<template>
    <div>
        <section id="working" class="filter-top-sec">
            <filter-list-header :listType="'map'" :hideMapSearchBtn="true" ref="filterComponent" :cities="cityArray"
            @onFilterChange="handleFilterChange"></filter-list-header>
        </section>
        <div class="container-fluid p-0">
            <div class="map-list-row">
                <!-- Loader Start -->
                <div class="map-loader" v-if="isMapLoading">
                    <div class="spinner-border" style="width: 4rem; height: 4rem" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <!-- Loader End -->

                <!-- Map Container -->
                <GmapMap id="gmap" :center="center" :zoom="selected_cities.length ? 11 : 8" map-type-id="roadmap"
                    class="map-container" :options="{
                        scrollwheel: true,
                        streetViewControl: false,
                        mapTypeControl: false,
                        clickableIcons: false,
                    }" @click.native="handleMapClick" @tilesloaded="removeMapLoader"
                    @center_changed="handleCenterChanged" @zoom_changed="handleMapZoomChange">
                    <gmap-cluster :zoomOnClick="true">
                        <gmap-custom-marker v-for="(property, index) in all_properties" :marker="property.position"
                            :key="property.unique_id" :ref="`marker-${property.unique_id}`" :position="property.position"
                            @click.native="handleMarkerClick(property)">
                            <div class="property-marker badge">
                                <i class="fas fa-home" v-if="isExclusiveProperty(property)"></i>
                                {{ property["list_price"] | compactPrice }}
                            </div>
                        </gmap-custom-marker>
                    </gmap-cluster>
                    <gmap-info-window :options="{
                        maxWidth: 300,
                        pixelOffset: { width: 0, height: -35 },
                    }" :position="infoWindow.position" :opened="infoWindow.open">
                        <map-property-card v-if="infoWindow.propertyDetails" :property_details="infoWindow.propertyDetails"
                            :image_base_url="url_web" :isExclusive="isExclusiveProperty(infoWindow.propertyDetails)
                                "></map-property-card>

                        <div class="property-details-loader" v-else>
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </gmap-info-window>
                </GmapMap>
            </div>
        </div>
    </div>
</template>

<script>
import SkeletonLoader from "./SkeletonLoader/SkeletonLoader.vue";
import IdxPropertyCard from "./PropertyCard/IdxPropertyCard.vue";
import MapPropertyCard from "./PropertyCard/MapPropertyCard.vue";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import FilterListHeader from "../components/FilterListHeader/FilterListHeader.vue";

const defaultLatLng = {
    lat: 43.653225,
    lng: -79.383186,
};
let timeout = null;

export default {
    name: "MapResults",
    props: {
        properties: {
            default: "[]",
        },
        cities: {
            default: "[]",
        },
        all_exclusive_properties: {
            default: "[]",
        },
        baseurl: {
            default: "",
        },
    },
    data() {
        return {
            all_properties: [],
            propertyData: {},
            current_page: 1,
            page_url: "",
            per_page: 9,
            all_cities: [],
            sliderValue: 300000,
            bedrooms: "",
            min_bedrooms: "",
            max_bedrooms: "",
            bathrooms: "",
            min_bathrooms: "",
            max_bathrooms: "",
            square_footage: "",
            min_price: "",
            max_price: "",
            selectedPriceOption: "",
            property_type: "",
            selected_style: "",
            city: "",
            isLoading: false,
            selected_cities: [],
            homeTypes: [
                "All",
                "Detached",
                "Semi-detached",
                "Town House",
                "Condo Town House",
                "Condo Apartment",
            ],
            styles: [
                "All",
                "2-Storey",
                "3-Storey",
                "Bungalow",
                "Split level",
                "Other",
            ],
            bedList: ["0+", "1+", "2+", "3+", "4+", "5+"],
            bathList: ["0+", "1+", "2+", "3+", "4+", "5+"],
            priceList: [
                "All",
                "0-500",
                "500-1M",
                "1M-1.5M",
                "1.5M-2M",
                "2M-3M",
                "3M+",
            ],
            sortByVal: [
                "Recommended",
                "Most Recent",
                "Oldest",
                "Most Expensive",
                "Least Expensive",
                "Most Bedroom",
            ],
            skeletonList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            sortBy: "timestamp_idx",
            sortDir: "",
            sortByFilter: "",
            sale_or_rent_list: ["Buy", "Rent"],
            sale_or_rent: "Sale",
            last_week: 0,
            search_mls_only: 1,
            type_of_listing: "Sale",
            exclusive_properties: [],
            url_web: "https://valuation.santhoshmathew.com/api/v1",
            skeletonList_two: [1, 2, 3, 4],
            infoWindow: {
                position: { lat: 0, lng: 0 },
                open: false,
                template: "",
                propertyDetails: null,
            },
            center: defaultLatLng,
            isMapLoading: true,
            centerChangedData: defaultLatLng
        };
    },
    components: {
        "skeleton-loader": SkeletonLoader,
        "idx-property-card": IdxPropertyCard,
        "gmap-custom-marker": GmapCustomMarker,
        "map-property-card": MapPropertyCard,
        'filter-list-header': FilterListHeader
    },
    async mounted() {
        this.center = await this.getUserCurrentLocation();

        this.exclusive_properties = JSON.parse(this.all_exclusive_properties);
        let properties = JSON.parse(this.properties);
        // TODO: Open
        if (properties["data"]) {
            this.all_properties = properties["data"];
            this.propertyData = properties;
        } else {
            this.all_properties = properties;
        }
        console.log("this.all_properties", this.all_properties);
        let cities = JSON.parse(this.cities);
        if (cities.length > 0) {
            for (let i = 0; i < cities.length; i++) {
                this.all_cities.push(cities[i].city);
            }
        }

        let urlParams = new URLSearchParams(window.location.search); //get all parameters
        let queryCity = urlParams.get("city");
        // console.log(queryCity);
        if (queryCity) {
            if (this.all_cities.includes(queryCity)) {
                this.selected_cities = [queryCity];
            } else {
                this.city = queryCity;
            }
        }
        // TODO: Open
        this.all_properties = this.exclusive_properties.concat(
            this.all_properties
        );
        // this.setCenter();
    },
    methods: {
        getHomes(centerLatLng, zoom = 11) {
            const radiusRange = this.calculateRadiusRange(zoom)
            this.all_properties = [];
            const filterData = this.$refs['filterComponent'];
            // this.current_page = page;
            this.closeInfoWindow();
            let apiUrl = "?q=1";
            if (filterData.sortBy) {
                apiUrl = apiUrl + "&sortBy=" + filterData.sortBy;
            } else {
                apiUrl = apiUrl + "&sortBy=list_price";
            }
            if (filterData.sortDir) {
                apiUrl = apiUrl + "&sortDir=" + filterData.sortDir;
            } else {
                apiUrl = apiUrl + "&sortDir=desc";
            }
            if (filterData.property_type) {
                apiUrl = apiUrl + "&property_type=" + filterData.property_type;
            }
            if (filterData.property_style) {
                const style = filterData.property_style === 'Split level' ?  'Multi-Level' : filterData.property_style
                apiUrl = apiUrl + '&property_style=' + style;
            }
            if (filterData.bedrooms) {
                apiUrl = apiUrl + "&bedrooms=" + filterData.bedrooms;
            }
            if (filterData.bathrooms) {
                apiUrl = apiUrl + "&bathrooms=" + filterData.bathrooms;
            }
            if (this.square_footage) {
                apiUrl = apiUrl + "&square_footage=" + this.square_footage;
            }
            if (this.selected_cities.length) {
                apiUrl = apiUrl + "&cities=" + this.selected_cities.join(",");
            } else if (filterData.city) {
                apiUrl = apiUrl + "&city=" + filterData.city;
            } else {
                if (!centerLatLng) {
                    apiUrl = apiUrl + "&get_last_week=1";
                }
            }
            if (filterData.min_price) {
                apiUrl = apiUrl + "&min_price=" + filterData.min_price;
            }
            if (filterData.max_price) {
                apiUrl = apiUrl + "&max_price=" + filterData.max_price;
            }
            if (filterData.sale_or_rent) {
                apiUrl = apiUrl + "&sale_or_lease=" + filterData.sale_or_rent;
            }
            if (filterData.last_week) {
                apiUrl = apiUrl + "&get_last_week=" + filterData.last_week;
            }
            if (centerLatLng) {
                apiUrl += `&latitude=${centerLatLng.lat}&longitude=${centerLatLng.lng}`
            }
            if (radiusRange) {
                apiUrl += `&range=${radiusRange}`
            }

            this.isLoading = true;
            // console.log(apiUrl);
            // if (page === 1 && this.selected_cities.length) {
            axios
                .get(this.url_web + "/get-idx-map-listings/" + apiUrl)
                .then((response) => {
                    this.all_properties = response.data.data;
                })
                .catch((err) => {
                    console.error(err);
                });
            // }

            return true;
        },
        calculateRadiusRange(zoom) {
            const zoomLevelsWiseRange = {
                9: 900000, //900km
                10: 500000,
                11: 300000,
                12: 200000,
                13: 100000,
                14: 70000,
                15: 40000,
                16: 20000,
                17: 10000,
                18: 5000// 5km
            }
            if (zoomLevelsWiseRange[zoom]) {
                return zoomLevelsWiseRange[zoom]
            }
            return 2000000
        },
        handleHomeTypeChange(val) {
            if (val === "All") {
                this.property_type = "";
            } else {
                //Semi-detached
                if (val == "Town House") {
                    this.property_type = "Att/Row/Twnhouse";
                } else if (val == "Condo Town House") {
                    this.property_type = "Condo Townhouse";
                } else if (val == "Condo Apartment") {
                    this.property_type = "Condo Apt";
                } else if (val == "Semi-detached") {
                    this.property_type = "Semi-Detached";
                } else {
                    this.property_type = val;
                }
            }
            this.getHomes();
        },
        handleHomeStyleChange(val) {
            if (val === "All") {
                this.selected_style = "";
            } else {
                if (val == "Split level") {
                    this.selected_style = "Multi-Level";
                } else {
                    this.selected_style = val;
                }
            }
            this.getHomes();
        },
        handleBedChange(val) {
            if (val === "0+") this.bedrooms = "";
            else this.bedrooms = val;
            this.getHomes();
        },
        handleBathChange(val) {
            if (val === "0+") this.bathrooms = "";
            else this.bathrooms = val;
            this.getHomes();
        },
        handlePriceChange(val) {
            if (val === "All") {
                this.min_price = "";
                this.max_price = "";
                this.selectedPriceOption = "";
            } else {
                const [min, max = ""] = val.split("-");
                // Min
                if (min == 0) {
                    this.min_price = 0;
                } else if (min == 500) {
                    this.min_price = 500000;
                } else if (min == "1M") {
                    this.min_price = 1000000;
                } else if (min == "1.5M") {
                    this.min_price = 1500000;
                } else if (min == "2M") {
                    this.min_price = 2000000;
                } else if (min == "3M") {
                    this.min_price = 3000000;
                } else {
                    this.min_price = "";
                }
                // Max
                if (max == 500) {
                    this.max_price = 500000;
                } else if (max == "1M") {
                    this.max_price = 1000000;
                } else if (max == "1.5M") {
                    this.max_price = 1500000;
                } else if (max == "2M") {
                    this.max_price = 2000000;
                } else if (max == "3M") {
                    this.max_price = 3000000;
                } else {
                    this.max_price = "";
                }
                // this.min_price = min;
                // this.max_price = max;
                this.selectedPriceOption = val;
            }
            this.getHomes();
        },
        isActive(selectedVal, val) {
            if (selectedVal === val) {
                return true;
            } else if (val === "All" && !selectedVal) return true;
        },
        handleSortFilter(val) {
            if (val === "Most Recent") {
                this.sortBy = "timestamp_idx";
                this.sortDir = "desc";
            } else if (val === "Oldest") {
                this.sortBy = "timestamp_idx";
                this.sortDir = "asc";
            } else if (val === "Most Expensive") {
                this.sortBy = "list_price";
                this.sortDir = "desc";
            } else if (val === "Least Expensive") {
                this.sortBy = "list_price";
                this.sortDir = "asc";
            } else if (val === "Most Bedroom") {
                this.sortBy = "bedrooms";
                this.sortDir = "desc";
            } else {
                this.sortBy = "";
                this.sortDir = "";
            }
            // console.log(val);
            this.sortByFilter = val;
            this.getHomes();
        },
        handleSaleOrRentChange(val) {
            if (val === "All") {
                this.sale_or_rent = "";
            } else if (val == "Buy") {
                this.sale_or_rent = "Sale";
                this.type_of_listing = "Sale";
            } else if (val == "Rent") {
                this.sale_or_rent = "Lease";
                this.type_of_listing = "Lease";
            }
            this.getHomes();
        },
        handleLastWeek(event) {
            if (this.last_week == 1) {
                this.last_week = "";
            } else {
                this.last_week = 1;
            }
            this.getHomes();
        },
        handleSearchByMls(event) {
            if (this.search_mls_only == 1) {
                this.search_mls_only = "0";
            } else {
                this.search_mls_only = 1;
            }
            this.getHomes();
        },
        handleSearchByExclusiveProperty(event) {
            window.location.href = "/exclusive-property/search-result";
        },
        isMoreToggleActive(item) {
            const obj = {
                Buy: "Sale",
                Rent: "Lease",
            };
            return this.sale_or_rent === obj[item];
        },
        getToPropertyDetailsUrl(property_details) {
            if (
                property_details["unique_id"] === undefined ||
                property_details["unique_id"] == "undefined" ||
                property_details["unique_id"] === "undefined"
            ) {
                return (
                    "/exclusive-property-detail/" +
                    this.property_details["unique_code"]
                );
            } else {
                return (
                    "/sellers/property-details/" +
                    this.convertToSlug(
                        property_details["address"] +
                        ", " +
                        property_details["city"] +
                        ", " +
                        property_details["area"] +
                        ", " +
                        property_details["zip_code"] +
                        ", " +
                        property_details["county"]
                    ) +
                    "/" +
                    property_details["unique_id"]
                );
            }
        },
        convertToSlug(val) {
            return val
                .toLowerCase()
                .replace(/[^\w ]+/g, "")
                .replace(/ +/g, "-");
        },
        round_off(num) {
            return (Math.round(num * 100) / 100).toFixed();
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        closeInfoWindow() {
            this.infoWindow = {
                position: { lat: 0, lng: 0 },
                open: false,
                template: "",
            };
        },
        handleMarkerClick(property) {
            this.infoWindow.propertyDetails = null;
            this.center = property.position;
            this.infoWindow.position = property.position;
            this.infoWindow.open = true;
            this.fetchPropertyDetails(property);
        },
        fetchPropertyDetails(property) {
            axios
                .get(
                    "https://valuation.santhoshmathew.com/api/v1/get-map-idx-details/" +
                    property?.unique_id
                )
                .then((res) => {
                    const cloneInfoWindow = { ...this.infoWindow };
                    cloneInfoWindow['propertyDetails'] = res?.data?.data;
                    this.infoWindow = cloneInfoWindow;
                    console.log("🏡", this.infoWindow.propertyDetails);
                    // this.infoWindow.propertyDetails.realtor =
                    //     "TeamAlliance REALTY";
                });
        },
        handleMapClick(event) {
            let clickedElement = event.target;
            // Check if the clicked element is outside of the info window
            if (
                !clickedElement.closest(".gm-style-iw, .property-marker") &&
                this.infoWindow.open
            ) {
                // Close the info window
                this.infoWindow.open = false;
            }
        },
        isExclusiveProperty(property) {
            return property?.realtor?.includes("REDBUYERS REALTY");
        },
        removeMapLoader() {
            this.isMapLoading = false;
        },
        setCenter() {
            if (this.all_properties?.length > 0) {
                this.center = this.all_properties?.[0]?.position;
            }
        },
        getUserCurrentLocation() {
            return new Promise((resolve) => {
                if ("geolocation" in window.navigator) {
                    // Geolocation is supported
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            const lat = position.coords.latitude;
                            const lng = position.coords.longitude;
                            console.log("Latitude: " + lat);
                            console.log("Longitude: " + lng);
                            resolve({ lat, lng });
                        },
                        function (error) {
                            console.warn(
                                "Error getting location: " + error.message
                            );
                            resolve(defaultLatLng);
                        }
                    );
                } else {
                    resolve(defaultLatLng);
                    // Geolocation is not supported
                    console.warn(
                        "Geolocation is not supported by your browser"
                    );
                }
            });
        },
        handleCenterChanged(e) {
            // const mapEl = document.getElementById('gmap')
            // console.log({mapEl: mapEl})
            // mapEl?.dispatchEvent('center_changed',(e)=>{
            //     console.log('dispatchEvent',e)
            // })
            // mapEl?.addEventListener('center_changed',(e)=>{console.log('listener',e)})
            const center = { lat: e.lat(), lng: e.lng() }
            this.centerChangedData = center;
            console.log("👋", center)
            // this.getHomes(center)
            // this.center = center

            if (timeout) clearTimeout(timeout)

            timeout = setTimeout(() => {
                this.getHomes(center)
            }, 600);

        },
        handleMapZoomChange(zoom) {
            console.log("🔎", zoom)
            this.getHomes(this.centerChangedData, zoom)
        },
        handleFilterChange(){
            this.getHomes()
        }
    },
    computed: {
        idxCounty() {
            if (this.all_properties.length) {
                const firstCounty = this.all_properties?.[0]?.county;
                const hasSameCountyForAll = this.all_properties.every(
                    (prop) => prop?.county === firstCounty
                );
                if (hasSameCountyForAll) return firstCounty;
                return null;
            }
            return null;
        },
        countyId() {
            return `${this.baseurl}/idx-listings/search-result/on`;
        },
        currentUrl() {
            return window.location.href;
        },
        cityArray(){
            let cities = this?.cities;
            if(typeof this?.cities === 'string'){
                cities = JSON.parse(this.cities)
            }
            return cities?.map(item=>item?.city)
        }
    },
    filters: {
        compactPrice(value) {
            return value.toLocaleString("en-US", { notation: "compact" });
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "./IdxResults.scss";

.map-list-row {
    --map-primary-clr: #0061DF;
    position: relative;
    height: calc(100vh - 158px);

    .pagination {
        flex-wrap: wrap;
    }

    .pagination--map {
        display: none;
    }

    .map-container {
        height: 100%;
        width: 100%;
    }
}

.mrker-tooltip {
    display: flex;
    text-decoration: none;
    gap: 6px;

    &:hover {
        color: #000;
        text-decoration: none;
    }

    p {
        font-size: 12px;
    }

    img {
        width: 32px;
        background-color: lightblue;
        margin-bottom: 0px !important;
    }
}

.gm-style .gm-style-iw-c {
    max-height: unset !important;
}

.gm-style-iw-d {
    width: auto !important;
    overflow: hidden !important;

    .property_feature_listings_box_item_image img {
        margin-bottom: 0;
    }
}

button.gm-ui-hover-effect {
    display: none;
}

.gm-style .gm-style-iw-c {}

.gm-style .gm-style-iw-t {
    bottom: 32px !important;
}

.gm-style-iw.gm-style-iw-c {
    top: 0px !important;
    padding: 0;
    max-width: unset !important;
    min-width: 300px !important;
    width: 300px !important;
    border-radius: 8px;
    // max-height: 68px !important;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

.property-details-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;

    .spinner-border {
        width: 3.5rem;
        height: 3.5rem;
    }
}

.spinner-border {
    color: var(--map-primary-clr);
    border-width: 6px;
}

.property-marker {
    position: relative;
    cursor: pointer;
    font-size: 0.75rem;
    line-height: 1.36;
    background-color: var(--map-primary-clr);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 6px 18px,
        rgba(0, 0, 0, 0.043) 0px 1.34018px 4.02054px,
        rgba(0, 0, 0, 0.027) 0px 0.399006px 1.19702px;
    border-radius: 4px;
    padding: 4px 8px;
    height: 24px;
    color: #fff;
}

.cluster {
    img {
        display: none;
    }

    &> :last-child {
        background-color: var(--map-primary-clr);
        color: white !important;
        box-shadow: rgba(0, 27, 223, 0.24) 0px 0px 0px 6px;
        width: 36px !important;
        height: 36px;
        min-width: 30px;
        min-height: 30px;
        max-width: 54px;
        max-height: 54px;
        border-radius: 100%;
        cursor: pointer;
        margin: 0px;
        padding: 0px;
        position: absolute !important;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(-50%, -100%);
        z-index: 2;
    }
}

.map-loader {
    position: absolute;
    z-index: 1;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    height: 100%;
    display: grid;
    place-items: center;
}
</style>
