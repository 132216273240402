<template>
    <div class="idx-property-details-comp">
        <div class="container">
            <div class="new_property_details_bredc_content">
                <ul>
                    <li><a :href="baseurl">Home</a></li>
                    <li>
                        <a :href="baseurl + '/buy-home'">Buy Homes</a>
                    </li>
                    <li>
                        <a :href="baseurl +
                            '/idx-listings/search-result'
                            ">Properties</a>
                    </li>
                    <li v-if="prev_ul.length >= 1">
                        <a :href="baseurl +
                            '/idx-listings/search-result?city=' +
                            prev_ul
                            ">{{ prev_ul }}</a>
                    </li>
                    <li class="text-primary d-none d-sm-block">{{ property_name }}</li>
                    <li class="text-primary d-block d-sm-none">{{ short_property_name }}</li>
                </ul>
            </div>
            <div class="row">
                <div class="col">
                    <div>
                        <h1 class="h2">{{ property_name }}</h1>
                        <p>Community : {{ property_details.community }}</p>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="d-flex align-items-center">
                        <a href="javascript:void(0)" class="mr-2" @click="openShareModal"><img
                                src="/images/property-details/solar-share-linear.png" alt=""></a>
                        <a href="#" @click="handleFavorite">
                            <span key="favorite" v-if="isFav"><i class="fas fa-heart text-primary"></i></span>
                            <span key="unfavorite" v-else><i class="far fa-heart text-primary"></i></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container property-slider__container">
            <div :id="'property-img'" class="carousel" data-ride="carousel" data-interval="false">
                <ol class="carousel-indicators">
                    <li :data-target="'#property-img'" :data-slide-to="index" :class="{ 'active': index === 0 }"
                        v-for="(img, index) in sliderImages"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item" v-for="(img, index) in sliderImages" :class="{ 'active': index === 0 }">
                        <img class="d-block w-100" :src="img" alt="Property">
                    </div>
                </div>
                <a class="carousel-control-prev" :href="'#property-img'" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" :href="'#property-img'" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <div class="bottom-label bottom-label-left">
                {{ get_updated_date_or_days }}
            </div>
            <div class="bottom-label bottom-label-right" v-if="property_info_other_images.length > 0">
                <button class="btn btn-sm photo-player-btn" data-fancybox="gallery"
                    :data-src="property_info_other_images[0]">+ Show
                    all {{ property_info_other_images.length }} Photos
                    <img :src="property_info_other_images[0]" class="img-fluid" @error="handleImageError"
                        v-show="false" />
                </button>
            </div>
        </div>
        <div class="container price-container-mobile d-block d-sm-none">
            <h2>{{ property_price }}</h2>
        </div>
        <div class="container main-info-container">
            <div class="main-info">
                <div class="main-info__item">
                    <h5>{{ bed }} <span v-if="addtional_bedrooms"> + {{ addtional_bedrooms }}</span></h5>
                    <p>Bedrooms</p>
                </div>
                <div class="main-info__item">
                    <h5>{{ sqft_area }}</h5>
                    <p>Squarefoot</p>
                </div>
                <div class="main-info__item">
                    <h5>{{ bath }}</h5>
                    <p>Bathrooms</p>
                </div>
                <div class="main-info__item">
                    <h5>{{ garage }}</h5>
                    <p>Garage</p>
                </div>
            </div>
            <div class="price">
                <h2>{{ property_price }}</h2>
            </div>
        </div>
        <div class="property-tabs-wrapper">
            <nav class="container">
                <ul>
                    <li>
                        <a href="#property-overview" :class="{ 'active': currentTabId === '#property-overview' }"
                            @click="handleTabChange">Overview</a>
                    </li>
                    <li>
                        <a href="#explore-neighborhood" :class="{ 'active': currentTabId === '#explore-neighborhood' }"
                            @click="handleTabChange">Map</a>
                    </li>
                    <li>
                        <a href="#property-details-section"
                            :class="{ 'active': currentTabId === '#property-details-section' }"
                            @click="handleTabChange">Property Details</a>
                    </li>
                    <li>
                        <a href="#facts-features-section"
                            :class="{ 'active': currentTabId === '#facts-features-section' }"
                            @click="handleTabChange">Facts & Feature</a>
                    </li>
                    <li>
                        <a href="#" @click="handleTabChange">Virtual Tour</a>
                    </li>
                </ul>
            </nav>
        </div>
        <!-- For Mobile View -->
        <div class="container d-flex d-sm-none justify-content-end">
            <button class="fill-up-btn btn" @click="openScheduleAppointmentForm">
                <div class="img-container">
                    <img src="/images/property-details/fluent-emoji_house-with-garden.png" alt="Home">
                </div>
            </button>
        </div>
        <div class="container tabs-content-layout mb-5">
            <div class="left-section">
                <!-- ------------------------ Property Overview Section ----------------------- -->
                <section id="property-overview">
                    <h3>Why {{ property_name }} ?</h3>
                    <ul class="property-overview__features">
                        <li>
                            <label>Type</label>
                            <p>{{ home_type }}</p>
                        </li>
                        <li>
                            <label>Bathrooms</label>
                            <p>{{ bath }}</p>
                        </li>
                        <li>
                            <label>Bedrooms</label>
                            <p>{{ bed }}</p>
                        </li>
                        <li>
                            <label>Age</label>
                            <p>{{ property_age }}</p>
                        </li>
                        <li>
                            <label>Status</label>
                            <p>{{ basement }}</p>
                        </li>
                        <li>
                            <label>Garage</label>
                            <p>{{ garage }}</p>
                        </li>
                        <li>
                            <label>Area</label>
                            <p>{{ sqft_area }} Sqft</p>
                        </li>
                    </ul>
                    <div class="about-property-wrapper" v-if="property_details.property_description">
                        <p class="about-property" ref="aboutPropertyRef" :class="{ 'full': showFullDescription }">
                            {{ property_details.property_description }}
                        </p>
                        <button class="btn read-more-btn" v-if="hasMoreDescription"
                            @click="showFullDescription = !showFullDescription">
                            <div v-if="showFullDescription" key="less">
                                <span class="text-primary">Read Less about the property </span>
                                <i class="fas fa-caret-up"></i>
                            </div>
                            <div v-else key="more">
                                <span class="text-primary">Read more about the property </span>
                                <i class="fas fa-caret-down"></i>
                            </div>
                        </button>
                    </div>
                </section>

                <!-- ---------------------- Explore Neighborhood Section ---------------------- -->
                <section id="explore-neighborhood">
                    <h3 class="mb-4">Explore Neighborhood - Map View</h3>
                    <div class="row align-items-lg-center">
                        <div class="col-lg-7">
                            <div class="map-view">
                                <iframe :src="'https://maps.google.com/maps?q=+' +
                                    street_address_map +
                                    '&hl=en&z=15&amp;output=embed&'
                                    " style="border: 0" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <ul class="neighborhood-features mt-5 mt-lg-0">
                                <li class="circle-icon-with-title">
                                    <div class="icon-wrapper">
                                        <img src="/images/property-details/neighborhood/fluent-emoji_bus-stop.png"
                                            alt="Bus Stop">
                                    </div>
                                    <p>Commute</p>
                                </li>
                                <li class="circle-icon-with-title">
                                    <div class="icon-wrapper">
                                        <img src="/images/property-details/neighborhood/fluent-emoji_bank.png"
                                            alt="Bus Stop">
                                    </div>
                                    <p>Banks</p>
                                </li>
                                <li class="circle-icon-with-title">
                                    <div class="icon-wrapper">
                                        <img src="/images/property-details/neighborhood/fluent-emoji_movie-camera.png"
                                            alt="Cinemas">
                                    </div>
                                    <p>Cinemas</p>
                                </li>
                                <li class="circle-icon-with-title">
                                    <div class="icon-wrapper">
                                        <img src="/images/property-details/neighborhood/fluent-emoji_evergreen-tree.png"
                                            alt="Evergreen Tree">
                                    </div>
                                    <p>Parks</p>
                                </li>
                                <li class="circle-icon-with-title">
                                    <div class="icon-wrapper">
                                        <img src="/images/property-details/neighborhood/fluent-emoji_shopping-bags.png"
                                            alt="Shopping Bags">
                                    </div>
                                    <p>Shopping</p>
                                </li>
                                <li class="circle-icon-with-title">
                                    <div class="icon-wrapper">
                                        <img src="/images/property-details/neighborhood/fluent-emoji_pot-of-food.png"
                                            alt="Food">
                                    </div>
                                    <p>Food</p>
                                </li>
                                <li class="circle-icon-with-title">
                                    <div class="icon-wrapper">
                                        <img src="/images/property-details/neighborhood/fluent-emoji_medical-symbol.png"
                                            alt="Food">
                                    </div>
                                    <p>Hospitals</p>
                                </li>
                                <li class="circle-icon-with-title">
                                    <div class="icon-wrapper">
                                        <img src="/images/property-details/neighborhood/fluent-emoji_ferris-wheel.png"
                                            alt="Ferris Wheel">
                                    </div>
                                    <p>Attractions</p>
                                </li>
                            </ul>
                        </div>
                    </div>


                </section>

                <!-- ---------------------- Amenities Section ---------------------- -->
                <section id="amenities-section" v-if="getAllAmenities.length">
                    <h3 class="mb-4">Amenities</h3>
                    <ul class="amenities-list m-0">
                        <li class="circle-icon-with-title" v-for="amenity in getAllAmenities" :key="amenity">
                            <div class="icon-wrapper">
                                <img :src="amenity | aminityIcon" :alt="amenity">
                            </div>
                            <p>{{ amenity }}</p>
                        </li>
                    </ul>
                </section>
                <section v-if="property_details.nearby_school" class="nearby-schools-section border-bottom-0 pb-3">
                    <h3>Nearby Schools</h3>
                    <ul>
                        <li v-for="school in property_details.nearby_school">
                            <h4>{{ school.school_name }}</h4>
                            <p>{{ school.school_address }}</p>
                            <p>{{ school.school_category }}</p>
                        </li>
                        <!-- <li>
                            <h4>Glengrove Public School</h4>
                            <p>1934 Glengrove Rd Pickering Ontario L1V1X2</p>
                            <p>Elementary school, Primary school, Elementary, School</p>
                        </li>
                        <li>
                            <h4>Glengrove Public School</h4>
                            <p>1934 Glengrove Rd Pickering Ontario L1V1X2</p>
                            <p>Elementary school, Primary school, Elementary, School</p>
                        </li>
                        <li>
                            <h4>Glengrove Public School</h4>
                            <p>1934 Glengrove Rd Pickering Ontario L1V1X2</p>
                            <p>Elementary school, Primary school, Elementary, School</p>
                        </li> -->
                    </ul>
                </section>
                <!-- ------------------------ Property Details Section ------------------------ -->
                <section id="property-details-section">
                    <h3 class="mb-3">Property Details</h3>
                    <ul>
                        <template v-for="item in property_details.propertyDetails">
                            <li v-if="item.value" :key="item.name">{{ item.name }} : <b>{{ item.value }}</b></li>
                        </template>
                    </ul>
                </section>

                <!-- ------------------------ Facts & Features Section ------------------------ -->
                <section id="facts-features-section">
                    <h3 class="mb-3">Facts & Features</h3>
                    <div class="facts-features-container">
                        <div class="facts-features__left-section">
                            <!-- Properties -->
                            <div class="facts-features-item" v-for="(item, index) in factsFeaturesLeftArray"
                                :key="item.name + '_' + index">
                                <label>{{ item.name }}</label>
                                <ul>
                                    <template v-for="(childItem, childIndex) in item.children">
                                        <li v-if="childItem.value" :key="childItem.name + '_' + childIndex">{{
                                            childItem.name
                                        }} : <span>{{
                                            childItem.value }}</span>
                                        </li>
                                    </template>
                                </ul>
                            </div>




                        </div>

                        <div class="facts-features__right-section">
                            <div class="facts-features-item" v-for="(item, index) in factsFeaturesRightArray"
                                :key="item.name + '_' + index">
                                <label>{{ item.name }}</label>
                                <ul>
                                    <template v-for="(childItem, childIndex) in item.children">
                                        <li v-if="childItem.value" :key="childItem.name + '_' + childIndex">{{
                                            childItem.name
                                        }} : <span>{{
                                            childItem.value }}</span>
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>

                </section>

                <!-- ---------------------- Estimated Home Value Section ----------------------  -->
                <section id="estimated-section" class="border-bottom-0 pb-0">
                    <div class="estimated-card">
                        <h3 class="mb-4">Your Estimated Home Value</h3>
                        <div class="estimated-card-header">
                            <div class="estimated-property-details">
                                <div>
                                    <img src="/images/property-details/location.png" alt="Location">
                                    <span>{{ property_name }}</span>
                                </div>
                                <div>
                                    <img src="/images/property-details/bed.png" alt="Beds">
                                    <span>{{ bed }} Beds</span>
                                </div>
                                <div>
                                    <img src="/images/property-details/bathroom.png" alt="Bath">
                                    <span>{{ bath }} Baths</span>
                                </div>
                                <div>
                                    <img src="/images/property-details/garage.png" alt="Garage">
                                    <span>{{ garage }} Garages</span>
                                </div>
                            </div>
                            <div>
                                <small class="text-primary">TeamAlliance Estimate:</small>
                                <p class="font-weight-bold text-primary">${{ property_price_home_value }}</p>
                            </div>
                            <div>
                                <small>Estimated Sales Range:</small>
                                <p class="font-weight-bold">
                                    ${{
                                        low_avg_price
                                        | round_off
                                        | numberWithCommas
                                    }}
                                    -
                                    ${{
                                        high_avg_price
                                        | round_off
                                        | numberWithCommas
                                    }}</p>
                            </div>
                        </div>
                        <div class="estimated-card-body mr-lg-5">
                            <div class="estimated-slider-wrapper">
                                <div class="slider-bar">
                                    <div class="slider-price slider-price--left">
                                        ${{
                                            low_avg_price
                                            | round_off
                                            | numberWithCommas
                                        }}
                                    </div>
                                    <div class="slider-price slider-price--center">
                                        ${{ property_price_home_value }}
                                    </div>
                                    <div class="slider-price slider-price--right">
                                        ${{
                                            high_avg_price
                                            | round_off
                                            | numberWithCommas
                                        }}
                                    </div>
                                    <div class="slider-bar-ball center"></div>
                                </div>
                            </div>
                        </div>
                        <div class="estimated-card-footer">
                            <p class="mb-2">*Approximate value based on Comparable homes in the area.</p>
                            <p class="mb-0">**An accurate cash offer might be different from the estimate shown above
                                and
                                exact offer will be provided after viewing the property</p>
                        </div>
                    </div>
                </section>

            </div>

            <aside class="right-section">
                <button class="fill-up-btn btn" @click="openScheduleAppointmentForm">
                    <div class="img-container">
                        <img src="/images/property-details/fluent-emoji_house-with-garden.png" alt="Home">
                    </div>
                    <span>
                        Hurry Up!<br />
                        Click here to schedule an appointment!
                    </span>
                </button>

                <!-- <a href="/find-your-home-value" class="info-blue-card instant-offer-card">
                    <div class="d-flex flex-column pr-3">
                        <p class="mb-1">Instant offer for your Home?</p>
                        <small>Approximate value based on similar homes in the area.</small>
                    </div>
                    <img src="/images/property-details/fluent-emoji_money-bag.png" alt="">
                </a>

                <a href="/buying-option" class="info-blue-card instant-offer-card">
                    <div class="d-flex flex-column">
                        <p class="mb-1">Down Payment Assistance for your Home?</p>
                        <small>Transform your down payment into
                            20% or more with the help from TeamAlliance.</small>
                    </div>
                    <img src="/images/property-details/fluent-emoji_money-with-wings.png" alt="">
                </a>

                <a href="#" data-toggle="modal" data-target="#ownershipInfoModal"
                    class="info-blue-card co-investment-card mb-0">
                    <div class="d-flex flex-column">
                        <p class="mb-1">Co-investment at 1/8th Cost!</p>
                        <small>Min ROI 25%. Click to learn more!</small>
                    </div>
                    <img src="/images/property-details/co-investment.png" alt="Co-Investment" width="80x">
                </a> -->
            </aside>
        </div>

        <section class="container pb-0" v-if="isRelatedSoldPropertiesLoading">
            <h3 class="mb-30">Comparable Homes</h3>
            <div class="skeleton-grid">
                <skeleton-loader v-for="value in skeletonList" :key="value" />
            </div>
        </section>
        <section class="container pb-0" v-if="sold_property.length">
            <h3 class="mb-30">Comparable Homes</h3>
            <div class="common-property-list-grid">
                <common-property-card v-for="property in sold_property" :key="property.id" :property_details="property"
                    type="COMPARABLE_HOME" :user_id="user_id" :userFavProperties="userFavSoldProperties"
                    @onFavoriteSuccess="handleSoldFavoriteSuccess" :hidePrice="true" />
            </div>
        </section>

        <section class="container pb-0" v-if="isSimilarPropertiesLoading">
            <h3 class="mb-30">Similar Homes</h3>
            <div class="skeleton-grid">
                <skeleton-loader v-for="value in skeletonList" :key="value" />
            </div>
        </section>
        <section class="container pb-0" v-if="related_properties.length">
            <h3 class="mb-30">Similar Homes</h3>
            <div class="common-property-list-grid">
                <common-property-card v-for="property in related_properties" :key="property.id"
                    :property_details="property" :user_id="user_id" :userFavProperties="userFavSimilarProperties"
                    @onFavoriteSuccess="handleSimilarFavoriteSuccess" />
            </div>
        </section>


        <mls-related-property-sold :property_id="get_id" :isAuth="true" v-if="get_id" :user_id="loggedInUserId" />

        <schedule-appointment-modal :streetAddress="street_address" :close="true"></schedule-appointment-modal>

        <share-property-modal v-if="loggedInUserEmail" ref="share-property-modal" :properties="[sharePropertyDetails]"
            :senderEmail="loggedInUserEmail" @onSuccess="handleShareSuccess"></share-property-modal>

        <auth-modal ref="authModal" :initialView="initialAuthView" @loginSuccess="loginSuccess"></auth-modal>

        <div v-show="false">
            <a data-fancybox="gallery" :data-src="item"
                v-for="(item, index) in this.property_info_other_images.slice(1)" :key="'property-fancybox-' + index">
                <img :src="item" class="img-fluid" @error="handleImageError" />
            </a>
        </div>
        <ownership-info-modal v-if="only_property_price" :price="only_property_price"></ownership-info-modal>
    </div>
</template>

<script>
import CommonPropertyCard from '../../../pages/PropertyCard/CommonPropertyCard/CommonPropertyCard.vue';
import SkeletonLoader from '../../../pages/SkeletonLoader/SkeletonLoader.vue';
import { getAmenityIcon } from '../../../utils/amenities';
import { getUserFavPropertiesIds } from '../../../utils/app';
import AuthModal, { VIEWS } from '../../AuthModal/AuthModal.vue';
import SharePropertyModal from '../../SharePropertyModal.vue';
import ScheduleAppointmentModal from '../ScheduleAppointmentModal.vue';
import OwnershipInfoModal from '../ownership-info-modal.vue';
import $ from "jquery";

const apiUrl =
    "https://valuation.santhoshmathew.com/api/v1/properties/related/";

export default {
    name: 'IdxPropertyDetails',
    components: {
        "schedule-appointment-modal": ScheduleAppointmentModal,
        "common-property-card": CommonPropertyCard,
        "skeleton-loader": SkeletonLoader,
        'share-property-modal': SharePropertyModal,
        'auth-modal': AuthModal,
        "ownership-info-modal": OwnershipInfoModal
    },
    props: {
        info: {
            default: "",
        },
        baseurl: {
            default: "",
        },
        property_images: {
            default: "[]",
        },
        is_logged_in: {
            default: "false",
        },
        previous_url: {
            default: "",
        },
        is_message_share: {
            default: 'true'
        },
        user_id: {
            default: ""
        },
        user_email: {
            default: ""
        }
    },
    data() {
        return {
            id: null,
            property_details: {},
            currentTabId: '#property-overview',
            favourite_properties: [],
            property_photos: [],
            logged_in: false,
            prev_ul: "",
            hasMoreDescription: false,
            showFullDescription: false,
            low_avg_price: "",
            high_avg_price: "",
            sold_property: [],
            related_properties: [],
            isRelatedSoldPropertiesLoading: true,
            isSimilarPropertiesLoading: true,
            skeletonList: [1, 2, 3, 4],
            userFavSoldProperties: [],
            userFavSimilarProperties: [],
            isFav: false,
            initialAuthView: VIEWS.LOGIN_FORM,
            loggedInUserEmail: "",
            loggedInUserId: ""
        };
    },
    mounted() {
        getAmenityIcon("")
        this.loggedInUserId = this.user_id;
        this.loggedInUserEmail = this.user_email;
        this.property_details = JSON.parse(this.info);
        this.logged_in = this.is_logged_in;
        this.prev_ul = this.previous_url;
        this.fetchUserFavSoldProperties();
        this.fetchUserFavSimilarProperties()
        setTimeout(() => {
            this.hasMoreDescription = this.$refs?.aboutPropertyRef?.scrollHeight > 90;
        }, 100);
    },
    created() {
        let photos = JSON.parse(this.property_images);
        this.property_photos = [...photos];
    },
    methods: {
        handleTabChange(e) {
            e.preventDefault();
            if (e.target.hash) {
                this.currentTabId = e.target.hash;
                let offset = $(e.target.hash).offset();
                $('html, body').animate({
                    scrollTop: offset.top - 70,
                }, 500);
            }
        },
        openScheduleAppointmentForm() {
            this.$bvModal.show('schedule-appointment-modal')
        },
        handleImageError(event) {
            event.target.src = "/assets/images/no-image-available.png";
        },
        getDifferenceInDays(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return (diffInMs / (1000 * 60 * 60 * 24)) | this.round_off;
        },
        getDifferenceInHours(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return (diffInMs / (1000 * 60 * 60)) | this.round_off;
        },
        getSoldPropertyList() {
            this.isRelatedSoldPropertiesLoading = true;
            axios
                .get(`${apiUrl}sold/${this.id}`)
                .then((res) => {
                    this.sold_property = res.data.data;
                    this.isRelatedSoldPropertiesLoading = false;
                })
                .catch((err) => {
                    console.log("Error", err);
                    this.isRelatedSoldPropertiesLoading = false;
                });
        },
        getRelatedPropertyList() {
            this.isSimilarPropertiesLoading = true;
            axios
                .get(`${apiUrl}similar/${this.id}`)
                .then((res) => {
                    this.related_properties = res.data.data;
                    this.avg_home_val = res.data.avg_home_value;
                    this.isSimilarPropertiesLoading = false;
                })
                .catch((err) => {
                    console.log("Error", err);
                    this.isSimilarPropertiesLoading = false;
                });
        },
        fetchUserFavSoldProperties() {
            getUserFavPropertiesIds(this.loggedInUserId, 'vow')
                .then(res => {
                    console.log(res)
                    this.userFavSoldProperties = res;
                });
        },
        handleSoldFavoriteSuccess(res) {
            if (this.userFavSoldProperties.includes(res.id)) {
                this.userFavSoldProperties = this.userFavSoldProperties.filter(propertyId => propertyId !== res.id)
            }
            else {
                this.userFavSoldProperties = [...this.userFavSoldProperties, res.id]
            }
        },
        fetchUserFavSimilarProperties() {
            getUserFavPropertiesIds(this.loggedInUserId, 'idx')
                .then(res => {
                    console.log(res)
                    this.userFavSimilarProperties = res;
                    this.isFav = this.userFavSimilarProperties.includes(this.id)
                });
        },
        handleSimilarFavoriteSuccess(res) {
            if (this.userFavSimilarProperties.includes(res.id)) {
                this.userFavSimilarProperties = this.userFavSimilarProperties.filter(propertyId => propertyId !== res.id)
            }
            else {
                this.userFavSimilarProperties = [...this.userFavSimilarProperties, res.id]
            }
        },
        handleFavorite() {
            if (!this.loggedInUserId) window.location.href = "/login";
            this.saveFavorite();
        },
        saveFavorite() {
            let endpoint = "https://valuation.santhoshmathew.com/api/v1/set-favorite-property";
            const formData = new FormData();
            formData.append("user_id", this.loggedInUserId),
                formData.append("property_id", this.id);
            formData.append(
                "property_type",
                "idx"
            );
            axios
                .post(endpoint, formData)
                .then((res) => {
                    console.log("❤️", res);
                    this.isFav = !this.isFav;
                })
                .catch((err) => { });
        },
        openAuthModal() {
            this.$refs.authModal.open()
        },
        loginSuccess(user) {
            console.log({ loginResponse: user })
            this.loggedInUserEmail = user.email;
            if (this.loggedInUserEmail) {
                this.openShareModal()
            }
        },
        openShareModal() {
            if (!this.loggedInUserEmail) {
                this.openAuthModal()
                return;
            } else {
                this.$refs['share-property-modal']?.open()
            }
        },
        handleShareSuccess() {
            setTimeout(() => {
                this.$refs['share-property-modal']?.close();
            }, 1000);
        }

    },
    computed: {
        property_name() {
            if (this.property_details?.address) {
                let addr = this.property_details.address;
                if (this.property_details?.city) {
                    addr = addr + ", " + this.property_details.city;
                }
                return addr;
            } else if (this.property_details?.id) {
                return "Property " + this.property_details.id;
            } else {
                return "TeamAlliance Property " + this.property_details?.id;
            }
        },
        short_property_name() {
            if (this.property_details?.address) {
                return this.property_details.address
            } else if (this.property_details?.id) {
                return "Property " + this.property_details.id;
            } else {
                return "TeamAlliance Property " + this.property_details?.id;
            }
        },
        sliderImages() {
            return this.property_photos?.slice?.(0, 5)
        },
        property_info_other_images() {
            if (Array.isArray(this.property_photos)) {
                return this.property_photos.slice(5)
            } else {
                return [];
            }
        },
        property_price() {
            if (this.property_details?.list_price) {
                return (
                    "$" +
                    this.property_details.list_price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
            } else {
                return "Unavailable";
            }
        },
        bed() {
            if (this.property_details?.bedrooms) {
                let bed = this.property_details.bedrooms;
                let result = bed - this.addtional_bedrooms;
                return result;
            } else {
                return "0";
            }
        },
        addtional_bedrooms() {
            if (this.property_details?.additional_bathroom) {
                if (
                    this.property_details.additional_bathroom == "0" ||
                    this.property_details.additional_bathroom === 0
                ) {
                    return 0;
                } else {
                    return this.property_details.additional_bathroom;
                }
            } else {
                return 0;
            }
        },
        bath() {
            if (this.property_details?.bathrooms) {
                let bath = this.property_details.bathrooms;
                let result = bath;
                return result;
            } else {
                return "0";
            }
        },
        sqft_area() {
            if (this.property_details?.square_footage) {
                let sqft = this.property_details.square_footage;
                return sqft
            } else {
                return "No Data";
            }
        },
        get_updated_date_or_days() {
            let todays_day = new Date();
            let last_updated_date = new Date(this.property_details.updated_at);
            if (
                this.getDifferenceInDays(last_updated_date, todays_day) == 1 ||
                this.getDifferenceInDays(last_updated_date, todays_day) == 0
            ) {
                return (
                    "Added " +
                    this.getDifferenceInHours(last_updated_date, todays_day) +
                    " Hours Ago"
                );
            } else {
                return (
                    "Added " +
                    this.getDifferenceInDays(last_updated_date, todays_day) +
                    " Days Ago"
                );
            }
        },
        basement() {
            let basement = "";
            if (this.property_details?.basement_one) {
                basement = this.property_details.basement_one;
                if (this.property_details?.basement_two) {
                    basement =
                        basement + ", " + this.property_details.basement_two;
                    return basement;
                }
            } else {
                basement = "N.A";
            }
            return basement;
        },
        garage() {
            // console.table(this.property_details);
            return this.property_details?.garage_spaces
                ? this.property_details.garage_spaces
                : "0.0";
        },
        home_type() {
            if (this.property_details?.property_type) {
                let home_type = this.property_details.property_type;
                return home_type;
            } else {
                return "N.A";
            }
        },
        property_age() {
            if (this.property_details?.approx_age) {
                let approx_age = this.property_details.approx_age;
                return "Approx " + approx_age + " years old";
            } else {
                return "No Data";
            }
        },
        street_address_map() {
            if (this.property_details?.address) {
                let street_address_map = this.property_details.address;
                street_address_map =
                    street_address_map +
                    (this.property_details?.city
                        ? "+" + this.property_details.city
                        : "") +
                    (this.property_details?.county
                        ? "+" + this.property_details.county
                        : "") +
                    (this.property_details?.zip_code
                        ? "+" + this.property_details.zip_code
                        : "");
                return street_address_map;
            } else {
                return "Address not available";
            }
        },
        street_address() {
            if (this.property_details?.address) {
                let street_address = this.property_details.address;
                street_address =
                    street_address +
                    (this.property_details?.city
                        ? ", " + this.property_details.city
                        : "") +
                    (this.property_details?.county
                        ? ", " + this.property_details.county
                        : "") +
                    (this.property_details?.zip_code
                        ? ", " + this.property_details.zip_code
                        : "");
                return street_address;
            } else {
                return "Address not available";
            }
        },
        property_price_home_value() {
            if (this.property_details?.list_price) {
                let real_value = this.property_details.list_price;
                let home_value;
                const price_discount = Math.floor(Math.random() * 5) + 1;
                const price_symbol_array = ["+", "-"];
                const price_symbol =
                    price_symbol_array[
                    Math.floor(Math.random() * price_symbol_array.length)
                    ];
                const discounted_amount = (price_discount / 100) * real_value;
                if (price_symbol === "-") {
                    home_value = real_value - discounted_amount;
                } else {
                    home_value = real_value + discounted_amount;
                }
                let round = (Math.round(home_value * 100) / 100).toFixed();
                // lease block
                if (this.get_sale_or_lease?.toLowerCase() === "lease") {
                    if (real_value >= 15000) {
                        const price_discount_new =
                            Math.floor(Math.random() * 7) + 1;
                        const discounted_amount_new =
                            (price_discount_new / 100) * real_value;
                        this.low_avg_price = home_value - discounted_amount_new;
                        this.high_avg_price =
                            home_value + discounted_amount_new;
                    } else {
                        const price_discount_new =
                            Math.floor(Math.random() * 5) + 1;
                        const discounted_amount_new =
                            (price_discount_new / 100) * real_value;
                        this.low_avg_price = home_value - discounted_amount_new;
                        this.high_avg_price =
                            home_value + discounted_amount_new;
                    }
                } else {
                    // Sale block
                    if (real_value >= 1800000) {
                        this.low_avg_price = home_value - 150000;
                        this.high_avg_price = home_value + 150000;
                    } else {
                        this.low_avg_price = home_value - 100000;
                        this.high_avg_price = home_value + 100000;
                    }
                }
                return round.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        sharePropertyDetails() {
            return {
                link: window.location.href,
                address: `${this.street_address_old}, ${this.city} ${this.county_zip}`,
                beedroom: this.bed,
                bathroom: this.bath,
                garage: this.garage,
                price: this.only_property_price,
            }
        },
        street_address_old() {
            if (this.property_details?.address) {
                let addr = this.property_details.address;
                return addr;
            } else {
                return "N.A";
            }
        },
        city() {
            if (this.property_details?.city) {
                let city = this.property_details.city;
                return city;
            } else {
                return "N/A";
            }
        },
        county_zip() {
            if (this.property_details?.county) {
                let county_city =
                    (this.property_details?.county
                        ? ", " + this.property_details.county
                        : "") +
                    (this.property_details?.zip_code
                        ? ", " + this.property_details.zip_code
                        : "");
                return county_city;
            } else {
                return "N/A";
            }
        },
        only_property_price() {
            if (this.property_details?.list_price) {
                return this.property_details.list_price;
            } else {
                return 0;
            }
        },
        factsFeaturesLeftArray() {
            return this.property_details.factsFeatures?.slice(0, 5)
        },
        factsFeaturesRightArray() {
            return this.property_details.factsFeatures?.slice(5)
        },
        getAllAmenities() {
            let all_amenities = [];
            if (this.property_details?.prop_feat1_out) {
                let prop_feat1_out = this.property_details.prop_feat1_out;
                all_amenities.push(prop_feat1_out);
            }
            if (this.property_details?.prop_feat2_out) {
                let prop_feat2_out = this.property_details.prop_feat2_out;
                all_amenities.push(prop_feat2_out);
            }
            if (this.property_details?.prop_feat3_out) {
                let prop_feat3_out = this.property_details.prop_feat3_out;
                all_amenities.push(prop_feat3_out);
            }
            if (this.property_details?.prop_feat4_out) {
                let prop_feat4_out = this.property_details.prop_feat4_out;
                all_amenities.push(prop_feat4_out);
            }
            if (this.property_details?.prop_feat5_out) {
                let prop_feat5_out = this.property_details.prop_feat5_out;
                all_amenities.push(prop_feat5_out);
            }
            if (this.property_details?.prop_feat6_out) {
                let prop_feat6_out = this.property_details.prop_feat6_out;
                all_amenities.push(prop_feat6_out);
            }
            return all_amenities;
        },
    },
    filters: {
        round_off(num) {
            return (Math.round(num * 100) / 100).toFixed();
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        aminityIcon(aminity) {
            return getAmenityIcon(aminity)
        }
    },
    watch: {
        property_details(newValue, oldValue) {
            console.log(newValue)
            if (newValue?.unique_id) {
                this.id = this.property_details.unique_id;
                this.getSoldPropertyList();
                this.getRelatedPropertyList();
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.idx-property-details-comp {
    --primary-clr: #0061df;

    ul {
        padding-left: 0;
    }

    .text-primary {
        color: var(--primary-clr);
    }

    .mb-30 {
        margin-bottom: 30px;
    }

    .new_property_details_bredc_content ul li:not(:last-child):before,
    .new_property_details_bredc_content ul li a {
        color: #6E6E73;
    }

    .property-slider__container {
        --space: 20px;
        position: relative;

        .carousel {
            img {
                border-radius: 20px;
                height: 550px;
                max-width: 100%;
                object-fit: cover;
            }

            .carousel-control-prev,
            .carousel-control-next {
                width: 30px;
                height: 30px;
                background-color: rgba(255, 255, 255, 0.65);
                border-radius: 10px;
                top: 50%;
                opacity: 1;
                transition: opacity 0.1s ease-in-out 0s;
                transform: translateY(-50%);

                &>span {
                    display: none;
                }

                &:hover {
                    border: 2px solid var(--primary-clr);
                }
            }

            .carousel-control-next {
                right: var(--space);

                &::before {
                    content: "\f105";
                    font-family: "Font Awesome 5 Free";
                    font-family: "Font Awesome 5 Free";
                    color: var(--primary-clr);
                    font-weight: 600;
                    font-size: 20px;
                }
            }

            .carousel-control-prev {
                left: var(--space);

                &::before {
                    content: "\f104";
                    font-family: "Font Awesome 5 Free";
                    color: var(--primary-clr);
                    font-weight: 600;
                    font-size: 20px;
                }
            }

            .carousel-indicators {
                z-index: 4;

                li {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;

                    &.active {
                        background-color: var(--primary-clr);
                    }
                }
            }
        }

        .bottom-label {
            --label-space: calc(var(--space) + 1rem);
            bottom: var(--space);
            position: absolute;

            &.bottom-label-left {
                padding: 6px 16px;
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.70);
                left: var(--label-space);
            }

            &.bottom-label-right {
                right: var(--label-space);

                .btn {
                    color: var(--primary-clr);
                    padding: 8px 16px;
                    border-radius: 10px;
                    border-radius: 10px;
                    background: #FFF;
                }
            }
        }
    }

    .main-info-container {
        display: flex;
        align-items: center;
        margin: 1rem auto;

        .main-info {
            flex: 1;
            display: flex;
            align-items: center;

            .main-info__item {
                flex: 1;
                text-align: center;
                padding: 5px 0px;

                &:not(:last-child) {
                    border-right: 1px solid #D9D9D9;
                }

                h5 {
                    color: var(--primary-clr);
                    margin-bottom: 0px;
                    line-height: 1;
                }

                p {
                    font-weight: 500;
                    margin-bottom: 0px;
                }
            }
        }

        .price {
            flex-basis: 20%;
            text-align: right;

            h2 {
                color: var(--primary-clr);
            }
        }
    }

    .property-tabs-wrapper {
        background: #FFF;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px -1px 4px 0px rgba(0, 0, 0, 0.05);

        nav ul {
            display: flex;
            align-items: center;
            gap: 40px;
            margin-bottom: 0;

            a {
                display: inline-block;
                font-weight: 500;
                color: #6E6E73;
                padding: 10px 5px;

                &.active {
                    color: var(--primary-clr);
                    border-radius: 2px 2px 0px 0px;
                    border-bottom: 3px solid var(--primary-clr);
                }
            }
        }
    }

    .tabs-content-layout {
        display: grid;
        grid-template-columns: 1fr 350px;
        gap: 25px;

        aside {
            position: sticky;
            top: 80px;
            align-self: flex-start;
        }

        .left-section>section {
            padding: 50px 0px;
            border-bottom: 2px solid #E1E1E1;
        }
    }

    .fill-up-btn {
        border-radius: 50px 20px 20px 48px;
        background: #FFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 25px;
        margin-bottom: 30px;

        span {
            flex: 1;
            text-align: initial;
        }

        &:hover {
            background: linear-gradient(294deg, #0061DF 0%, rgba(0, 97, 223, 0.00) 100%);
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
            color: #fff;
        }

        .img-container {
            width: 67px;
            height: 67px;
            border-radius: 50%;
            background-color: var(--primary-clr);
            position: relative;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 11px solid rgba(0, 97, 223, 0.7);
                border-radius: 50%;
            }


            &:before {
                animation: ripple 2s linear infinite;
            }

            &:after {
                animation: ripple 2s linear 1s infinite;
            }
        }



        @keyframes ripple {
            0% {
                transform: scale(1.1);
            }

            50% {
                transform: scale(1.5);
                opacity: 1;
            }

            100% {
                transform: scale(1.8);
                opacity: 0;
            }
        }
    }

    aside.right-section {
        padding-top: 50px;



        .info-blue-card {
            background: #EAF3FF;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
            display: flex;
            align-items: center;
            padding: 16px;
            height: 100px;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 18px;

            small {
                font-size: 12px;
                color: #575F6B;
            }

            p {
                font-size: 13px;
                font-weight: 500;
                color: #212121;
            }

            img {
                width: 52px;
            }

            &.co-investment-card {
                img {
                    width: 80px;
                }
            }
        }


    }

    #property-overview {
        h3 {
            margin-bottom: 20px;
        }

        .property-overview__features {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 30px 20px;
            margin-bottom: 30px;

            label {
                font-size: 14px;
                color: #6E6E73;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            p {
                color: #323232;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0px;
            }
        }

        .about-property-wrapper {
            position: relative;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(217, 217, 217, 0.59) 100%);
            padding: 10px;
            padding-bottom: 1.2rem;

            .about-property {
                margin-bottom: 0;
                height: 90px;
                overflow-y: hidden;

                &.full {
                    height: auto;
                }
            }

            .read-more-btn {
                display: inline-flex;
                padding: 6px 16px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 16px;
                border: 1px solid #D1D1D1;
                background: #FFF;
                font-size: 14px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 50%);

                &>div {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
    }

    #explore-neighborhood {
        border-bottom-width: 0px;

        .map-view iframe {
            width: 100%;
            height: 250px;
            border-radius: 10px;
            background: lightgray 0px -56.807px / 100% 133.34% no-repeat;
            box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
        }

        .neighborhood-features {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            row-gap: 40px;
            column-gap: 5px;
        }
    }

    #amenities-section {
        .amenities-list {
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            row-gap: 40px;
            column-gap: 10px;
        }
    }

    #property-details-section {
        ul {
            li {
                display: flex;
                align-items: center;

                b {
                    margin-left: 6px;
                    font-weight: 500;
                }
            }

            li::before {
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--primary-clr);
                margin-right: 14px;
            }
        }
    }

    #facts-features-section {
        .facts-features-container {
            display: flex;
            gap: 30px;

            &>* {
                flex: 1;
            }
        }

        .facts-features-item {
            label {
                font-weight: 600;
                margin-bottom: 10px;
            }
        }
    }

    #estimated-section {
        .estimated-card {
            background-color: #F7FBFF;
            padding: 30px;
        }

        .estimated-card-header {
            display: flex;
            gap: 20px;
            align-items: flex-start;

            &,
            small {
                font-size: 13px;
            }

            .estimated-property-details {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                column-gap: 6px;
                line-height: 0;
                width: 270px;


                &>* {
                    display: flex;
                    align-items: center;
                    gap: 3px;
                }

                &> :first-child {
                    flex-basis: 100%;
                    margin-bottom: 3px;
                }
            }

            margin-bottom: 40px;
        }

        .estimated-card-body {
            margin-bottom: 100px;

            .estimated-slider-wrapper {
                background: linear-gradient(90deg, #FFF 0%, #EBECF0 100%);
                height: 100px;
                padding: 30px 50px;
                border-radius: 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.20);

                .slider-bar {
                    height: 7px;
                    background-color: #382AFF;
                    position: relative;

                    .slider-bar-ball {
                        width: 19px;
                        height: 19px;
                        border-radius: 50%;
                        background: linear-gradient(rgba(41, 144, 233, 1), rgba(0, 97, 223, 1));
                        position: absolute;
                    }

                    &::before,
                    &::after {
                        content: "";
                        display: block;
                        width: 17px;
                        height: 17px;
                        border-radius: 50%;
                        background: linear-gradient(rgba(41, 144, 233, 1), rgba(0, 97, 223, 1));
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &::after {
                        right: 0;
                        transform: translate(50%, -50%);
                    }

                    .slider-bar-ball.center {
                        width: 28px;
                        height: 28px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border: 6px solid #fff;
                    }

                }
            }

            .slider-price {
                width: 110px;
                height: 45px;
                background: linear-gradient(119deg, #FFF 0%, rgba(235, 236, 240, 0.19) 100%);
                box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.2);
                color: var(--primary-clr);
                position: absolute;
                top: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
                transform: translateX(-50%);

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    border: 8px solid transparent;
                    border-bottom-color: #F9F9FA;
                    top: -8px;
                    transform: translateY(-50%);
                }

                &--right {
                    right: 0;
                    transform: translateX(50%);
                }

                &--center {
                    background: linear-gradient(119deg, #2990E9 0%, #0061DF 100%);
                    color: #fff;
                    box-shadow: 0px 31.539297103881836px 63.07859420776367px rgba(0, 0, 0, 0.20);
                }

                &--center::after {
                    border-bottom-color: #157CE5;
                }
            }

            .slider-bar-ball.center,
            .slider-price--center {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .estimated-card-footer {
            p {
                font-size: 13px;
            }
        }
    }

    .circle-icon-with-title {
        --icon-wrapper-size: 42px;
        --icon-size: 25px;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 12px;

        .icon-wrapper {
            background-color: #fff;
            border-radius: 50%;
            width: var(--icon-wrapper-size);
            height: var(--icon-wrapper-size);
            filter: drop-shadow(0px 2px 5px rgba(0, 97, 223, 0.20));
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: var(--icon-size);
                height: var(--icon-size);
            }
        }

        p {
            font-size: 13px;
        }
    }

    .new_property_details_bredc_content ul li.text-primary:before {
        display: none;
    }

    .nearby-schools-section {
        h3 {
            margin-bottom: 50px;
        }

        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
            margin: 0;
            padding: 0;

            li {
                box-shadow: 0px 4px 4px 0px rgba(0, 97, 223, 0.15);
                padding: 30px 24px 24px 24px;

                p {
                    color: #414141;
                    ;
                }

                p:first-of-type {
                    margin-bottom: 10px;
                }

                p:last-child {
                    margin-bottom: 0;
                }

                h4 {
                    font-size: 20px;
                    margin-bottom: 15px;
                }
            }
        }
    }

    @media (max-width:567px) {
        .new_property_details_bredc_content ul {
            li {
                font-size: 13px;

                &:not(:last-child) {
                    margin-right: 20px;

                    &:before {
                        right: -14px;
                        top: 0px;
                        font-size: inherit;
                    }
                }

                a {
                    font-size: inherit;
                }
            }
        }

        .new_property_details_bredc_content+.row {
            margin-bottom: 14px;
            align-items: center;

            h1 {
                font-size: 20px;
                line-height: normal;
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0;
            }
        }

        .property-slider__container {
            padding: 0;

            #property-img {
                .carousel-item img {
                    height: 250px;
                    border-radius: 6.763px;
                }

                .carousel-indicators {
                    bottom: -17px;

                    li {
                        width: 6px;
                        height: 6px;
                    }
                }
            }

            .bottom-label.bottom-label-left {
                padding: 2.164px 4.328px;
                border-radius: 2.705px;
                left: 12px;
                bottom: 12px;
                font-size: 14px;
            }

            .bottom-label-right {
                // display:none;
                bottom: -6px;
                right: 16px !important;
                transform: translateY(100%);
                box-shadow: 0px 5px 5px 0px rgba(0, 97, 223, 0.10);
                border-radius: 7.545px;

                button {
                    padding: 6.036px 12.072px;
                }
            }
        }

        .price-container-mobile {
            padding-top: 13px;
            padding-bottom: 6px;

            h2 {
                font-size: 24px;
                color: var(--primary-clr);
            }
        }

        .main-info-container {
            .price {
                display: none;
            }

            .main-info__item {
                h5 {
                    font-size: 17px !important;
                }

                p {
                    font-size: 14px !important;
                    color: #6E6E73 !important;
                }
            }
        }

        .property-tabs-wrapper {
            box-shadow: none;
            gap: 0;

            ul {
                justify-content: space-between;
                gap: 0 !important;

                li {
                    a {
                        padding: 7px 0px;
                        font-size: 12px;
                    }
                }

            }
        }

        .tabs-content-layout {
            grid-template-columns: 1fr;
            // margin-bottom: 80px !important;

            .right-section {
                display: none;
            }

            h3 {
                font-size: 20px;
                text-align: center;
            }

            #property-overview {
                padding-bottom: 30px;
                border: none;
                padding-top: 15px;

                .property-overview__features {
                    grid-template-columns: repeat(3, 1fr);
                    gap: 15px 5px;
                    max-width: 100%;
                }

                .read-more-btn {
                    text-wrap: nowrap;
                }
            }

            #facts-features-section {
                padding-bottom: 0;
                border: none;

                .facts-features-container {
                    flex-wrap: wrap;
                    gap: 0;

                    &> :first-child {
                        flex-basis: 100%;
                    }
                }
            }

            #property-details-section {
                padding-top: 30px;
                padding-bottom: 0px;
                border: none;

                li {
                    align-items: flex-start;
                    margin-bottom: 8px;

                    b {
                        flex: 1;
                    }

                    &::before {
                        transform: translateY(8px);
                    }
                }
            }

            #amenities-section {
                padding: 0;
                padding-bottom: 10px;
                border: none;

                .amenities-list {
                    grid-template-columns: repeat(4, 1fr);
                    column-gap: 5px;
                }
            }

            #estimated-section {
                .estimated-card {
                    padding: 0;
                    background-color: transparent;
                }

                .estimated-card-header {
                    flex-direction: column;
                    gap: 15px;

                    p {
                        margin-bottom: 0;
                    }

                    font-size: 14px;
                }

                .estimated-slider-wrapper {
                    padding: 0px 45px;
                    border-radius: 15px;
                    height: 60px;
                    box-shadow: 0px 2px 25.41510009765625px rgba(4, 119, 255, 0.20);

                    .slider-bar {
                        height: 4px;

                        &::after,
                        &::before {
                            width: 10px;
                            height: 10px;
                        }
                    }

                    .slider-bar-ball.center {
                        width: 22px !important;
                        height: 22px !important;
                        box-shadow: 0px 22.058576583862305px 44.11715316772461px rgba(4, 119, 255, 0.20);
                        scale: 1.1;
                    }

                    .slider-price {
                        top: 62px;
                        font-size: 13px;
                        width: 90px;
                        height: 37px;
                        box-shadow: 0px 13.480216979980469px 26.960433959960938px rgba(4, 119, 255, 0.20);
                    }
                }
            }
        }

        .fill-up-btn {
            position: fixed;
            z-index: 2;
            bottom: 80px;
            background: transparent;
            box-shadow: none;
            width: auto;
            margin-bottom: 0;
            margin-right: 12px;
            margin-top: 5px;

            &:hover {
                background: transparent;
            }

            &:focus {
                border: none;
            }
        }



        h3.mb-30 {
            margin-bottom: 25px !important;
            font-size: 24px !important;
            text-align: center !important;
        }

        // &>section:last-of-type {
        //     margin-bottom: 80px !important;
        // }

        .nearby-schools-section {
            h3 {
                margin-bottom: 20px !important;
            }

            ul {
                display: grid;
                grid-template-columns: 1fr;

                h4 {
                    font-size: 17px !important;
                }
            }
        }

        .common-property-list-grid {
            display: flex !important;
            overflow-x: auto;
            padding-bottom: 20px;
            width: 100%;

            &>* {
                min-width: 100%;
            }

            &::-webkit-scrollbar-track {
                border-radius: 100px;
            }

            &::-webkit-scrollbar-thumb {
                -webkit-appearance: none;
                border-radius: 100px;
            }

            &::-webkit-scrollbar {
                height: 8px;
            }

        }
    }
}
</style>
<style>
.sidebar_menu {
    top: 0px;
}
</style>
