<template>
    <a
        class="card map-property-card"
        :href="propertyDetailsLink.href"
        :target="propertyDetailsLink.target"
        :class="{ exclusive: isExclusive }"
    >
        <!-- <img
            :src="thumbnail"
            @error="handleImageError"
            alt="Property"
            class="card-img-top mb-0 bg-light"
        /> -->

        <!-- carousel start -->
        <div
            id="carouselExampleControls"
            class="carousel slide card-img-top mb-0 bg-light"
            data-ride="carousel"
            data-interval="false"
        >
            <div class="carousel-inner">
                <div
                    class="carousel-item"
                    v-for="(photoUrl, index) in property_details.photos"
                    :class="{ active: index === 0 }"
                >
                    <img class="d-block w-100" :src="photoUrl" alt="slide" />
                </div>
            </div>
            <a
                class="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
                @click="handleSliderNavigation"
            >
                <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
            </a>
            <a
                class="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
                @click="handleSliderNavigation"
            >
                <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
            </a>
            <div class="carousel-counter" v-if="property_details.photos.length">
                {{ currentImgSlideIndex + 1 }} /{{
                    property_details.photos.length
                }}
            </div>
        </div>
        <!-- carousel end -->

        <div class="card-body">
            <div class="list-price-container">
                <h4>${{ list_price | round_off | numberWithCommas }}</h4>
                <span class="listed-time">{{ get_updated_date_or_days }}</span>
            </div>

            <div class="property-overview">
                <div class="property-overview__item">
                    <span class="text-dark">{{ bedrooms }}</span>
                    bd
                </div>
                <div class="property-overview__item">
                    <span class="text-dark">{{ bathrooms }}</span>
                    ba
                </div>
                <div class="property-overview__item">
                    <span class="text-dark">{{ square_footage }}</span>
                    sqft
                </div>
                <div class="property-overview__item">
                    <span class="text-dark">{{ garage }}</span>
                    prkg
                </div>
            </div>
            <div>{{ street_address }} ({{ property_type }})</div>
        </div>
    </a>
</template>

<script>
export default {
    name: "MapPropertyCard",
    props: {
        property_details: {
            required: true,
        },
        image_base_url: {
            default: "https://teamalliance.ca/",
        },
        isExclusive: {
            default: false,
        },
    },
    data() {
        return {
            url: "",
            exclusive_property: false,
            currentImgSlideIndex: 0,
        };
    },
    mounted() {
        this.url = this.image_base_url;
    },
    computed: {
        property_slug() {
            return (
                "/sellers/property-details/" +
                this.convertToSlug(
                    this.property_details["address"] +
                        ", " +
                        this.property_details["city"] +
                        ", " +
                        this.property_details["area"] +
                        ", " +
                        this.property_details["zip_code"] +
                        ", " +
                        this.property_details["county"]
                ) +
                "/" +
                this.property_details["unique_id"]
            );
        },
        property_address() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return (
                    this.property_details["street_address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["zip_code"] +
                    ", " +
                    this.property_details["county"]
                );
            } else {
                return (
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["zip_code"] +
                    ", " +
                    this.property_details["county"]
                );
            }
        },
        property_address_community() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return (
                    this.property_details["street_address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["community"]
                );
            } else {
                return (
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["community"]
                );
            }
        },
        bedrooms() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return this.property_details["bedrooms"]
                    ? this.property_details["bedrooms"]
                    : 0;
            } else {
                return this.property_details["bedrooms"]
                    ? this.property_details["bedrooms"] -
                          this.addtional_bedrooms
                    : 0;
            }
        },
        addtional_bedrooms() {
            if (
                this.property_details["additional_bathroom"] == "0" ||
                this.property_details["additional_bathroom"] === 0 ||
                this.property_details["additional_bathroom"] === "undefined" ||
                this.property_details["additional_bathroom"] == "undefined" ||
                this.property_details["additional_bathroom"] === undefined
            ) {
                return this.property_details["additional_bedrooms"]
                    ? this.property_details["additional_bedrooms"]
                    : 0;
            } else {
                return this.property_details["additional_bathroom"];
            }
        },
        bathrooms() {
            return this.property_details["bathrooms"]
                ? this.property_details["bathrooms"]
                : 0;
        },
        garage() {
            return this.property_details["garage"] || 0;
        },
        square_footage() {
            return this.property_details["square_footage"]
                ? this.property_details["square_footage"]
                : "-";
        },
        list_price() {
            return this.property_details["list_price"];
        },
        virtual_tour_url() {
            return this.property_details["virtual_tour_url"];
        },
        total_photos() {
            return this.property_details["photos"].length;
        },
        property_type() {
            return this.property_details["property_type"];
        },
        listing_purpose() {
            return this.property_details["sale_or_lease"];
        },
        u_id() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return this.property_details["id"];
            } else {
                return this.property_details["unique_id"];
            }
        },
        thumbnail() {
            if (typeof this.property_details["photos"][0] === "object") {
                return this.property_details["photos"][0]
                    ? this.property_details["photos"][0]["url"]
                    : "/assets/images/no-image-available.png";
            } else {
                return this.property_details["photos"][0]
                    ? this.property_details["photos"][0]
                    : "/assets/images/no-image-available.png";
            }
        },
        isExclusiveProperty() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return (this.exclusive_property = true);
            }
        },
        street_address() {
            return this.property_details["address"];
        },

        realtor() {
            return this.property_details["realtor"];
        },

        canonical() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return (
                    this.url +
                    "/exclusive-property-detail/" +
                    this.property_details["unique_code"]
                );
            } else {
                return (
                    this.url +
                    "/sellers/property-details/" +
                    this.convertToSlug(
                        this.property_details["address"] +
                            ", " +
                            this.property_details["city"] +
                            ", " +
                            this.property_details["area"] +
                            ", " +
                            this.property_details["zip_code"] +
                            ", " +
                            this.property_details["county"]
                    ) +
                    "/" +
                    this.property_details["unique_id"]
                );
            }
        },
        main_image() {
            return this.property_details["photos"][0] || "";
        },

        zip_code() {
            return this.property_details["zip_code"];
        },

        start_date() {
            return this.property_details["start_date"];
        },

        sale_property_type() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return this.property_details["property_listing_type"]
                    ? this.property_details["property_listing_type"]
                    : "Exclsuive Property";
            }
        },
        get_updated_date_or_days() {
            let todays_day = new Date();
            let last_updated_date = new Date(this.property_details.updated_at);
            if (
                this.getDifferenceInDays(last_updated_date, todays_day) == 1 ||
                this.getDifferenceInDays(last_updated_date, todays_day) == 0
            ) {
                return (
                    "Added " +
                    this.getDifferenceInHours(last_updated_date, todays_day) +
                    " Hours Ago"
                );
            } else {
                return (
                    "Added " +
                    this.getDifferenceInDays(last_updated_date, todays_day) +
                    " Days Ago"
                );
            }
        },
        lat() {
            return this.property_details.latitude || "";
        },
        lang() {
            return this.property_details.longitude || "";
        },
        // isSelectedForShare(){
        //     return this.selectedShareProperties.findIndex()
        // }
        propertyDetailsLink() {
            let value = this.property_details;
            let href = "";
            let target = "_self";

            if (
                value["unique_id"] === undefined ||
                value["unique_id"] == "undefined" ||
                value["unique_id"] === "undefined"
            ) {
                href =
                    this.url +
                    "/exclusive-property-detail/" +
                    this.property_details["unique_code"];
            } else {
                href =
                    "/sellers/property-details/" +
                    this.convertToSlug(
                        value["address"] +
                            ", " +
                            value["city"] +
                            ", " +
                            value["area"] +
                            ", " +
                            value["zip_code"] +
                            ", " +
                            value["county"]
                    ) +
                    "/" +
                    value["unique_id"];
                target = "_blank";
            }
            return {
                href,
                target,
            };
        },
    },
    methods: {
        goToPropertyDetails(value) {
            if (
                value["unique_id"] === undefined ||
                value["unique_id"] == "undefined" ||
                value["unique_id"] === "undefined"
            ) {
                window.open(
                    this.url +
                        "/exclusive-property-detail/" +
                        this.property_details["unique_code"],
                    "_self"
                );
            } else {
                window.location.href =
                    "/sellers/property-details/" +
                    this.convertToSlug(
                        value["address"] +
                            ", " +
                            value["city"] +
                            ", " +
                            value["area"] +
                            ", " +
                            value["zip_code"] +
                            ", " +
                            value["county"]
                    ) +
                    "/" +
                    value["unique_id"];
            }
        },
        convertToSlug(val) {
            return val
                .toLowerCase()
                .replace(/[^\w ]+/g, "")
                .replace(/ +/g, "-");
        },
        handleImageError(event) {
            event.preventDefault();
            event.target.src = "/assets/images/no-image-available.png";
        },
        getDifferenceInDays(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return (diffInMs / (1000 * 60 * 60 * 24)) | this.round_off;
        },
        getDifferenceInHours(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return (diffInMs / (1000 * 60 * 60)) | this.round_off;
        },
        toggleShareProperty(e) {
            e.stopPropagation();
            this.$emit("share");
        },
        handleSliderNavigation(event) {
            if (!this.property_details.photos?.length) return;
            console.log(event, event?.target?.dataset?.slide);
            const buttonId = event?.target?.dataset?.slide;
            if (buttonId === "prev") {
                this.currentImgSlideIndex--;
                if (this.currentImgSlideIndex < 0) {
                    this.currentImgSlideIndex =
                        this.property_details.photos?.length - 1;
                }
            } else if (buttonId === "next") {
                this.currentImgSlideIndex++;
                if (
                    this.currentImgSlideIndex ===
                    this.property_details.photos?.length
                ) {
                    this.currentImgSlideIndex = 0;
                }
            }
        },
    },
    filters: {
        round_off(num) {
            return (Math.round(num * 100) / 100).toFixed();
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
};
</script>

<style lang="scss">
.map-property-card {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    max-width: 300px;
    // border-radius: 10px;
    color: unset;
    border-width: 0;
    &:hover {
        text-decoration: none;
    }
    .card-img-top {
        height: 240px;
        width: 100%;
        max-width: 100% !important;
        &:hover {
            .carousel-control-next,
            .carousel-control-prev {
                opacity: 1;
            }
        }
        img {
            transform: scale(1);
            filter: brightness(100%);
            transition: transform 260ms ease-in-out 0s,
                filter 260ms ease-in-out 0s;
            object-fit: cover;
            height: 240px;
            max-width: 100%;
            margin-bottom: 0px;
            max-height: none;
            &:hover {
                transform: scale(1.02);
                filter: brightness(105%);
                transition: transform 260ms ease-in-out 0s,
                    filter 260ms ease-in-out 0s;
            }
        }
    }
    .card-body {
        padding: 8px;
        padding-bottom: 16px;
    }
    .list-price-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
            font-size: 20px;
            margin: 0;
        }
        .listed-time {
            color: #6c757d;
        }
        margin-bottom: 10px;
    }
    .property-overview {
        color: #6c757d;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 10px;
        span {
            font-weight: 700;
        }
    }
    &.exclusive::before {
        content: "Exclusive";
        border-radius: 4px;
        font-size: 12px;
        padding: 4px 14px;
        background: var(--map-primary-clr);
        position: absolute;
        left: 12px;
        top: 12px;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        z-index: 1;
    }
    .carousel-control-prev,
    .carousel-control-next {
        width: 40px;
        height: 40px;
        padding: 4px 10px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 4px;
        border-radius: 50%;
        top: 50%;
        opacity: 0.7;
        transition: opacity 0.1s ease-in-out 0s;
        transform: translateY(-50%);
        & > span {
            display: none;
        }
        &:hover,&:focus {
            border: 2px solid var(--map-primary-clr);
        }
    }
    .carousel-control-next {
        right: 15px;
        &::before {
            content: "\f105";
            font-family: "Font Awesome 5 Free";
            font-family: "Font Awesome 5 Free";
            color: #000;
            font-weight: 600;
            font-size: 20px;
        }
    }
    .carousel-control-prev {
        left: 15px;
        &::before {
            content: "\f104";
            font-family: "Font Awesome 5 Free";
            color: #000;
            font-weight: 600;
            font-size: 20px;
        }
    }
    .carousel-counter {
        position: absolute;
        bottom: 16px;
        right: 8px;
        padding: 2px 8px;
        border-radius: 24px;
        color: white;
        background: #373d3c;
        opacity: 0.9;
        font-weight: 600;
    }
}
</style>
