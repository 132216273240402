<template>
    <div>
        <div class="property_feature_listings_box_item h-100" v-b-modal="'modal-center-' + u_id">
            <!-- @click="goToPropertyDetails(property_details)" -->
            <div class="property_feature_listings_box_item_image">
                <img :src="thumbnail" @error="handleImageError" class="img-fluid" alt="" />
                <span class="type_point">{{ property_type }}</span>
                <span class="sale">{{ listing_purpose }}</span>
                <span class="sale" style="margin-top: 110px;font-weight: 600;background: linear-gradient(180deg, #F99B0F 0%, #C64020 100%);box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);border-radius: 14px;" v-if="isExclusiveProperty === true">{{ sale_property_type }}</span>
                <span class="image"><img src="/images/camera.png" class="img-fluid" />{{
                        total_photos
                }}</span>
                <span class="video"><a :href="
                    virtual_tour_url
                        ? virtual_tour_url
                        : 'javascript:void(0)'
                " target="_blank" style="color: white; text-decoration: none"><img src="/images/play.png"
                            class="img-fluid" />{{
                                    virtual_tour_url ? 1 : 0
                            }}</a></span>
            </div>
            <div class="property_feature_listings_box_item_content">
                <a :href="propertyDetailsLink.url" style="text-decoration: none; color: #212529"><span class="location">{{
                        property_address
                }}</span></a>
            </div>
            <div class="property_feature_listings_box_item_bottomcontent">
                <span v-if="addtional_bedrooms > 0" class="bedroom"><img src="/images/double-bed.png"
                        class="img-fluid" />{{
                                bedrooms
                        }} + {{ addtional_bedrooms }}
                    Beds</span>
                <span v-else class="bedroom"><img src="/images/double-bed.png" class="img-fluid" />{{
                        bedrooms
                }}
                    Beds</span>
                <span class="bathroom"><img src="/images/fbath.png" class="img-fluid" />{{
                        bathrooms
                }}
                    Baths</span>
                <span class="garage"><img src="/images/garage.png" class="img-fluid" />{{
                        garage
                }}
                    Garage</span>
                <span class="measure"><img src="/images/measure.png" class="img-fluid" />{{
                        square_footage
                }}
                    Sq Ft</span>
            </div>
            <div class="property_feature_listings_box_item_price">
                <span class="price">${{ list_price | round_off | numberWithCommas }}</span>
            </div>
            <!-- Modal Here Start -->
            <div>
                <b-modal :id="'modal-center-' + u_id" centered title="Property Detail" hide-header hide-footer>
                    <span @click="$bvModal.hide('modal-center-' + u_id)">
                        <b-icon icon="x-circle" class="mb-3" scale="2" style="float:right;cursor: pointer;"
                            variant="danger"></b-icon>
                    </span>
                    <div id="carouselExampleIndicators" class="carousel slide mt-3" data-ride="carousel">
                        <ol class="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                        </ol>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img :src="thumbnail" @error="handleImageError" class="rounded img-fluid" alt="image" />
                            </div>
                            <div class="carousel-item">
                                <img :src="thumbnail_1" @error="handleImageError" class="rounded img-fluid"
                                    alt="image" />
                            </div>
                            <div class="carousel-item">
                                <img :src="thumbnail_2" @error="handleImageError" class="rounded img-fluid"
                                    alt="image" />
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                            data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                            data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                    <p class="my-4" @click="goToPropertyDetails(property_details)">{{ property_address_community }}</p>
                    <div class="property_feature_listings_box_item_bottomcontent text-center">
                        <span v-if="addtional_bedrooms > 0" class="bedroom"><img src="/images/double-bed.png"
                                class="img-fluid" />{{
                                        bedrooms
                                }} + {{ addtional_bedrooms }}
                            Beds</span>
                        <span v-else class="bedroom"><img src="/images/double-bed.png" class="img-fluid" />{{
                                bedrooms
                        }}
                            Beds</span>
                        <span class="bathroom"><img src="/images/fbath.png" class="img-fluid" />{{
                                bathrooms
                        }}
                            Baths</span>
                        <span class="garage"><img src="/images/garage.png" class="img-fluid" />{{
                                garage
                        }}
                            Garage</span>
                        <span class="measure"><img src="/images/measure.png" class="img-fluid" />{{
                                square_footage
                        }}
                            Sq Ft</span>
                    </div>
                    <div class="property_feature_listings_box_item_price">
                        <span class="price">${{ list_price | round_off | numberWithCommas }}</span>
                        <a class="btn btn-outline-primary ms-auto" style="margin-left: 100px;"
                            :href="propertyDetailsLink.url" target="_blank">View Full
                            Listing</a>
                    </div>
                </b-modal>
            </div>
            <!-- Modal Here end -->
        </div>
    </div>
</template>

<script>
export default {
    name: "ExcluisvePropertyCard",
    props: {
        property_details: {
            required: true,
        },
        image_base_url: {
            default:'https://teamalliance.ca/'
        }
    },
    data() {
        return {
            url:'',
            exclusive_property:false,
        };
    },
    mounted() {
        this.url = this.image_base_url;
    },
    computed: {
        property_slug() {
            return (
                "/sellers/property-details/" +
                this.convertToSlug(
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["zip_code"] +
                    ", " +
                    this.property_details["county"]
                ) +
                "/" +
                this.property_details["unique_id"]
            );
        },
        property_address() {
            if(this.property_details["unique_id"] === undefined || this.property_details["unique_id"] == 'undefined' || this.property_details["unique_id"] === 'undefined') {
                return (
                     this.property_details["street_address"] +
                     ", " +
                     this.property_details["city"] +
                     ", " +
                     this.property_details["area"] +
                     ", " +
                     this.property_details["zip_code"] +
                     ", " +
                     this.property_details["county"]
                );
            }else {
                return (
                this.property_details["address"] +
                ", " +
                this.property_details["city"] +
                ", " +
                this.property_details["area"] +
                ", " +
                this.property_details["zip_code"] +
                ", " +
                this.property_details["county"]
            );
           }
        },
        property_address_community() {
            if(this.property_details["unique_id"] === undefined || this.property_details["unique_id"] == 'undefined' || this.property_details["unique_id"] === 'undefined') {
               return (
                    this.property_details["street_address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["community"]
                );
            }else {
                 return (
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["community"]
                );
            }
        },
        bedrooms() {
            if(this.property_details["unique_id"] === undefined || this.property_details["unique_id"] == 'undefined' || this.property_details["unique_id"] === 'undefined') { 
                return this.property_details["bedrooms"]
                ? this.property_details["bedrooms"]
                : 0;
            }
            else {
            return this.property_details["bedrooms"]
                ? this.property_details["bedrooms"] - this.addtional_bedrooms
                : 0;
            }
        },
        addtional_bedrooms() {
            if (this.property_details['additional_bathroom'] == '0' || this.property_details['additional_bathroom'] === 0 || this.property_details["additional_bathroom"] === 'undefined' || this.property_details["additional_bathroom"] == 'undefined' || this.property_details["additional_bathroom"] === undefined) {
                return this.property_details["additional_bedrooms"]
                ? this.property_details["additional_bedrooms"]
                : 0;
            }
            else {
                return this.property_details['additional_bathroom'];
            }
        },
        bathrooms() {
            return this.property_details["bathrooms"]
                ? this.property_details["bathrooms"]
                : 0;
        },
        garage() {
            if(this.property_details["garage_spaces"] === 'undefined' || this.property_details["garage_spaces"] == 'undefined' || this.property_details["garage_spaces"] === undefined ) {
                return this.property_details["garage"]
                ? this.property_details["garage"]
                : 0;
            }else {
                return this.property_details["garage_spaces"]
                ? this.property_details["garage_spaces"]
                : 0;
            }
        },
        square_footage() {
            return this.property_details["square_footage"]
                ? this.property_details["square_footage"]
                : 0;
        },
        list_price() {
            return this.property_details["list_price"];
        },
        virtual_tour_url() {
            return this.property_details["virtual_tour_url"];
        },
        total_photos() {
            return this.property_details["photos"].length;
        },
        property_type() {
            return this.property_details["property_type"];
        },
        listing_purpose() {
            return this.property_details["sale_or_lease"];
        },
        u_id() {
            if(this.property_details["unique_id"] === undefined || this.property_details["unique_id"] == 'undefined' || this.property_details["unique_id"] === 'undefined') {
                return this.property_details['id'];
            }else {
                return this.property_details["unique_id"];
            }
        },
        thumbnail() {
            if(typeof this.property_details["photos"][0] === 'object'){
                return this.property_details["photos"][0]
                ? this.property_details["photos"][0]['url']
                : "/assets/images/no-image-available.png";
            }
            else {
             return this.property_details["photos"][0]
                ? this.property_details["photos"][0]
                : "/assets/images/no-image-available.png";
            }
        },
        thumbnail_1() {
            if(typeof this.property_details["photos"][1] === 'object'){
                return this.property_details["photos"][1]
                ? this.property_details["photos"][1]['url']
                : "/assets/images/no-image-available.png";
            }
            else {
             return this.property_details["photos"][1]
                ? this.property_details["photos"][1]
                : "/assets/images/no-image-available.png";
            }
        },
        thumbnail_2() {
            if(typeof this.property_details["photos"][2] === 'object'){
                return this.property_details["photos"][2]
                ? this.property_details["photos"][2]['url']
                : "/assets/images/no-image-available.png";
            }
            else {
             return this.property_details["photos"][2]
                ? this.property_details["photos"][2]
                : "/assets/images/no-image-available.png";
            }
        },
        isExclusiveProperty() {
            if(this.property_details["unique_id"] === undefined || this.property_details["unique_id"] == 'undefined' || this.property_details["unique_id"] === 'undefined'){
                return this.exclusive_property = true;
            }
        },
        sale_property_type() {
            if(this.property_details["unique_id"] === undefined || this.property_details["unique_id"] == 'undefined' || this.property_details["unique_id"] === 'undefined'){
                return this.property_details["property_listing_type"]
                ? this.property_details["property_listing_type"]
                : 'Exclsuive Property';
            }
        },
        propertyDetailsLink(){
            if(this.property_details["unique_id"] === undefined || this.property_details["unique_id"] == 'undefined' || this.property_details['unique_id'] === 'undefined') {
                return {
                    url: this.url+'/exclusive-property-detail/' + this.property_details["unique_code"],
                    target:"_self"
                }
            }
            else {
                const url =
                "/sellers/property-details/" +
                this.convertToSlug(
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["zip_code"] +
                    ", " +
                    this.property_details["county"]
                ) +
                "/" +
                this.property_details["unique_id"];
                return {
                    url,
                    target:"_self"
                }
            }
        }
    },
    methods: {
        goToPropertyDetails(value) {
            if(value["unique_id"] === undefined || value["unique_id"] == 'undefined' || value['unique_id'] === 'undefined') {
                window.open(this.url+'/exclusive-property-detail/' + this.property_details["unique_code"],"_self");
            }
            else {
                window.location.href =
                "/sellers/property-details/" +
                this.convertToSlug(
                    value["address"] +
                    ", " +
                    value["city"] +
                    ", " +
                    value["area"] +
                    ", " +
                    value["zip_code"] +
                    ", " +
                    value["county"]
                ) +
                "/" +
                value["unique_id"];
            }
        },
        convertToSlug(val) {
            return val
                .toLowerCase()
                .replace(/[^\w ]+/g, "")
                .replace(/ +/g, "-");
        },
        handleImageError(event) {
            event.preventDefault();
            event.target.src = "/assets/images/no-image-available.png";
        },
    },
    filters: {
        round_off(num) {
            return (Math.round(num * 100) / 100).toFixed();
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
};
</script>
