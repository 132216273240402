<template>
    <div class="Container">
        <common-property-card v-for="property in properties" :key="property.id" :property_details="property"
            :user_id="user_id" :userFavProperties="userFavProperties" @onFavoriteSuccess="handleFavoriteSuccess"
            type="NEW_CONSTRUCTION" />
    </div>
</template>
<script>
import axios from "axios";
import CommonPropertyCard from "../../../pages/PropertyCard/CommonPropertyCard/CommonPropertyCard.vue";
export default {
    name: "PropertyGrid",
    components: {
        "common-property-card": CommonPropertyCard,
    },
    data(){
        return{
            properties:[]
        }
    },
    mounted(){
      this.getProperties()
    },
    methods: {
        async getProperties(){
           try{
               const response = await axios.get('https://www.redbuyers.com/api/new-construction')
               const data = response.data.data.property.data.slice(0, 6);
               this.properties = data
           }catch(error){
            throw new Error(error)
           }
        }
    }
}
</script>
<style lang="scss" scoped>
 .Container{
    width:min(80rem, 95%);
    margin: 3rem auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(316px, 1fr));
    gap: 10px;
    padding-bottom: 1rem;
 }
</style>
