<template>
    <div>
        <p class="step-label">EDIT PROPERTY - Step 3</p>
        <div class="form-split">
            <!-- Form Column 1 -->
            <div>
                <!-- Ownership -->
                <div class="form-group">
                    <label class="d-block" for="ownership">Ownership</label>
                    <input type="text" id="ownership" class="form-control" v-model="ownership" />
                </div>
                <!-- Property Tax -->
                <div class="form-group">
                    <label class="d-block" for="property_tax">Property Tax</label>
                    <input type="text" id="property_tax" class="form-control" v-model="property_tax" />
                </div>
                <!-- Lot Size -->
                <!-- <div class="form-group">
                    <label class="d-block" for="lot_size">Lot Size</label>
                    <input
                        type="text"
                        id="lot_size"
                        v-model="lot_size"
                        class="form-control"
                    />
                </div> -->
                <!-- Build Year -->
                <div class="form-group">
                    <label class="d-block" for="built_year">Build Year</label>
                    <input type="text" id="built_year" v-model="built_year" class="form-control" />
                </div>
                <!-- Sales started -->
                <div class="form-group">
                    <label class="d-block" for="sales_started_at">Sales Started</label>
                    <input type="text" id="sales_started_at" v-model="sales_started_at" class="form-control" />
                </div>

                <!-- Sales Company -->
                <div class="form-group">
                    <label class="d-block" for="sales_company">Sales Company</label>
                    <input type="text" id="sales_company" v-model="sales_company" class="form-control" />
                </div>

                <!-- C.C / Maintenance -->
                <div class="form-group">
                    <label class="d-block" for="maintenance_charge">C.C / Maintenance</label>
                    <input type="text" id="maintenance_charge" v-model="maintenance_charge" class="form-control" />
                </div>

                <!-- Parking Type -->
                <div class="form-group">
                    <label class="d-block" for="parking_type">Parking Type</label>
                    <input type="text" id="parking_type" v-model="parking_type" class="form-control" />
                </div>

                <!-- Purchase Cost -->
                <div class="form-group">
                    <label class="d-block" for="cost_of_parking">Parking Cost</label>
                    <input type="text" id="cost_of_parking" v-model="cost_of_parking" class="form-control" />
                </div>
            </div>

            <!-- Form Column 2 -->
            <div>
                <!-- Co-op Fee Realtors -->
                <div class="form-group">
                    <label class="d-block" for="co_op_fee">Co-op Fee Realtors</label>
                    <input type="text" id="co_op_fee" v-model="co_op_fee" class="form-control" />
                </div>

                <!-- Storage space purchase cost -->
                <div class="form-group">
                    <label class="d-block" for="purchase_storage">Storage space purchase cost</label>
                    <input type="text" id="purchase_storage" v-model="purchase_storage" class="form-control" />
                </div>

                <!-- Swimming Pool -->
                <div class="form-group">
                    <label class="d-block" for="swimming_pool">Swimming Pool</label>
                    <select class="form-control" id="swimming_pool" v-model="swimming_pool">
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                </div>

                <!-- Overview text -->
                <div class="form-group">
                    <label class="d-block" for="overview_text">Overview Text</label>
                    <input type="text" id="overview_text" class="form-control" v-model="overview_text" />
                </div>

                <!-- Virtual tour video link -->
                <div class="form-group">
                    <label class="d-block" for="virtual_tour">Virtual tour video link</label>
                    <input type="text" id="virtual_tour" v-model="virtual_tour" class="form-control" />
                </div>

                <!-- Website URL -->
                <div class="form-group">
                    <label class="d-block" for="website_address">Website URL</label>
                    <input type="url" id="website_address" v-model="website_address" class="form-control" />
                </div>

                <!-- Number of units -->
                <div class="form-group">
                    <label class="d-block" for="no_of_units">Number of units</label>
                    <input type="text" id="no_of_units" v-model="no_of_units" class="form-control" />
                </div>

                <!-- Community Description -->
                <div class="form-group">
                    <label class="d-block" for="community_description">Community Description</label>
                    <ckeditor id="community_description" :editor="editor" v-model="community_description"
                        :config="editorConfig"></ckeditor>
                </div>
            </div>
        </div>
        <div class="form-button-group">
            <button class="save-btn save" @click="handleSave()" :disabled="isSaving">
                <span v-if="isSaving" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                Save
            </button>
            <button class="reset-btn" @click="handleReset()" :disabled="isSaving">
                Reset
            </button>
            <button class="next-btn next" @click="$emit('skip')" :disabled="isSaving">
                Next
            </button>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import pick from "lodash/pick";

const initialFormFields = {
    ownership: "",
    property_tax: "",
    lot_size: "",
    built_year: "",
    sales_started_at: "",
    sales_company: "",
    maintenance_charge: "",
    parking_type: "",
    cost_of_parking: "",
    co_op_fee: "",
    purchase_storage: "",
    swimming_pool: "",
    virtual_tour: "",
    website_address: "",
    no_of_units: "",
    community_description: "",
    overview_text: "",
};

export default {
    name: "Step3",
    props: {
        propertyDetails: {},
        isSaving: false,
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "outdent",
                        "indent",
                        "undo",
                        "redo",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "blockQuote",
                        "insertTable",
                    ],
                },
            },
            ...initialFormFields,
        };
    },
    mounted() {
        setTimeout(() => {
            this.setExistingFormData();
        }, 1000);
    },
    methods: {
        getAddressData(addressData, placeResultData, id) {
            if (placeResultData?.formatted_address) {
                this.builder_address = placeResultData.formatted_address;
            }
        },
        handleSave() {
            const FormData = pick(this, Object.keys(initialFormFields));
            this.$emit("skip");
            axios
                .post(
                    "/property-management/update-property-details/" +
                    this.propertyDetails?.id,
                    FormData
                )
                .then((response) => {
                    this.isSaving = false;
                })
                .catch((error) => {
                    console.error(error?.response?.data);
                })
                .finally((res) => {
                    this.updateData();
                    this.$emit("skip");
                    // window.location.reload();
                });
        },
        handleReset() {
            for (let key in initialFormFields) {
                this[key] = initialFormFields[key];
            }
        },
        setExistingFormData() {
            for (let key in initialFormFields) {
                this[key] = this.propertyDetails[key] || initialFormFields[key];
            }
        },
        updateData() {
            for (let key in initialFormFields) {
                this.propertyDetails[key] = this[key];
            }
        },
    },
};
</script>

<style lang="scss">
.form-split {
    .form-group {
        .form-control {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
        }

        textarea.form-control {
            height: 10rem;
        }

        .input-group-col-2 select {
            width: 186px;
        }
    }
}

.form-button-group {
    .save-btn.save {
        background-color: #0061df !important;
        color: #fff !important;
    }

    .reset-btn {
        border: 0.5px solid #e8e8e8 !important;
        color: #5f6165 !important;
    }

    .next-btn.next {
        color: var(--clr-primary) !important;
        border: 0.5px solid var(--clr-primary) !important;
        color: #0061df !important;
        border: 0.5px solid #0061df !important;
    }
}
</style>
