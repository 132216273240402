<template>
    <!-- <div class="container my-4">
        <div class="mt-100">
            <a
                href="/property-management/all-properties"
                class="btn btn-outline-primary mb-3"
                ><i class="fas fa-chevron-left mr-2"></i>Back To List</a
            >
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a
                        class="nav-link"
                        :class="formStep == 1 ? 'active' : ''"
                        @click="formStep = 1"
                        href="javascript:void(0)"
                        >Step 1 (Basic Info)</a
                    >
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        :class="formStep == 2 ? 'active' : ''"
                        @click="formStep = 2"
                        href="javascript:void(0)"
                        >Step 2 (Advanced Info)</a
                    >
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        :class="formStep == 3 ? 'active' : ''"
                        @click="formStep = 3"
                        href="javascript:void(0)"
                        >Step 3 (Property Details)</a
                    >
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link"
                        :class="formStep == 4 ? 'active' : ''"
                        @click="formStep = 4"
                        href="javascript:void(0)"
                        >Step 4 (Image & Docs)</a
                    >
                </li>
            </ul>
        </div>

        <div class="tab-content" id="propertyEditTabContent">
            <div
                class="tab-pane fade"
                :class="formStep == 1 ? 'show active' : ''"
                role="tabpanel"
            >
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <form-step-one
                                :property_unique_code="property_unique_code"
                                :project_city_list="project_city_list"
                                :property_details="details"
                                @moveToFormTwo="moveToFormTwo"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                :class="formStep == 2 ? 'show active' : ''"
                role="tabpanel"
            >
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <form-step-two
                                :property_details="details"
                                @moveToFormThree="moveToFormThree"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                :class="formStep == 3 ? 'show active' : ''"
                role="tabpanel"
            >
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <form-step-three
                                :property_details="details"
                                @moveToFormFour="moveToFormFour"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                :class="formStep == 4 ? 'show active' : ''"
                role="tabpanel"
            >
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <form-step-four
                                :property_details="details"
                                @moveToFormThree="moveToFormThree"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="page-container">
        <div class="parent-form" v-if="Form === 1">
            <ul class="tab-list pl-0">
                <li :class="{ active: currentForm === 1 }">
                    <button @click="handleTabChange(1)">
                        Step 1 (Basic Info)
                    </button>
                </li>
                <li class="divider"></li>
                <li :class="{ active: currentForm === 2 }">
                    <button @click="handleTabChange(2)">
                        Step 2 (Property Details)
                    </button>
                </li>
                <li class="divider"></li>
                <li :class="{ active: currentForm === 3 }">
                    <button @click="handleTabChange(3)">
                        Step 3 (Additional Info)
                    </button>
                </li>
                <li class="divider"></li>
                <li :class="{ active: currentForm === 4 }">
                    <button @click="handleTabChange(4)">
                        Step 4 (Advanced Info)
                    </button>
                </li>
                <li class="divider"></li>
                <li :class="{ active: currentForm === 5 }">
                    <button @click="handleTabChange(5)">
                        Step 5 (Image & Docs)
                    </button>
                </li>
            </ul>
            <div class="form-container">
                <step-1 :cityList="city_list" v-if="currentForm == 1" :propertyDetails="details" @skip="handleSkip"
                    :isSaving="isSaving" />
                <step-2 :cityList="city_list" v-if="currentForm == 2" :propertyDetails="details" @skip="handleSkip"
                    :isSaving="isSaving" />
                <step-3 :cityList="city_list" v-if="currentForm == 3" :propertyDetails="details" @skip="handleSkip"
                    :isSaving="isSaving" />
                <step-4 :cityList="city_list" v-if="currentForm == 4" :propertyDetails="details" @skip="handleSkip"
                    :isSaving="isSaving" />
                <step-5 :cityList="city_list" v-if="currentForm == 5" :propertyDetails="details" @skip="handleSkip"
                    :isSaving="isSaving" />
            </div>
        </div>
        <div class="child-form" v-if="Form === 0">
            <!-- :ParentPropertyData="builder_formatted_data" -->
            <div class="form-container">
                <child-form :prevData="details" :ParentPropertyData="builder_formatted_data" :edit="true" />
            </div>
        </div>
    </div>
</template>

<script>
import FormStepOne from "../../components/Admin/Properties/EditProperty/FormStepOne.vue";
import FormStepTwo from "../../components/Admin/Properties/EditProperty/FormStepTwo.vue";
import FormStepThree from "../../components/Admin/Properties/EditProperty/FormStepThree.vue";
import FormStepFour from "../../components/Admin/Properties/EditProperty/FormStepFour.vue";
import step1 from "../../components/Admin/Properties/newEditProperty/step1.vue";
import step2 from "../../components/Admin/Properties/newEditProperty/step2.vue";
import step3 from "../../components/Admin/Properties/newEditProperty/step.3.vue";
import step4 from "../../components/Admin/Properties/newEditProperty/step4.vue";
import step5 from "../../components/Admin/Properties/newEditProperty/step5.vue";
import ChildForm from "../../components/Admin/AddProperty/ChildForm.vue";
export default {
    name: "AdminEditProperty",
    props: [
        "property_details",
        "property_plans",
        "cities",
        "builder_formatted_data",
    ],
    data() {
        return {
            currentForm: 1,
            details: {},
            plans: [],
            city_list: [],
            // property variables
            project_city: {},
            isSaving: false,
            Form: 0,
        };
    },
    mounted() {
        this.details = JSON.parse(this.property_details);
        this.builder_formatted_data = JSON.parse(this.builder_formatted_data);
        this.Form = this.details.is_parent;
        this.plans = JSON.parse(this.property_plans);
        this.city_list = JSON.parse(this.cities);
        if (sessionStorage.getItem("formStep")) {
            this.formStep = sessionStorage.getItem("formStep");
        }
    },
    computed: {
        property_unique_code() {
            if (this.details?.unique_code) {
                return this.details.unique_code;
            } else {
                return "";
            }
        },
        project_city_list() {
            if (this.cities) {
                let tempArr = this.city_list.map((el) => {
                    return { id: el.id, name: el.city };
                });
                return tempArr;
            } else {
                return [];
            }
        },
    },
    components: {
        "form-step-one": FormStepOne,
        "form-step-two": FormStepTwo,
        "form-step-three": FormStepThree,
        "form-step-four": FormStepFour,
        "step-1": step1,
        "step-2": step2,
        "step-3": step3,
        "step-4": step4,
        "step-5": step5,
        "child-form": ChildForm,
    },
    methods: {
        // moveToFormTwo(payload) {
        //     this.formStep = 2;
        // },
        // moveToFormThree(payload) {
        //     this.formStep = 3;
        // },
        // moveToFormFour(payload) {
        //     this.formStep = 4;
        // },
        handleTabChange(step) {
            this.currentForm = step;
        },
        handleSkip() {
            this.currentForm += 1;
        },
        handleSave(data) {
            this.propertyDetails = { ...this.propertyDetails, ...data };
            this.updateProperty();
            return;
            // // add
            // if (this.currentForm === 2 && !this.propertyId) {
            //     this.createProperty();
            // } else {
            //     this.currentForm += 1;
            // }
        },
    },

    updatePropertyFiles(id, images) {
        const formData = new FormData();
        formData.append("images", JSON.stringify(images));
        formData.append("layouts", JSON.stringify([]));
        axios
            .post(`/builders/update-property-files/${id}`, formData)
            .then((res) => {
                window.location.href = "/property-management/all-properties";
            })
            .catch((error) => {
                let modal = this.$refs.errorInfo;
                if (error?.response?.data?.message) {
                    this.errorMessage = error?.response?.data?.message;
                    modal.open();
                    return;
                }
                if (error.message) {
                    this.errorMessage = error.message;
                    modal.open();
                }
            })
            .finally((res) => {
                this.isSaving = false;
            });
    },
};
</script>
<style lang="scss">
.step-label {
    font-size: 14px;
    margin-bottom: 30px;
    color: #000;
}

.spinner-border-sm {
    border-width: 0.2em;
}

.form-container {
    .form-split {
        display: flex;
        justify-content: space-between;
        gap: 60px;
    }

    .form-split>* {
        flex: 1;
        max-width: 460px;
    }

    .form-button-group {
        display: flex;
        align-items: center;
        gap: 30px;
        justify-content: flex-end;

        button,
        a {
            border: none;
            outline: none;
            min-height: 31px;
            min-width: 50px;
            padding: 7px 8px;
            border-radius: 6px;
            background-color: transparent;

            &.save-btn {
                background-color: var(--clr-primary);
                color: #fff;
            }

            &.reset-btn {
                border: 0.5px solid #e8e8e8;
                color: #5f6165;
            }

            &.next-btn {
                color: var(--clr-primary);
                border: 0.5px solid var(--clr-primary);
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .input-group-col-2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;

        input,
        select {
            width: 186px;
        }

        .form-group {
            margin-bottom: 0px;
        }
    }

    .form-check-label {
        color: #5f6165;
        font-size: 14px;
    }

    .form-group {
        margin-bottom: 24px;

        &>label {
            color: #5f6165;
            font-weight: 500;

            span.required {
                color: #f00;
            }
        }

        &.radio-group>label {
            margin-bottom: 18px;
        }
    }
}
</style>
<style scoped lang="scss">
.page-container {
    background-color: #fff;
    margin: 10rem 50px;
    max-width: 1320px;
    margin-bottom: 70px;
}

.tab-list {
    display: flex;
    gap: 8px;
    list-style: none;
    margin-bottom: 50px;
    align-items: center;
    padding: 0;
    margin-top: 10rem;

    button {
        border: none;
        outline: none;
        background-color: transparent;
        color: var(--clr-primary);
        border-radius: 4px;
        padding: 10px;
        width: 175px;
        text-wrap: nowrap;
        font-size: 14px;
    }

    .active button {
        background: #f4f4f4;
        color: #5f6165;
    }

    .divider {
        border-right: 2px solid #d9d9d9;
        padding: 0;
        height: 32px;
    }
}
</style>
