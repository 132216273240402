<template>
    <div>
        <section class="filter-top-sec mb-5">
            <filter-list-header ref="filterComponent" :listType="'exclusive'" :cities="cityArray"
                @onFilterChange="handleFilterChange" :hideMapSearchBtn="true"></filter-list-header>
        </section>
        <div class="container">
            <div class="row">
                <!-- ---------------------- Redbuyers Exclusive Listings ---------------------- -->
                <div class="col-12">
                    <h2 class="list-heading">TeamAlliance Exclusive Listings</h2>
                    <div class="common-property-list-grid my-4">
                        <template v-if="isLoading">
                            <skeleton-loader v-for="item in skeletonList" :key="'idx-property-' + item" />
                        </template>
                        <div v-else-if="all_properties.length == 0" class="text-center">
                            <img src="/images/no_result.gif" alt="No Result"
                                class="img-responsive img-fluid mx-auto d-block">
                        </div>
                        <template v-else>
                            <common-property-card v-for="(value, index) in all_properties"
                                :key="'idx-property-' + index" type="EXCLUSIVE" :property_details="value"
                                :image_base_url="url_web" :user_id="user_id"
                                :userFavProperties="userFavExclusiveProperties"
                                @onFavoriteSuccess="handleExclusiveFavoriteSuccess" />
                        </template>
                    </div>

                    <template v-if="!isLoading">
                        <pagination :data="propertyData" align="center" :show-disabled="true" :limit="10"
                            :page="current_page" @pagination-change-page="getHomes"></pagination>
                    </template>
                </div>

                <!-- ------------------------- Redbuyers MLS Listings ------------------------- -->
                <div class="col-12 mb-5" v-if="exclusiveListingChecked" ref="mlsListingRef">
                    <h2 class="list-heading">TeamAlliance Premium Listings</h2>
                    <div class="common-property-list-grid my-4">
                        <template v-if="isLoadingExclusiveList">
                            <skeleton-loader v-for="item in skeletonList" :key="'idx-property-' + item" />
                        </template>
                        <div v-else-if="exclusiveList.length == 0" class="text-center">
                            <img src="/images/no_result.gif" alt="No Result"
                                class="img-responsive img-fluid mx-auto d-block">
                        </div>
                        <template v-else>
                            <common-property-card v-for="(value, index) in exclusiveList" :key="'idx-property-' + index"
                                :property_details="value" :image_base_url="url_web" :user_id="user_id"
                                :userFavProperties="userFavMlsProperties"
                                @onFavoriteSuccess="handleMlsFavoriteSuccess" />
                        </template>
                    </div>

                    <template v-if="!isLoadingExclusiveList">
                        <pagination :data="exclusiveListData" align="center" :show-disabled="true" :limit="10"
                            :page="currentExclusiveListPage" @pagination-change-page="getHomes"></pagination>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader.vue";
import ExclusivePropertyCard from "../PropertyCard/ExcluisvePropertyCard.vue";
import CommonPropertyCard from "../PropertyCard/CommonPropertyCard/CommonPropertyCard.vue";
import axios from "axios";
import $ from 'jquery'
import { getUserFavPropertiesIds } from "../../utils/app";
import FilterListHeader from "../../components/FilterListHeader/FilterListHeader.vue";

export default {
    name: "ExclusivePropertyResult",
    props: {
        properties: {
            default: '[]'
        },
        cities: {
            default: '[]'
        },
        baseurl: {
            default: ''
        },
        user_id: {
            default: ''
        }
    },
    data() {
        return {
            all_properties: [],
            propertyData: {},
            exclusiveList: [],
            exclusiveListData: {},
            currentExclusiveListPage: 1,
            isLoadingExclusiveList: true,
            current_page: 1,
            page_url: '',
            per_page: 9,
            all_cities: [],
            sliderValue: 300000,
            bedrooms: '',
            min_bedrooms: '',
            max_bedrooms: '',
            bathrooms: '',
            min_bathrooms: '',
            max_bathrooms: '',
            square_footage: '',
            min_price: '',
            max_price: '',
            selectedPriceOption: '',
            property_type: '',
            selected_style: '',
            city: '',
            isLoading: false,
            selected_cities: [],
            homeTypes: ['All', 'Detached', 'Semi-detached', 'Town House', 'Condo Town House', 'Condo Apartment'],
            styles: ['All', '2-Storey', '3-Storey', 'Bungalow', 'Split level', 'Other'],
            bedList: ['0+', '1+', '2+', '3+', '4+', '5+'],
            bathList: ['0+', '1+', '2+', '3+', '4+', '5+'],
            priceList: ['All', '0-500', '500-1M', '1M-1.5M', '1.5M-2M', '2M-3M', '3M+'],
            sortByVal: ['Recommended', 'Most Recent', 'Oldest', 'Most Expensive', 'Least Expensive', 'Most Bedroom'],
            skeletonListInt: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            skeletonList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            sortBy: '',
            sortDir: '',
            sortByFilter: '',
            sale_or_rent_list: ['Buy', 'Rent'],
            sale_or_rent: '',
            last_week: 0,
            search_mls_only: 1,
            type_of_listing: 'Sale',
            exclusive_properties: [],
            url_web: '',
            skeletonList_two: [1, 2, 3, 4],
            exclusiveListingChecked: 1,
            userFavExclusiveProperties: [],
            userFavMlsProperties: []
        };
    },
    components: {
        'skeleton-loader': SkeletonLoader,
        'exclusive-property-card': ExclusivePropertyCard,
        'common-property-card': CommonPropertyCard,
        'filter-list-header': FilterListHeader
    },
    mounted() {
        this.url_web = this.baseurl;
        let properties = JSON.parse(this.properties);
        if (properties['data']) {
            this.all_properties = properties['data'];
            if (properties['meta']['path']) {
                this.page_url = properties['meta']['path'];
            }
            this.propertyData = properties;
        } else {
            this.all_properties = properties;

        }

        let cities = JSON.parse(this.cities);
        if (cities.length > 0) {
            for (let i = 0; i < cities.length; i++) {
                this.all_cities.push(cities[i].city);
            }
        }

        let urlParams = new URLSearchParams(window.location.search); //get all parameters
        let queryCity = urlParams.get('city');
        if (queryCity) {
            if (this.all_cities.includes(queryCity)) {
                this.selected_cities = [queryCity];
            }
            else {
                this.city = queryCity;
            }
        }
        this.getSaleWithRB(1)
        this.fetchUserFavExclusiveProperties()
        this.fetchUserFavMlsProperties()
    },
    methods: {
        fetchUserFavExclusiveProperties() {
            getUserFavPropertiesIds(this.user_id, 'exclusive')
                .then(res => {
                    this.userFavExclusiveProperties = res;
                });
        },
        handleExclusiveFavoriteSuccess(res) {
            if (this.userFavExclusiveProperties.includes(res.id)) {
                this.userFavExclusiveProperties = this.userFavExclusiveProperties.filter(propertyId => propertyId !== res.id)
            }
            else {
                this.userFavExclusiveProperties = [...this.userFavExclusiveProperties, res.id]
            }
        },
        fetchUserFavMlsProperties() {
            getUserFavPropertiesIds(this.user_id, 'idx')
                .then(res => {
                    this.userFavMlsProperties = res;
                });
        },
        handleMlsFavoriteSuccess(res) {
            if (this.userFavMlsProperties.includes(res.id)) {
                this.userFavMlsProperties = this.userFavMlsProperties.filter(propertyId => propertyId !== res.id)
            }
            else {
                this.userFavMlsProperties = [...this.userFavMlsProperties, res.id]
            }
        },
        getHomes(page = 1) {
            this.all_properties = [];
            this.current_page = page;
            const filterData = this.$refs['filterComponent'];
            let apiUrl = '?page=' + page + '&perPage=' + this.per_page;
            if (filterData.sortBy) {
                apiUrl = apiUrl + '&sortBy=' + filterData.sortBy;
            }
            else {
                apiUrl = apiUrl + '&sortBy=list_price';
            }
            if (filterData.sortDir) {
                apiUrl = apiUrl + '&sortDir=' + filterData.sortDir;
            }
            else {
                apiUrl = apiUrl + '&sortDir=desc';
            }
            if (filterData.property_type) {
                apiUrl = apiUrl + '&property_type=' + filterData.property_type;
            }
            if (filterData.property_style) {
                const style = filterData.property_style === 'Split level' ? 'Multi-Level' : filterData.property_style
                apiUrl = apiUrl + '&property_style=' + style;
            }
            if (filterData.bedrooms) {
                apiUrl = apiUrl + '&bedrooms=' + filterData.bedrooms;
            }
            if (filterData.bathrooms) {
                apiUrl = apiUrl + '&bathrooms=' + filterData.bathrooms;
            }
            // if(this.min_bedrooms) {
            //   apiUrl = apiUrl + '&min_bedrooms=' + this.min_bedrooms;
            // }
            // if(this.max_bedrooms) {
            //   apiUrl = apiUrl + '&max_bedrooms=' + this.max_bedrooms;
            // }
            // if(this.min_bathrooms) {
            //   apiUrl = apiUrl + '&min_bathrooms=' + this.min_bathrooms;
            // }
            // if(this.max_bathrooms) {
            //   apiUrl = apiUrl + '&max_bathrooms=' + this.max_bathrooms;
            // }
            if (filterData.square_footage) {
                apiUrl = apiUrl + '&square_footage=' + filterData.square_footage;
            }
            if (this.selected_cities.length) {
                apiUrl = apiUrl + '&cities=' + this.selected_cities.join(',');
            } else if (filterData.city) {
                apiUrl = apiUrl + '&city=' + filterData.city;
            }
            if (filterData.min_price) {
                apiUrl = apiUrl + '&min_price=' + filterData.min_price;
            }
            if (filterData.max_price) {
                apiUrl = apiUrl + '&max_price=' + filterData.max_price;
            }
            if (filterData.sale_or_rent) {
                apiUrl = apiUrl + '&sale_or_lease=' + filterData.sale_or_rent;
            }
            if (this.last_week) {
                apiUrl = apiUrl + '&get_last_week=' + this.last_week;
            }
            this.isLoading = true;
            axios.get(this.url_web + '/exclusive-property-filter' + apiUrl)
                .then(response => {
                    // console.log("This is api 2");
                    this.all_properties = response.data.data;
                    this.propertyData = response.data;
                    this.page_url = response.data['meta']['path'];
                    // console.log(this.all_properties);
                }).catch(err => {
                    // console.error(err);
                }).finally(result => {
                    this.isLoading = false;
                });
            return true;
        },
        handleHomeTypeChange(val) {
            if (val === 'All') {
                this.property_type = '';
            } else {
                //Semi-detached
                if (val == 'Town House') {
                    this.property_type = 'Att/Row/Twnhouse';
                } else if (val == 'Condo Town House') {
                    this.property_type = 'Condo Townhouse';
                } else if (val == 'Condo Apartment') {
                    this.property_type = 'Condo Apt';
                } else if (val == 'Semi-detached') {
                    this.property_type = 'Semi-Detached';
                } else {
                    this.property_type = val;
                }
            }
            this.getHomes();
        },
        handleHomeStyleChange(val) {
            if (val === 'All') {
                this.selected_style = '';
            } else {
                if (val == 'Split level') {
                    this.selected_style = 'Multi-Level';
                } else {
                    this.selected_style = val;
                }
            }
            this.getHomes();
        },
        handleBedChange(val) {
            if (val === '0+') this.bedrooms = '';
            else this.bedrooms = val;
            this.getHomes();
        },
        handleBathChange(val) {
            if (val === '0+') this.bathrooms = '';
            else this.bathrooms = val;
            this.getHomes();
        },
        handlePriceChange(val) {
            if (val === 'All') {
                this.min_price = '';
                this.max_price = '';
                this.selectedPriceOption = '';
            } else {
                const [min, max = ''] = val.split('-')
                // Min
                if (min == 0) {
                    this.min_price = 0;
                } else if (min == 500) {
                    this.min_price = 500000;
                } else if (min == '1M') {
                    this.min_price = 1000000;
                } else if (min == '1.5M') {
                    this.min_price = 1500000;
                } else if (min == '2M') {
                    this.min_price = 2000000;
                } else if (min == '3M') {
                    this.min_price = 3000000;
                } else {
                    this.min_price = '';
                }
                // Max
                if (max == 500) {
                    this.max_price = 500000;
                } else if (max == '1M') {
                    this.max_price = 1000000;
                } else if (max == '1.5M') {
                    this.max_price = 1500000;
                } else if (max == '2M') {
                    this.max_price = 2000000;
                } else if (max == '3M') {
                    this.max_price = 3000000;
                } else {
                    this.max_price = '';
                }
                // this.min_price = min;
                // this.max_price = max;
                this.selectedPriceOption = val;
            }
            this.getHomes();
        },
        isActive(selectedVal, val) {
            if (selectedVal === val) {
                return true
            } else if (val === 'All' && !selectedVal) return true
        },
        handleSortFilter(val) {
            if (val === 'Most Recent') {
                this.sortBy = 'created_at';
                this.sortDir = 'desc';
            }
            else if (val === 'Oldest') {
                this.sortBy = 'created_at';
                this.sortDir = 'asc';
            }
            else if (val === 'Most Expensive') {
                this.sortBy = 'list_price';
                this.sortDir = 'desc';
            }
            else if (val === 'Least Expensive') {
                this.sortBy = 'list_price';
                this.sortDir = 'asc';
            }
            else if (val === 'Most Bedroom') {
                this.sortBy = 'bedrooms';
                this.sortDir = 'desc';
            }
            else {
                this.sortBy = '';
                this.sortDir = '';
            }
            // console.log(val);
            this.sortByFilter = val;
            this.getHomes();
        },
        handleSaleOrRentChange(val) {
            if (val === 'All') {
                this.sale_or_rent = '';
            }
            else if (val == 'Buy') {
                this.sale_or_rent = 'Sale';
                this.type_of_listing = 'Sale';
            }
            else if (val == 'Rent') {
                this.sale_or_rent = 'Lease';
                this.type_of_listing = 'Lease';
            }
            this.getHomes();
        },
        handleLastWeek(event) {
            if (this.last_week == 1) {
                this.last_week = '';
            }
            else {
                this.last_week = 1;
            }
            this.getHomes();
        },
        handleSearchByMls(event) {
            window.location.href = "/idx-listings/search-result";
        },
        handleSearchByExclusiveProperty(event) {
            if (this.search_mls_only == 1) {
                this.search_mls_only = '0';
            }
            else {
                this.search_mls_only = 1;
            }
            this.getHomes();
        },
        isMoreToggleActive(item) {
            const obj = {
                'Buy': 'Sale',
                'Rent': 'Lease'
            }
            return this.sale_or_rent === obj[item]
        },
        getSaleWithRB(page = 1) {
            this.exclusiveList = [];
            this.currentExclusiveListPage = page;
            let queryString = '?sale_with_redbuyers=REDBUYERS%20REALTY,%20BROKERAGE&page=' + page + '&perPage=' + this.per_page;
            this.isLoadingExclusiveList = true;
            axios.get('https://valuation.santhoshmathew.com/api/v1/get-with-realtor' + queryString)
                .then((response) => {
                    console.log(response)
                    this.exclusiveList = response.data.data;
                    this.exclusiveListData = response.data;
                    // this.page_url = response.data['meta']['path'];
                })
                .catch(err => console.log(err))
                .finally(() => {
                    this.isLoadingExclusiveList = false;
                })
        },
        handleExclusiveListing(e) {
            console.log('search by Exclusive Listing', e.target.checked);
            if (e.target.checked) {
                this.getSaleWithRB(1)
                // this.skeletonList = this.skeletonListInt.slice(0,5)
                // this.per_page = 4
                setTimeout(() => {
                    // this.$refs.mlsListingRef.scrollIntoView({behavior: 'smooth', block: 'nearest'})
                    $('html, body').animate({
                        scrollTop: $(this.$refs.mlsListingRef).offset().top - 100
                    }, 500);
                }, 100)
            }
            // this.per_page = 9
            // this.skeletonList = this.skeletonListInt

        },
        handleFilterChange() {
            this.getHomes()
        }
    },
    computed: {
        cityArray() {
            let cities = this?.cities;
            if (typeof this?.cities === 'string') {
                cities = JSON.parse(this.cities)
            }
            return cities?.map(item => item?.city)
        }
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '../IdxResults.scss';

.exclusive-property-result-grid {
    .property_feature_listings_box_item {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
        border-radius: 15px;

        span.sale,
        span.type_point {
            border-radius: 4px;
        }
    }
}

.list-heading {
    font-weight: 500;
    font-size: 32px;
}
</style>
