<template>
    <div>
        <p class="step-label">EDIT PROPERTY - Step 5</p>
        <div class="form-split">
            <!-- Form Column 1 -->
            <div>
                <div class="form-group">
                    <label>Add elevation images</label>
                    <vue-dropzone
                        :duplicateCheck="true"
                        :options="coverImageDropzoneOptions"
                        id="coverImagesDropzone"
                        ref="coverImagesDropzoneRef"
                        :useCustomSlot="true"
                        @vdropzone-error="handleCoverImagesUploadError"
                        @vdropzone-complete="uploadCoverImages"
                        @vdropzone-sending="requestCoverImage"
                        @vdropzone-removed-file="handleRemovedFile"
                    >
                        <div class="dropzone-custom-content">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    opacity="0.5"
                                    d="M17 9.00195C19.175 9.01395 20.353 9.11095 21.121 9.87895C22 10.758 22 12.172 22 15V16C22 18.829 22 20.243 21.121 21.122C20.243 22 18.828 22 16 22H8C5.172 22 3.757 22 2.879 21.122C2 20.242 2 18.829 2 16V15C2 12.172 2 10.758 2.879 9.87895C3.647 9.11095 4.825 9.01395 7 9.00195"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M12 15V2M12 2L15 5.5M12 2L9 5.5"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p class="mt-2">add files</p>
                        </div>
                    </vue-dropzone>
                </div>
            </div>

            <!-- Form Column 2 -->
            <div>
                <!-- <div class="form-group">
                    <label>Add pdf file</label>
                    <vue-dropzone
                        :duplicateCheck="true"
                        :options="dropzoneOptions"
                        id="coverImagesDropzoneRef"
                        ref="secondDropZoneRef"
                        :useCustomSlot="true"
                        @vdropzone-error="handlePhotoUploadError"
                        @vdropzone-complete="updatePropertyImages"
                    >
                        <div class="dropzone-custom-content">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    opacity="0.5"
                                    d="M17 9.00195C19.175 9.01395 20.353 9.11095 21.121 9.87895C22 10.758 22 12.172 22 15V16C22 18.829 22 20.243 21.121 21.122C20.243 22 18.828 22 16 22H8C5.172 22 3.757 22 2.879 21.122C2 20.242 2 18.829 2 16V15C2 12.172 2 10.758 2.879 9.87895C3.647 9.11095 4.825 9.01395 7 9.00195"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                />
                                <path
                                    d="M12 15V2M12 2L15 5.5M12 2L9 5.5"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p class="mt-2">add files</p>
                        </div>
                    </vue-dropzone>
                </div> -->
            </div>
        </div>
        <div class="form-button-group">
            <button class="save-btn" @click="handleSave()">Save</button>
            <button class="reset-btn" @click="handleReset()">Reset</button>
            <button class="next-btn" @click="handleSave()">Done</button>
        </div>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const initialFormFields = {
    short_description: "",
    detailed_description: "",
    propertyImages: [],
};

export default {
    name: "Step5",
    props: {
        propertyDetails: {},
        isSaving: false,
        propertyId: "",
    },
    data() {
        return {
            coverImageDropzoneOptions: {
                url: "/builders/upload-property-cover-image",
                paramName: "file[0]",
                dictRemoveFile: "Remove",
                addRemoveLinks: true,
                autoProcessQueue: false,
            },
            dropzoneOptions: {
                url: "/builders/upload-property-image",
            },
            short_description: "",
            detailed_description: "",
            propertyImages: [],
            editor: ClassicEditor,
            builderTypeOptions: [
                {
                    label: "Sales",
                    value: "Sales",
                },
                {
                    label: "Broker",
                    value: "Broker",
                },
            ],
        };
    },
    created() {
        this.setExistingFormData();
    },
    methods: {
        handleSave() {
            this.$refs.coverImagesDropzoneRef.processQueue();
            const formData = {
                short_description: this.short_description,
                detailed_description: this.detailed_description,
                images: this.propertyImages,
            };
        },
        handleReset() {
            for (let key in initialFormFields) {
                this[key] = initialFormFields[key];
            }
            this.$refs.coverImagesDropzoneRef.removeAllFiles();
        },
        setExistingFormData() {
            for (let key in initialFormFields) {
                this[key] = this.propertyDetails[key] || initialFormFields[key];
            }
        },
        handleCoverImagesUploadError(file, message, xhr) {
            this.photoUploadError = true;
            this.$refs.coverImagesDropzoneRef.removeFile(file);
        },
        handlePhotoUploadError(file, message, xhr) {
            this.photoUploadError = true;
            this.$refs.secondDropZoneRef.removeFile(file);
        },
        requestCoverImage(file, xhr, formData) {
            formData.append("property_id", this.propertyId);
        },
        handleRemovedFile(file, messae, xhr) {
            console.warn("deleated file", file);
        },
    },
};
</script>

<style>
.ck-editor__editable > p {
    height: 100px;
}
</style>
