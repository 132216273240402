<template>
    <div>
        <div
            class="property_feature_listings_box_item h-100"
            v-b-modal="'modal-center-' + u_id"
            :class="isSharable === 'true' ? 'share': ''"
        >
            <!-- @click="goToPropertyDetails(property_details)" -->
            <div class="property_feature_listings_box_item_image">
                <img
                    :src="thumbnail"
                    @error="handleImageError"
                    class="img-fluid"
                    alt=""
                />
                <span class="type_point">{{ property_type }}</span>
                <span class="sale">{{ listing_purpose }}</span>
                <span class="updated_date_or_days">{{ get_updated_date_or_days }}</span>
                <span
                    class="sale"
                    style="
                        margin-top: 110px;
                        font-weight: 600;
                        background: linear-gradient(
                            180deg,
                            #f99b0f 0%,
                            #c64020 100%
                        );
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        border-radius: 14px;
                    "
                    v-if="isExclusiveProperty === true"
                    >{{ sale_property_type }}</span
                >
                <span class="image"
                    ><img src="/images/camera.png" class="img-fluid" />{{
                        total_photos
                    }}</span
                >
                <span class="video"
                    ><a
                        :href="
                            virtual_tour_url
                                ? virtual_tour_url
                                : 'javascript:void(0)'
                        "
                        target="_blank"
                        style="color: white; text-decoration: none"
                        ><img src="/images/play.png" class="img-fluid" />{{
                            virtual_tour_url ? 1 : 0
                        }}</a
                    ></span
                >
                <span class="share-btn btn btn-sm btn-dark" @click="toggleShareProperty" v-if="isSharable === 'true'">
                    <!-- <i class="far fa-share-square"></i>  -->
                    <i class="fas fa-share"></i>
                </span>
            </div>
            <div class="property_feature_listings_box_item_content">
                <!-- <a :href="property_slug" style="text-decoration: none; color: #212529"><span class="location">{{
                        property_address
                }}</span></a> -->
                <a
                    :href="linkToDetailPage"
                    style="text-decoration: none; color: #212529"
                    ><span class="location">{{ property_address }}</span></a
                >
            </div>
            <div class="property_feature_listings_box_item_bottomcontent">
                <span v-if="addtional_bedrooms > 0" class="bedroom"
                    ><img src="/images/double-bed.png" class="img-fluid" />{{
                        bedrooms
                    }}
                    + {{ addtional_bedrooms }} Beds</span
                >
                <span v-else class="bedroom"
                    ><img src="/images/double-bed.png" class="img-fluid" />{{
                        bedrooms
                    }}
                    Beds</span
                >
                <span class="bathroom"
                    ><img src="/images/fbath.png" class="img-fluid" />{{
                        bathrooms
                    }}
                    Baths</span
                >
                <span class="garage"
                    ><img src="/images/garage.png" class="img-fluid" />{{
                        garage
                    }}
                    Garage</span
                >
                <span class="measure"
                    ><img src="/images/measure.png" class="img-fluid" />{{
                        square_footage
                    }}
                    Sq Ft</span
                >
            </div>
            <div class="property_feature_listings_box_item_price">
                <span class="price"
                    >${{ list_price | round_off | numberWithCommas }}</span
                >
                <p class="d-none street_address_schema">{{ street_address }}</p>
                <p class="d-none list_price_schema">
                    ${{ list_price | round_off | numberWithCommas }}
                </p>
                <p class="d-none address_latitude_schema">{{ lat }}</p>
                <p class="d-none address_longitude_schema">{{ lang }}</p>
                <p class="d-none listing_brokerage_schema">{{ realtor }}</p>
                <p class="d-none price_currency_schema">CAD</p>
                <p class="d-none address_list_price_schema">
                    ${{ list_price | round_off | numberWithCommas }}
                </p>
                <p class="d-none address_country_schema">Canada</p>
                <p class="d-none url_schema">{{ canonical }}</p>
                <p class="d-none main_image_schema">{{ main_image }}</p>
                <p class="d-none address_zip_code_schema">
                    {{ zip_code }}
                </p>
                <p class="d-none start_date_schema">{{ start_date }}</p>
            </div>

            <!-- Modal Here Start -->
            <div>
                <b-modal
                    :id="'modal-center-' + u_id"
                    centered
                    title="Property Detail"
                    hide-header
                    hide-footer
                >
                    <span @click="$bvModal.hide('modal-center-' + u_id)">
                        <b-icon
                            icon="x-circle"
                            class="mb-3"
                            scale="2"
                            style="float: right; cursor: pointer"
                            variant="danger"
                        ></b-icon>
                    </span>
                    <div
                        id="carouselExampleIndicators"
                        class="carousel slide mt-3"
                        data-ride="carousel"
                    >
                        <ol class="carousel-indicators">
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="0"
                                class="active"
                            ></li>
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="1"
                            ></li>
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to="2"
                            ></li>
                        </ol>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img
                                    :src="thumbnail"
                                    @error="handleImageError"
                                    class="rounded img-fluid"
                                    alt="image"
                                />
                            </div>
                            <div class="carousel-item">
                                <img
                                    :src="thumbnail_1"
                                    @error="handleImageError"
                                    class="rounded img-fluid"
                                    alt="image"
                                />
                            </div>
                            <div class="carousel-item">
                                <img
                                    :src="thumbnail_2"
                                    @error="handleImageError"
                                    class="rounded img-fluid"
                                    alt="image"
                                />
                            </div>
                        </div>
                        <a
                            class="carousel-control-prev"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="prev"
                        >
                            <span
                                class="carousel-control-prev-icon"
                                aria-hidden="true"
                            ></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a
                            class="carousel-control-next"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="next"
                        >
                            <span
                                class="carousel-control-next-icon"
                                aria-hidden="true"
                            ></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                    <a class="my-4" :href="linkToDetailPage">
                        {{ property_address_community }}
                    </a>
                    <div
                        class="property_feature_listings_box_item_bottomcontent text-center"
                    >
                        <span v-if="addtional_bedrooms > 0" class="bedroom"
                            ><img
                                src="/images/double-bed.png"
                                class="img-fluid"
                            />{{ bedrooms }} +
                            {{ addtional_bedrooms }} Beds</span
                        >
                        <span v-else class="bedroom"
                            ><img
                                src="/images/double-bed.png"
                                class="img-fluid"
                            />{{ bedrooms }} Beds</span
                        >
                        <span class="bathroom"
                            ><img src="/images/fbath.png" class="img-fluid" />{{
                                bathrooms
                            }}
                            Baths</span
                        >
                        <span class="garage"
                            ><img
                                src="/images/garage.png"
                                class="img-fluid"
                            />{{ garage }} Garage</span
                        >
                        <span class="measure"
                            ><img
                                src="/images/measure.png"
                                class="img-fluid"
                            />{{ square_footage }} Sq Ft</span
                        >
                    </div>
                    <div class="property_feature_listings_box_item_price">
                        <span class="price"
                            >${{
                                list_price | round_off | numberWithCommas
                            }}</span
                        >
                        <a
                            class="btn btn-outline-primary ms-auto"
                            style="margin-left: 100px"
                            @click="goToPropertyDetails(property_details)"
                            >View Full Listing</a
                        >
                    </div>
                </b-modal>
            </div>
            <!-- Modal Here end -->
            <!-- <div class="row">
                <p class="d-none main_image_schema">{{ thumbnail }}</p>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "IdxPropertyCard",
    props: {
        property_details: {
            required: true,
        },
        image_base_url: {
            default: "https://teamalliance.ca/",
        },
        selectedShareProperties:{
            default:[]
        },
        isSharable:{
            default: false
        }
    },
    data() {
        return {
            url: "",
            exclusive_property: false,
        };
    },
    mounted() {
        this.url = this.image_base_url;
        // console.log(this.property_details);
    },
    computed: {
        property_slug() {
            return (
                "/sellers/property-details/" +
                this.convertToSlug(
                    this.property_details["address"] +
                        ", " +
                        this.property_details["city"] +
                        ", " +
                        this.property_details["area"] +
                        ", " +
                        this.property_details["zip_code"] +
                        ", " +
                        this.property_details["county"]
                ) +
                "/" +
                this.property_details["unique_id"]
            );
        },
        property_address() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return (
                    this.property_details["street_address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["zip_code"] +
                    ", " +
                    this.property_details["county"]
                );
            } else {
                return (
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["zip_code"] +
                    ", " +
                    this.property_details["county"]
                );
            }
        },
        property_address_community() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return (
                    this.property_details["street_address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["community"]
                );
            } else {
                return (
                    this.property_details["address"] +
                    ", " +
                    this.property_details["city"] +
                    ", " +
                    this.property_details["area"] +
                    ", " +
                    this.property_details["community"]
                );
            }
        },
        bedrooms() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return this.property_details["bedrooms"]
                    ? this.property_details["bedrooms"]
                    : 0;
            } else {
                return this.property_details["bedrooms"]
                    ? this.property_details["bedrooms"] -
                          this.addtional_bedrooms
                    : 0;
            }
        },
        addtional_bedrooms() {
            if (
                this.property_details["additional_bathroom"] == "0" ||
                this.property_details["additional_bathroom"] === 0 ||
                this.property_details["additional_bathroom"] === "undefined" ||
                this.property_details["additional_bathroom"] == "undefined" ||
                this.property_details["additional_bathroom"] === undefined
            ) {
                return this.property_details["additional_bedrooms"]
                    ? this.property_details["additional_bedrooms"]
                    : 0;
            } else {
                return this.property_details["additional_bathroom"];
            }
        },
        bathrooms() {
            return this.property_details["bathrooms"]
                ? this.property_details["bathrooms"]
                : 0;
        },
        garage() {
            if (
                this.property_details["garage_spaces"] === "undefined" ||
                this.property_details["garage_spaces"] == "undefined" ||
                this.property_details["garage_spaces"] === undefined
            ) {
                return this.property_details["garage"]
                    ? this.property_details["garage"]
                    : 0;
            } else {
                return this.property_details["garage_spaces"]
                    ? this.property_details["garage_spaces"]
                    : 0;
            }
        },
        square_footage() {
            return this.property_details["square_footage"]
                ? this.property_details["square_footage"]
                : 0;
        },
        list_price() {
            return this.property_details["list_price"];
        },
        virtual_tour_url() {
            return this.property_details["virtual_tour_url"];
        },
        total_photos() {
            return this.property_details["photos"].length;
        },
        property_type() {
            return this.property_details["property_type"];
        },
        listing_purpose() {
            return this.property_details["sale_or_lease"];
        },
        u_id() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return this.property_details["id"];
            } else {
                return this.property_details["unique_id"];
            }
        },
        thumbnail() {
            if (typeof this.property_details["photos"][0] === "object") {
                return this.property_details["photos"][0]
                    ? this.property_details["photos"][0]["url"]
                    : "/assets/images/no-image-available.png";
            } else {
                return this.property_details["photos"][0]
                    ? this.property_details["photos"][0]
                    : "/assets/images/no-image-available.png";
            }
        },
        thumbnail_1() {
            if (typeof this.property_details["photos"][1] === "object") {
                return this.property_details["photos"][1]
                    ? this.property_details["photos"][1]["url"]
                    : "/assets/images/no-image-available.png";
            } else {
                return this.property_details["photos"][1]
                    ? this.property_details["photos"][1]
                    : "/assets/images/no-image-available.png";
            }
        },
        thumbnail_2() {
            if (typeof this.property_details["photos"][2] === "object") {
                return this.property_details["photos"][2]
                    ? this.property_details["photos"][2]["url"]
                    : "/assets/images/no-image-available.png";
            } else {
                return this.property_details["photos"][2]
                    ? this.property_details["photos"][2]
                    : "/assets/images/no-image-available.png";
            }
        },
        isExclusiveProperty() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return (this.exclusive_property = true);
            }
        },
        street_address() {
            return this.property_details["address"];
        },

        realtor() {
            return this.property_details["realtor"];
        },

        canonical() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return (
                    this.url +
                    "/exclusive-property-detail/" +
                    this.property_details["unique_code"]
                );
            } else {
                return (
                    this.url +
                    "/sellers/property-details/" +
                    this.convertToSlug(
                        this.property_details["address"] +
                            ", " +
                            this.property_details["city"] +
                            ", " +
                            this.property_details["area"] +
                            ", " +
                            this.property_details["zip_code"] +
                            ", " +
                            this.property_details["county"]
                    ) +
                    "/" +
                    this.property_details["unique_id"]
                );
            }
        },
        main_image() {
            return this.property_details["photos"][0] || "";
        },

        zip_code() {
            return this.property_details["zip_code"];
        },

        start_date() {
            return this.property_details["start_date"];
        },

        sale_property_type() {
            if (
                this.property_details["unique_id"] === undefined ||
                this.property_details["unique_id"] == "undefined" ||
                this.property_details["unique_id"] === "undefined"
            ) {
                return this.property_details["property_listing_type"]
                    ? this.property_details["property_listing_type"]
                    : "Exclsuive Property";
            }
        },
        linkToDetailPage() {
            let page_link;
            let value = this.property_details;
            if (
                value["unique_id"] === undefined ||
                value["unique_id"] == "undefined" ||
                value["unique_id"] === "undefined"
            ) {
                page_link =
                    this.url +
                    "/exclusive-property-detail/" +
                    this.property_details["unique_code"];
            } else {
                page_link =
                    "/sellers/property-details/" +
                    this.convertToSlug(
                        value["address"] +
                            ", " +
                            value["city"] +
                            ", " +
                            value["area"] +
                            ", " +
                            value["zip_code"] +
                            ", " +
                            value["county"]
                    ) +
                    "/" +
                    value["unique_id"];
            }
            return page_link;
        },
        get_updated_date_or_days() {
            let todays_day = new Date();
            let last_updated_date = new Date(this.property_details.updated_at);
            if (
                this.getDifferenceInDays(last_updated_date, todays_day) == 1 ||
                this.getDifferenceInDays(last_updated_date, todays_day) == 0
            ) {
                return (
                    "Added " +
                    this.getDifferenceInHours(last_updated_date, todays_day) +
                    " Hours Ago"
                );
            } else {
                return (
                    "Added " +
                    this.getDifferenceInDays(last_updated_date, todays_day) +
                    " Days Ago"
                );
            }
        },
        lat(){
            return this.property_details.latitude || '';
        },
        lang(){
            return this.property_details.longitude || '';
        },
        // isSelectedForShare(){
        //     return this.selectedShareProperties.findIndex()
        // }
    },
    methods: {
        goToPropertyDetails(value) {
            if (
                value["unique_id"] === undefined ||
                value["unique_id"] == "undefined" ||
                value["unique_id"] === "undefined"
            ) {
                window.open(
                    this.url +
                        "/exclusive-property-detail/" +
                        this.property_details["unique_code"],
                    "_self"
                );
            } else {
                window.location.href =
                    "/sellers/property-details/" +
                    this.convertToSlug(
                        value["address"] +
                            ", " +
                            value["city"] +
                            ", " +
                            value["area"] +
                            ", " +
                            value["zip_code"] +
                            ", " +
                            value["county"]
                    ) +
                    "/" +
                    value["unique_id"];
            }
        },
        convertToSlug(val) {
            return val
                .toLowerCase()
                .replace(/[^\w ]+/g, "")
                .replace(/ +/g, "-");
        },
        handleImageError(event) {
            event.preventDefault();
            event.target.src = "/assets/images/no-image-available.png";
        },
        getDifferenceInDays(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return (diffInMs / (1000 * 60 * 60 * 24)) | this.round_off;
        },
        getDifferenceInHours(date1, date2) {
            const diffInMs = Math.abs(date2 - date1);
            return (diffInMs / (1000 * 60 * 60)) | this.round_off;
        },
        toggleShareProperty(e){
            e.stopPropagation()
            this.$emit('share')
        }
    },
    filters: {
        round_off(num) {
            return (Math.round(num * 100) / 100).toFixed();
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
};
</script>
