<template>
  <div>
    <section class="filter-top-sec">
      <filter-list-header ref="filterComponent" :cities="cityArray"  @onFilterChange="handleFilterChange"></filter-list-header>
    </section>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <ol class=" bg-transparent mt-3">
              <li :class="{'d-none': !idxCounty}">
                  <p class="d-none schema_idx_county">{{ idxCounty }}</p>
                  <p class="d-none schema_county_url">{{ countyId }}</p>
              </li>
              <li :class="{'d-none': !selected_cities.length,'active': !!selected_cities.length }">
                  <p class="d-none schema_idx_city" v-if="selected_cities.join().length != 0">{{ selected_cities.join()}}</p>
                  <p class="d-none schema_idx_city" v-else>All Homes</p>
                  <p class="d-none schema_city_search_url">{{ currentUrl }}</p>
              </li>
          </ol>
          <div class="common-property-list-grid mb-4">
            <template v-if="isLoading">
              <skeleton-loader v-for="item in skeletonList" :key="'idx-property-' + item" />
            </template>
            <div v-else-if="all_properties.length == 0" class="text-center">
              <img src="/images/no_result.gif" alt="No Result" class="img-responsive img-fluid mx-auto d-block">
            </div>
            <template v-else>
              <common-property-card 
                v-for="(value, index) in all_properties" 
                :key="'idx-property-' + index"
                :property_details="value" 
                :image_base_url="url_web"
                :canShare="!displayShareButton"
                :isCheckedForShare="isCheckedForShare(value)" 
                @share="handleShare(value)"
                :user_id="user_id"
                :userFavProperties="userFavProperties"
                @onFavoriteSuccess="handleFavoriteSuccess"
              />
            </template>
          </div>

          <template v-if="!isLoading">
            <pagination :data="propertyData" align="center" :show-disabled="true" :limit="10" :page="current_page"
              @pagination-change-page="getHomes"></pagination>
          </template>
        </div>
      </div>
    </div>
    <share-property-modal v-if="loggedInUserEmail" ref="share-property-modal" :properties="selectedShareProperties" :senderEmail="loggedInUserEmail" @onSuccess="handleShareSuccess"></share-property-modal>
    <auth-modal ref="authModal" :initialView="initialAuthView" @loginSuccess="loginSuccess"></auth-modal>
  </div>
</template>

<script>
import SkeletonLoader from "./SkeletonLoader/SkeletonLoader.vue";
import IdxPropertyCard from "./PropertyCard/IdxPropertyCard.vue";
import SharePropertyModal from "../components/SharePropertyModal.vue";
import CommonPropertyCard from "./PropertyCard/CommonPropertyCard/CommonPropertyCard.vue"
import { getUserFavPropertiesIds } from "../utils/app";
import AuthModal, { VIEWS } from "../components/AuthModal/AuthModal.vue";
import FilterListHeader from "../components/FilterListHeader/FilterListHeader.vue";

export default {
  name: "IdxResults",
  props: {
    properties: {
      default: '[]'
    },
    cities: {
      default(){
        return []
      }
    },
    all_exclusive_properties: {
      default: '[]'
    },
    baseurl:{
      default:''
    },
    sender_email:{
      default:''
    },
    is_message_share:{
      default: 'false'
    },
    user_id: {
      default: ""
    }
  },
  data() {
    return {
      all_properties: [],
      propertyData: {},
      current_page: 1,
      page_url: '',
      per_page: 9,
      all_cities: [],
      sliderValue: 300000,
      bedrooms: '',
      min_bedrooms: '',
      max_bedrooms: '',
      bathrooms: '',
      min_bathrooms: '',
      max_bathrooms: '',
      square_footage: '',
      min_price: '',
      max_price: '',
      selectedPriceOption: '',
      property_type: '',
      selected_style: '',
      city: '',
      isLoading: false,
      selected_cities: [],
      skeletonList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      sortBy: 'timestamp_idx',
      sortDir: '',
      sortByFilter: '',
      sale_or_rent_list: ['Buy', 'Rent'],
      sale_or_rent: 'Sale',
      last_week:0,
      search_mls_only:1,
      type_of_listing:'Sale',
      exclusive_properties: [],
      url_web:'',
      skeletonList_two: [1, 2, 3, 4],
      selectedShareProperties: [],
      selectedSharePropertiesIds: [],
      userFavProperties: [],
      displayFavButton: true,
      displayShareEmailCard: false,
      displayShareButton: true,
      initialAuthView: VIEWS.LOGIN_FORM,
      loggedInUserEmail: ""
    };
  },
  components: {
    'skeleton-loader': SkeletonLoader,
    'idx-property-card': IdxPropertyCard,
    'share-property-modal': SharePropertyModal,
    'common-property-card': CommonPropertyCard,
    'auth-modal': AuthModal,
    'filter-list-header': FilterListHeader
  },
  mounted() {
    this.loggedInUserEmail = this.sender_email;
    this.exclusive_properties = JSON.parse(this.all_exclusive_properties);
    this.url_web = this.baseurl;
    let properties = JSON.parse(this.properties);
    if (properties['data']) {
      this.all_properties = properties['data'];
      if (properties['meta']['path']) {
        this.page_url = properties['meta']['path'];
      }
      this.propertyData = properties;
    } else {
      this.all_properties = properties;
    }

    let cities = JSON.parse(this.cities);
    if (cities.length > 0) {
      for (let i = 0; i < cities.length; i++) {
        this.all_cities.push(cities[i].city);
      }
    }

    let urlParams = new URLSearchParams(window.location.search); //get all parameters
    let queryCity = urlParams.get('city');
    // console.log(queryCity);
    if (queryCity) {
      if (this.all_cities.includes(queryCity)) {
        this.selected_cities = [queryCity];
      }
      else {
        this.city = queryCity;
      }
    }
    this.all_properties = this.exclusive_properties.concat(this.all_properties);
    this.fetchUserFavProperties();
  },
  methods: {
    fetchUserFavProperties(){
      getUserFavPropertiesIds(this.user_id, 'idx')
      .then(res=>{
        console.log(res)
        this.userFavProperties = res;
      });
    },
    handleFavoriteSuccess(res){
      if(this.userFavProperties.includes(res.id)){
        this.userFavProperties = this.userFavProperties.filter(propertyId=> propertyId !== res.id)
      }
      else {
        this.userFavProperties = [...this.userFavProperties, res.id]
      }
    },
    getHomes(page = 1) {
      this.all_properties = [];
      this.current_page = page;
      const filterData = this.$refs['filterComponent'];
      let apiUrl = '?page=' + page + '&perPage=' + this.per_page;
      if (filterData.sortBy) {
        apiUrl = apiUrl + '&sortBy=' + filterData.sortBy;
      }
      else {
        apiUrl = apiUrl + '&sortBy=list_price';
      }
      if (filterData.sortDir) {
        apiUrl = apiUrl + '&sortDir=' + filterData.sortDir;
      }
      else {
        apiUrl = apiUrl + '&sortDir=desc';
      }
      if (filterData.property_type) {
        apiUrl = apiUrl + '&property_type=' + filterData.property_type;
      }
      if (filterData.property_style) {
        const style = filterData.property_style === 'Split level' ?  'Multi-Level' : filterData.property_style
        apiUrl = apiUrl + '&property_style=' + style;
      }
      if (filterData.bedrooms) {
        apiUrl = apiUrl + '&bedrooms=' + filterData.bedrooms;
      }
      if (filterData.bathrooms) {
        apiUrl = apiUrl + '&bathrooms=' + filterData.bathrooms;
      }
      // if(this.min_bedrooms) {
      //   apiUrl = apiUrl + '&min_bedrooms=' + this.min_bedrooms;
      // }
      // if(this.max_bedrooms) {
      //   apiUrl = apiUrl + '&max_bedrooms=' + this.max_bedrooms;
      // }
      // if(this.min_bathrooms) {
      //   apiUrl = apiUrl + '&min_bathrooms=' + this.min_bathrooms;
      // }
      // if(this.max_bathrooms) {
      //   apiUrl = apiUrl + '&max_bathrooms=' + this.max_bathrooms;
      // }
      if (filterData.square_footage) {
        apiUrl = apiUrl + '&square_footage=' + filterData.square_footage;
      }
      if (this.selected_cities.length) {
        apiUrl = apiUrl + '&cities=' + this.selected_cities.join(',');
      } else if (filterData.city) {
        apiUrl = apiUrl + '&city=' + filterData.city;
      }
      if (filterData.min_price!=="") {
        apiUrl = apiUrl + '&min_price=' + filterData.min_price;
      }
      if (filterData.max_price) {
        apiUrl = apiUrl + '&max_price=' + filterData.max_price;
      }
      if (filterData.sale_or_rent) {
        apiUrl = apiUrl + '&sale_or_lease=' + filterData.sale_or_rent;
      }
      if (this.last_week) {
        apiUrl = apiUrl + '&get_last_week=' + this.last_week;
      }
      this.isLoading = true;
      // console.log(apiUrl);
      if(page === 1 && this.selected_cities.length) {
        axios.get(this.url_web+'/exclusive-property-filter/'+apiUrl)
        .then(response => {
          // console.log("This is api 1");
          this.all_properties = response.data.data.concat(this.all_properties);
          // console.log(this.all_properties);
        }).catch(err => {
          console.error(err);
        });
      }
      axios.get(this.page_url+apiUrl)
        .then(response => {
          // console.log("This is api 2");
          this.all_properties = this.all_properties.concat(response.data.data);
          this.propertyData = response.data;
          this.page_url = response.data['meta']['path'];
          // console.log(this.all_properties);
        }).catch(err => {
          console.error(err);
        }).finally(result => {
          this.isLoading = false;
      });
      return true;
    },
    handleSearchByExclusiveProperty(event) {
      window.location.href="/exclusive-property/search-result";
    },
    openShareModal(){
      if(!this.loggedInUserEmail){
        // window.location.href="/login";
        this.openAuthModal()
        return;
      }
      this.displayFavButton = false;
      this.displayShareEmailCard = true;
      this.displayShareButton = false;
    },
    sendPropertyEmail(){
      if(this.selectedSharePropertiesIds?.length){
        this.$refs['share-property-modal']?.open()
      }
    },
    convertToSlug(val) {
      return val
          .toLowerCase()
          .replace(/[^\w ]+/g, "")
          .replace(/ +/g, "-");
    },
    goToPropertyLink(value) {
      if (
          value["unique_id"] === undefined ||
          value["unique_id"] == "undefined" ||
          value["unique_id"] === "undefined"
      ) {
          window.location.origin + "/exclusive-property-detail/" +
          value["unique_code"]
      } else {
        return window.location.origin + "/sellers/property-details/" +
        this.convertToSlug(
            value["address"] +
                ", " +
                value["city"] +
                ", " +
                value["area"] +
                ", " +
                value["zip_code"] +
                ", " +
                value["county"]
        ) +
        "/" +
        value["unique_id"];
      }
    },
    bed(property){
      let bed = property?.bedrooms || 0;
      if(!Number(property?.additional_bathroom)){
        bed = bed - Number(property?.additional_bathroom);
      }
      return bed
    },
    getAddress(property){
      let address = property?.address || ""
      if(property?.city){
        address+= ", " + property?.city;
      }
      if(property?.county){
        address+= ", " + property?.county;
      }
      if(property?.zip_code){
        address+= ", " + property?.zip_code;
      }
      return address;
    },
    handleShare(property){
      if(this.isCheckedForShare(property)){
        this.selectedShareProperties = this.selectedShareProperties.filter(item=> (item?.["unique_id"] || item?.['id']) !== (property?.["unique_id"] || property?.['id']));
        this.selectedSharePropertiesIds = this.selectedShareProperties.map(item=> (item?.["unique_id"] || item?.['id']))
      }else {
        let formattedProperty = {
          id: property?.["id"],
          unique_id: property?.["unique_id"],
          link: this.goToPropertyLink(property),
          address: this.getAddress(property),
          beedroom: this.bed(property),
          bathroom: Number(property?.bathrooms) || "0",
          garage: property?.garage_spaces || "0.0",
          price: property?.list_price || "0",
        }
        this.selectedShareProperties.push(formattedProperty);
        this.selectedSharePropertiesIds.push(formattedProperty?.["unique_id"] || formattedProperty?.['id'])
      }
      
    },
    isCheckedForShare(property){
      return this.selectedSharePropertiesIds.includes(property?.['unique_id'] || property?.['id'])
    },
    openAuthModal(){
      this.$refs.authModal.open()
    },
    loginSuccess(user){
      console.log({loginResponse: user})
      this.loggedInUserEmail = user.email;
      if(this.loggedInUserEmail){
        this.openShareModal()
      }
    },
    handleShareSuccess(){
      setTimeout(() => {
        this.$refs['share-property-modal']?.close();
        this.displayFavButton = true;
        this.displayShareEmailCard = false;
        this.displayShareButton = true;
        this.selectedShareProperties = []
        this.selectedSharePropertiesIds = []
      }, 1000);
    },
    handleFilterChange(){
      this.getHomes()
    }
  },
  computed: {
    idxCounty(){
      if(this.all_properties.length){
        const firstCounty = this.all_properties?.[0]?.county;
        const hasSameCountyForAll = this.all_properties.every(prop=>prop?.county === firstCounty);
        if(hasSameCountyForAll) return firstCounty
        return null
      }
      return null
    },
    countyId(){
      return `${this.baseurl}/idx-listings/search-result/on`;
    },
    currentUrl(){
      return window.location.href
    },
    showHeaderShareBtn(){
      return this.selectedSharePropertiesIds.length
    },
    cityArray(){
      let cities = this?.cities;
      if(typeof this?.cities === 'string'){
        cities = JSON.parse(this.cities)
      }
      return cities?.map(item=>item?.city || item?.community) ?? cities?.map(item=>item?.city);
    }
  },

};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<style lang="scss">
@import './IdxResults.scss';
</style>