<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center mb-4 py-2">
        <h3 class="customer-rating__header m-0">Why TeamAlliance?</h3>
      </div>
      <div class="extra-div col-lg-4 col-md-4 col-12">
        <img src="/images/index_two_assets/review_1.png" width="60" height="60" alt="review" />
        <h6 class="h6">Our Reviews Say it All.</h6>
        <h4>100%</h4>
        <h6>Customer Satisfaction</h6>
      </div>

      <div class="extra-div col-lg-4 col-md-4 col-12">
        <img src="/images/index_two_assets/customer-care.png" width="60" height="60" alt="review" />
        <h6 class="h6">Helped</h6>
        <h4>100% of Customer</h4>
        <h6>To Get their Accurate Home Value For free</h6>
      </div>

      <div class="extra-div col-lg-4 col-md-4 col-12">
        <img src="/images/index_two_assets/town.png" width="60" height="60" alt="review" />
        <h6 class="h6">2000+ Properties</h6>
        <h4>Sold</h4>
        <h6>Above Asking Price</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingTwoReviews"
};
</script>

<style scoped>
.extra-div.col-lg-4.col-md-4.col-12:hover {
  cursor: pointer;
}
</style>
