<template>
    <div>
        <div class="form-split">
            <div>
                <div class="form-group" v-if="!edit">
                    <label class="d-block">Link to parent property<span class="required">*</span></label>
                    <select name="project_city" id="project_city" class="form-control"
                        :class="parent_property_err ? 'is-invalid' : ''" v-model="parent_property"
                        @change="updateParentId">
                        <option value="">-- Select Type --</option>
                        <option :value="name" v-for="{ id, name } in parentProperties">
                            {{ name }}
                        </option>
                    </select>
                    <small class="invalid-feedback" role="alert" v-if="parent_property_err">
                        Parent Property is required
                    </small>
                </div>
                <!-- project_name -->
                <div class="form-group">
                    <label class="d-block">Property Name <span class="required">*</span></label>
                    <input class="form-control" v-model="property_name" placeholder="Property Name"
                        :class="project_name_err ? 'is-invalid' : ''" />
                    <small class="invalid-feedback" role="alert" v-if="project_name_err">
                        Property Name is required
                    </small>
                </div>
                <!-- Builder Name -->
                <div class="form-group">
                    <label class="d-block">Builder Name </label>
                    <input type="text" class="form-control" v-model="builder_name" placeholder="Builder Name" />
                </div>
                <!-- company name  -->
                <div class="form-group">
                    <label class="d-block">Company Name </label>
                    <input type="text" class="form-control" v-model="company_name" placeholder="Comapny Name" />
                </div>
                <!-- House Type -->
                <div class="form-group">
                    <label class="d-block">House Type <span class="required">*</span></label>
                    <select name="project_city" id="project_city" class="form-control"
                        :class="home_type_err ? 'is-invalid' : ''" v-model="home_type">
                        <option value="">-- Select Type --</option>
                        <option :value="item" v-for="item in homeTypes">
                            {{ item }}
                        </option>
                    </select>
                    <small class="invalid-feedback" role="alert" v-if="home_type_err">
                        House type is required
                    </small>
                </div>

                <!-- Bathroom -->
                <div class="form-group">
                    <label class="d-block">Bathroom <span class="required">*</span></label>
                    <select v-model="bathroom" id="bathroom" class="form-control"
                        :class="bathroom_err ? 'is-invalid' : ''">
                        <option value="">Select</option>
                        <option :value="item" v-for="item in numOptions">
                            {{ item }}
                        </option>
                    </select>
                    <small class="invalid-feedback" role="alert" v-if="bathroom_err">
                        Bathroom is required
                    </small>
                </div>
                <!-- Bedroom -->
                <div class="form-group">
                    <label class="d-block">Bedroom <span class="required">*</span></label>
                    <select v-model="bedroom" id="bathroom" class="form-control"
                        :class="bedroom_err ? 'is-invalid' : ''">
                        <option value="">Select</option>
                        <option :value="item" v-for="item in numOptions">
                            {{ item }}
                        </option>
                    </select>
                    <small class="invalid-feedback" role="alert" v-if="bedroom_err">
                        Bedroom field is required
                    </small>
                </div>
                <!-- Lot Premium Description -->
                <div class="form-group">
                    <label class="d-block">Lot Premium </label>
                    <textarea class="form-control" rows="4" v-model="description"></textarea>
                </div>
                <!-- Deposite Structure  -->
                <div class="form-group" v-if="isElevation">
                    <label class="d-block">Deposite Structure</label>
                    <textarea class="form-control" rows="4" v-model="deposit_structure"></textarea>
                </div>
                <!-- Elevation premium -->
                <div class="form-group">
                    <label class="d-block">Elevation premium</label>
                    <textarea class="form-control" rows="4" v-model="elevation_premium"></textarea>
                </div>
            </div>
            <div>
                <!-- garage -->
                <div class="form-group">
                    <label class="d-block">Garage <span class="required">*</span></label>
                    <select v-model="garage" id="bathroom" class="form-control" :class="garage_err ? 'is-invalid' : ''">
                        <option value="">Select</option>
                        <option :value="item" v-for="item in numOptions">
                            {{ item }}
                        </option>
                    </select>
                    <small class="invalid-feedback" role="alert" v-if="garage_err">
                        Garage field is required
                    </small>
                </div>
                <!-- Sqft -->
                <div class="form-group">
                    <label> Sqft Area <span class="required">*</span></label>
                    <div class="input-group-col-12">
                        <div>
                            <input type="number" class="form-control" placeholder="sqft" v-model="sqft_area"
                                :class="sqft_area_err ? 'is-invalid' : ''" />
                            <small class="invalid-feedback" role="alert" v-if="sqft_area_err">
                                Sqft is required
                            </small>
                        </div>
                    </div>
                </div>
                <!-- price sqft -->
                <div class="form-group">
                    <label>
                        Price Sqft Area <span class="required">*</span></label>
                    <div class="input-group-col-12">
                        <div>
                            <input type="number" class="form-control" placeholder="Price Sqft Area"
                                v-model="price_per_sqft" :class="price_per_sqft_err ? 'is-invalid' : ''" />
                            <small class="invalid-feedback" role="alert" v-if="price_per_sqft_err">
                                Price per sqft is required
                            </small>
                        </div>
                    </div>
                </div>
                <!-- price -->
                <div class="form-group">
                    <label> Price<span class="required">*</span></label>
                    <div class="input-group-col-12">
                        <div>
                            <input type="number" class="form-control" placeholder="Price" v-model="list_price"
                                :class="price_err ? 'is-invalid' : ''" />
                            <small class="invalid-feedback" role="alert" v-if="price_err">
                                Price is required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="form-group" v-if="isElevation">
                    <label>Lot Size</label>
                    <div class="input-group-col-12">
                        <input class="form-control" placeholder="Lot size" v-model="lot_size" />
                    </div>
                </div>
                <!-- child property file uploader  -->
                <div class="form-group">
                    <label>Floor Plan Images</label>
                    <vue-dropzone :duplicateCheck="true" :options="dropzoneOptions" id="coverImagesDropzoneRef"
                        ref="secondDropZoneRef" :useCustomSlot="true" @vdropzone-error="handlePhotoUploadError"
                        @vdropzone-success="uploadCoverImages" @vdropzone-queue-complete="handleQueueComplete">
                        <div class="dropzone-custom-content">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <path opacity="0.5"
                                    d="M17 9.00195C19.175 9.01395 20.353 9.11095 21.121 9.87895C22 10.758 22 12.172 22 15V16C22 18.829 22 20.243 21.121 21.122C20.243 22 18.828 22 16 22H8C5.172 22 3.757 22 2.879 21.122C2 20.242 2 18.829 2 16V15C2 12.172 2 10.758 2.879 9.87895C3.647 9.11095 4.825 9.01395 7 9.00195"
                                    stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M12 15V2M12 2L15 5.5M12 2L9 5.5" stroke="black" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p class="mt-2">add files</p>
                        </div>
                    </vue-dropzone>
                    <div class="row mt-4">
                        <div class="col-md-4 mb-2" v-for="(item, index) in oldPropertyImages"
                            :key="'property-image-' + index">
                            <img :src="item.thumbnail_path" :alt="getFileNameByPath(item.image_path)"
                                class="img-responsive img-fluid" />
                            <small>{{
                                getFileNameByPath(item.image_path)
                            }}</small>
                            <button class="btn btn-sm btn-outline-danger" @click.prevent="deletePropertyUrl(item)">
                                <i class="far fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                    <p v-if="!isElevation" class="text-danger">You are allowed to upload a maximum of 5 files per
                        session.
                        Each file must not exceed a size of 5MB.</p>
                </div>
                <div class="form-group" v-if="isElevation">
                    <label for="">Elevation Image Uploader</label>
                    <vue-dropzone :duplicateCheck="true" :options="ElevationImages" id="ElevationImageUploader"
                        ref="ElevationImageUploaderRef" :useCustomSlot="true"
                        @vdropzone-sending="addParamToImageUploader" @vdropzone-error="handlePhotoUploadError"
                        @vdropzone-queue-complete="elevationComplete">
                        <div class="dropzone-custom-content">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <path opacity="0.5"
                                    d="M17 9.00195C19.175 9.01395 20.353 9.11095 21.121 9.87895C22 10.758 22 12.172 22 15V16C22 18.829 22 20.243 21.121 21.122C20.243 22 18.828 22 16 22H8C5.172 22 3.757 22 2.879 21.122C2 20.242 2 18.829 2 16V15C2 12.172 2 10.758 2.879 9.87895C3.647 9.11095 4.825 9.01395 7 9.00195"
                                    stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                <path d="M12 15V2M12 2L15 5.5M12 2L9 5.5" stroke="black" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p class="mt-2">add files</p>
                        </div>
                    </vue-dropzone>
                    <div class="row mt-4">
                        <div class="col-md-4 mb-2" v-for="(item, index) in elevationImages"
                            :key="'property-image-' + index">
                            <img :src="item.thumbnail_path" :alt="getFileNameByPath(item.image_path)"
                                class="img-responsive img-fluid" />
                            <small>{{
                                getFileNameByPath(item.image_path)
                            }}</small>
                            <button class="btn btn-sm btn-outline-danger" @click.prevent="deleteEleveationUrl(item)">
                                <i class="far fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                    <p v-if="isElevation" class="text-danger">You are allowed to upload a maximum of 5 files per
                        session.
                        Each file must not exceed a size of 5MB.</p>
                </div>
            </div>
        </div>
        <div class="form-button-group">
            <button class="save-btn save" @click="handleSave('save')" :disabled="isSaving">
                <span v-if="isSaving" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                Save
            </button>
            <button class="reset-btn next" @click="handleReset()" :disabled="isSaving">
                Reset
            </button>
        </div>
        <!-- message box for error and confirmation of data save  -->
        <sweet-modal icon="success" ref="successInfo">{{
            SuccessMessage
        }}</sweet-modal>
        <sweet-modal icon="error" ref="errorInfo">{{
            errorMessage
        }}</sweet-modal>
    </div>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { required } from "vuelidate/lib/validators";
import pick from "lodash/pick";

const initialFormFields = {
    parent_id: null,
    child_or_parent: "Child",
    sqft_area: "",
    garage: "",
    bathroom: "",
    bedroom: "",
    list_price: "",
    price_per_sqft: "",
    description: "",
    home_type: "",
    property_name: "",
    project_city: "",
    builder_address: "",
    lot_size: '',
    deposit_structure: '',
    elevation_premium: '',
    builder_name: "",
    company_name: '',
};
export default {
    name: "ChildForm",
    props: {
        ParentPropertyData: "",
        prevData: {},
        edit: false,
    },
    data() {
        return {
            ...initialFormFields,
            numOptions: [0, 1, 2, 3, 4, 5],
            homeTypes: [
                "Detached",
                "Semi-detached",
                "Town House",
                "Condo Town House",
                "Condo Apartment",
                "Condominium",
                "Condo",
            ],
            propertyImages: [],
            dropzoneOptions: {
                url: "/builders/upload-property-image",
                dictRemoveFile: "Remove",
                addRemoveLinks: true,
                autoProcessQueue: false,
                maxFilesize: 5,
                maxFiles: 5,
                dictFileTooBig: 'Image is too large. Maximum size: 5MB',
                dictMaxFilesExceeded: 'Maximum 5 images allowed',
                acceptedFiles: '.jpg,.jpeg,.png',
            },
            ElevationImages: {
                url: "/builders/upload-property-elevation-image",
                paramName: "file[0]",
                dictRemoveFile: "Remove",
                addRemoveLinks: true,
                autoProcessQueue: false,
                maxFilesize: 5,
                maxFiles: 5,
                dictFileTooBig: 'Image is too large. Maximum size: 5MB',
                dictMaxFilesExceeded: 'Maximum 5 images allowed',
                acceptedFiles: '.jpg,.jpeg,.png,.gif',
            },
            parent_property: "",
            parentProperties: [],
            id: "",
            isSaving: false,
            SuccessMessage: "",
            errorMessage: "",
            property_images: [],
            oldPropertyImages: [],
            iselevationarray: ["Detached", "Semi-detached", "Town House"],
            elevationImages: []
        };
    },
    mounted() {
        if (this.ParentPropertyData) {
            this.parentProperties = JSON.parse(this.ParentPropertyData);
        }
        if (this.prevData) {
            setTimeout(() => {
                this.UpDateData();
            }, 1000);
        }
    },
    validations: {
        home_type: {
            required,
        },
        price_per_sqft: {
            required,
        },
        bedroom: {
            required,
        },
        bathroom: {
            required,
        },
        garage: {
            required,
        },
        sqft_area: {
            required,
        },
        parent_property: {
            required,
        },
        list_price: {
            required,
        },
        property_name: {
            required,
        },
    },
    computed: {
        home_type_err() {
            return !!this.$v.home_type.$error;
        },
        price_per_sqft_err() {
            return !!this.$v.price_per_sqft.$error;
        },
        bedroom_err() {
            return !!this.$v.bedroom.$error;
        },
        bathroom_err() {
            return !!this.$v.bathroom.$error;
        },
        garage_err() {
            return !!this.$v.garage.$error;
        },
        sqft_area_err() {
            return !!this.$v.sqft_area.$error;
        },
        price_per_sqft_err() {
            return !!this.$v.price_per_sqft.$error;
        },
        parent_property_err() {
            return !!this.$v.parent_property.$error;
        },
        price_err() {
            return !!this.$v.list_price.$error;
        },
        project_name_err() {
            return !!this.$v.property_name.$error;
        },
        isElevation() {
            return this.iselevationarray.includes(this.home_type)
        }
    },
    methods: {
        //handle save button
        async handleSave() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                const formData = pick(this, Object.keys(initialFormFields));
                if (!this.edit) {
                    this.$v.$reset();
                    await this.submitChildProperty(formData)
                    this.$refs.ElevationImageUploaderRef.processQueue()

                } else {
                    await this.updateChildProperty(formData);
                    this.$refs.secondDropZoneRef.processQueue();
                    this.$refs.ElevationImageUploaderRef.processQueue()
                }
            }
        },

        //create new child property
        async submitChildProperty(formData) {
            this.isSaving = true;
            try {
                const response = await axios.post("/builders/submit-builder-property", formData);
                this.id = response.data.data?.id;
                if (response.status === 200) {
                    if (!this.iselevationarray.includes(this.home_type) && !this.edit) {
                        this.parent_property = ""
                        this.sqft_area = ""
                        this.garage = ""
                        this.bathroom = ""
                        this.bedroom = ""
                        this.list_price = ""
                        this.price_per_sqft = ""
                        this.description = ""
                        this.home_type = ""
                        this.property_name = ""
                        this.builder_name = ""
                        this.company_name = ""
                    }
                    this.$refs.secondDropZoneRef.processQueue();
                }
            } catch (error) {
                let modal = this.$refs.errorInfo;
                if (error?.response?.data?.message) {
                    this.errorMessage = error?.response?.data?.message;
                    modal.open();
                    return;
                }
                if (error.message) {
                    this.errorMessage = error.message;
                    modal.open();
                }
            } finally {
                this.isSaving = false;
            }
        },

        //update child property
        async updateChildProperty(formData) {
            try {
                this.isSaving = true;
                // await this.updatePropertyFiles(this.id, this.property_images);
                const response = await axios.post("/property-management/update-property-details/" + this.id, formData);
                const { status } = response;
                if (status === 200) {
                    const modal = this.$refs.successInfo;
                    this.SuccessMessage = "Updated";
                    modal.open();
                }
            } catch (error) {
                console.error(error?.response?.data);
            } finally {
                this.isSaving = false;
                // window.location.reload();
            }
        },

        //reset the form
        handleReset() {
            for (let key in initialFormFields) {
                this[key] = initialFormFields[key];
            }
            this.parent_property = "";
            this.city = "";
            this.builder_address = "";
            this.$refs.secondDropZoneRef.removeAllFiles();
            this.$refs.ElevationImageUploaderRef.removeAllFiles();
        },




        //image uploader error handler
        handlePhotoUploadError(file, message, xhr) {
            this.photoUploadError = true;

            this.errorMessage = 'Something went wrong try again letter';
            modal.open();

        },

        //update id according to parent
        updateParentId() {
            const selectedOption = this.parentProperties.find(
                (property) => property.name === this.parent_property
            );
            this.parent_id = selectedOption ? selectedOption.id : null;
            this.project_city = selectedOption ? selectedOption.city : null;
            this.builder_address = selectedOption
                ? selectedOption.address
                : null;
        },

        //set existing data
        UpDateData() {
            for (let key in initialFormFields) {
                this[key] = this.prevData[key] || initialFormFields[key];
            }
            const FindValue = this.parentProperties.find(
                (property) => property.address === this.prevData?.street_address
            );
            this.parent_property = FindValue.name;
            this.id = this.prevData?.id;
            this.project_name = this.prevData?.property_name;
            this.separateImageLayouts();
        },

        //get conver image
        uploadCoverImages(response) {
            if (response?.xhr?.status == 200) {
                if (response?.xhr?.response) {
                    let serverResponse = JSON.parse(response?.xhr?.response);
                    if (serverResponse?.data?.image_path) {
                        try {
                            this.updatePropertyFiles(this.id, [serverResponse.data.image_path]);
                        } catch (error) {
                            let modal = this.$refs.errorInfo;
                            if (error?.response?.data?.message) {
                                this.errorMessage = error?.response?.data?.message;
                                modal.open();
                                return;
                            }
                            if (error.message) {
                                this.errorMessage = error.message;
                                modal.open();
                            }
                        }
                    }
                }
            }
        },
        //update property images
        async updatePropertyFiles(id, images) {
            const data = {
                images: JSON.stringify(images),
                layouts: JSON.stringify([]),
            };

            try {
                const res = await axios.post(`/builders/update-property-files/${id}`, data);
                const { status } = res;
            } catch (error) {
                let modal = this.$refs.errorInfo;
                if (error?.response?.data?.message) {
                    this.errorMessage = error?.response?.data?.message;
                    modal.open();
                    return;
                }
                if (error.message) {
                    this.errorMessage = error.message;
                    modal.open();
                }
            } finally {
                this.isSaving = false;
            }
        },

        handleQueueComplete() {
            this.$refs.secondDropZoneRef.removeAllFiles()
            if (this.edit) {
                window.location.reload();
            }
        },

        elevationComplete() {
            this.$refs.ElevationImageUploaderRef.removeAllFiles()
            if (this.iselevationarray.includes(this.home_type) && !this.edit) {
                this.parent_property = ""
                this.sqft_area = ""
                this.garage = ""
                this.bathroom = ""
                this.bedroom = ""
                this.list_price = ""
                this.price_per_sqft = ""
                this.description = ""
                this.home_type = ""
                this.property_name = ""
                this.builder_name = ""
                this.company_name = ""
            }
            if (this.edit) {
                window.location.reload();
            }
        },
        getFileNameByPath(fullPath) {
            let filename = fullPath.replace(/^.*[\\\/]/, "");
            return filename;
        },

        async deletePropertyUrl(item) {
            try {
                const formData = {
                    image_file_id: item.id,
                };

                const response = await axios.post("/builders/delete-property-file-image", formData);

                this.isSaving = false;

                if (response.status === 200) {
                    this.oldPropertyImages = this.oldPropertyImages.filter((el) => el.id !== item.id);
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.isSaving = false;
            }
        },

        async deleteEleveationUrl(item) {
            try {
                const formData = {
                    property_id: item.id,
                };

                const response = await axios.post("/builders/delete-property-elevation-image", formData);

                this.isSaving = false;

                if (response.status === 200) {
                    this.elevationImages = this.elevationImages.filter((el) => el.id !== item.id);
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.isSaving = false;
            }
        },

        separateImageLayouts() {
            if (this.prevData?.property_images) {
                if (Array.isArray(this.prevData?.property_images)) {
                    let property_images = [...this.prevData.property_images];
                    property_images.forEach((el) => {
                        let thumbnail_path = el.file_url;
                        let image_path = this.extractLastPart(thumbnail_path);
                        this.oldPropertyImages.push({
                            thumbnail_path: thumbnail_path,
                            image_path: image_path,
                            fk_property_id: el.fk_property_id,
                            id: el.id,
                        });
                    });
                }
            }
            if (this.prevData?.property_elevation_image) {
                if (Array.isArray(this.prevData?.property_elevation_image)) {
                    let property_images = [...this.prevData.property_elevation_image];
                    property_images.forEach((el) => {
                        let thumbnail_path = el.file_url;
                        let image_path = this.extractLastPart(thumbnail_path);
                        this.elevationImages.push({
                            thumbnail_path: thumbnail_path,
                            image_path: image_path,
                            fk_property_id: el.fk_property_id,
                            id: el.id,
                        });
                    });
                }
            }
        },

        extractLastPart(inputString) {
            var lastUnderscoreIndex = inputString.lastIndexOf("_");
            var resultString =
                lastUnderscoreIndex !== -1
                    ? inputString.substring(lastUnderscoreIndex + 1)
                    : inputString;
            return resultString;
        },
        addParamToImageUploader(file, xhr, formData) {
            formData.append("property_id", this.id);
        },


    },
};
</script>

<style lang="scss">
.spinner-border-sm {
    border-width: 0.2em;
}

.form-group {
    .save-btn.save {
        background-color: #0061df !important;
        color: #fff !important;
        display: block;
        margin: 0.75rem auto 0 auto;
    }
}

.form-button-group {
    .save-btn.save {
        background-color: #0061df !important;
        color: #fff !important;
    }

    .reset-btn {
        border: 0.5px solid #e8e8e8 !important;
        color: #5f6165 !important;
    }

    .next-btn.next {
        color: var(--clr-primary) !important;
        border: 0.5px solid var(--clr-primary) !important;
        color: #0061df !important;
        border: 0.5px solid #0061df !important;
    }
}
</style>
