<template>
    <div>
        <p class="step-label">EDIT PROPERTY - Step 2</p>
        <div class="form-split">
            <!-- Form Column 1 -->
            <div>
                <!-- Construction status -->
                <div class="form-group">
                    <label class="d-block">Construction status
                        <span class="required">*</span></label>
                    <input type="text" class="form-control" v-model="construction_status"
                        :class="construction_status_err ? 'is-invalid' : ''" />
                    <small class="invalid-feedback" role="alert" v-if="construction_status_err">
                        Construction status is required
                    </small>
                </div>

                <!-- Price Range -->
                <!-- <div class="form-group">
                    <label>Price<span class="required">*</span></label>
                    <div class="input-group-col-12">
                        <div>
                            <input
                                type="number"
                                class="form-control"
                                placeholder="Price"
                                v-model="min_price"
                                :class="price_err ? 'is-invalid' : ''"
                            />
                            <small
                                class="invalid-feedback"
                                role="alert"
                                v-if="price_err"
                            >
                                price is required
                            </small>
                        </div>
                        <div>  <span>-</span>
                      
                            <input type="number" class="form-control" placeholder="No Max" v-model="max_price"
                                :class="max_price_err ? 'is-invalid' : ''">
                            <small class="invalid-feedback" role="alert" v-if="max_price_err">
                                Max price is required
                            </small>
                        </div>
                    </div>
                </div> -->

                <!-- Sqft Range -->
                <!-- <div class="form-group">
                    <label> Sqft Area <span class="required">*</span></label>
                    <div class="input-group-col-12">
                        <div>
                            <input
                                type="number"
                                class="form-control"
                                placeholder="sqft"
                                v-model="sqft_area"
                                :class="sqft_err ? 'is-invalid' : ''"
                            />
                            <small
                                class="invalid-feedback"
                                role="alert"
                                v-if="sqft_err"
                            >
                                sqft is required
                            </small>
                        </div>
                        <span>-</span>
                        <div>
                            <input
                                type="number"
                                class="form-control"
                                placeholder="No Max"
                                v-model="max_sqft"
                                :class="max_sqft_err ? 'is-invalid' : ''"
                            />
                            <small
                                class="invalid-feedback"
                                role="alert"
                                v-if="max_sqft_err"
                            >
                                Max sqft is required
                            </small>
                        </div>
                    </div>
                </div> -->
                <!-- Building Type -->
                <!-- <div class="form-group">
                    <label for="building_type"
                        >Building Type <span class="required">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        id="building_type"
                        placeholder="Enter Building Type"
                        v-model="building_type"
                        :class="building_type_err ? 'is-invalid' : ''"
                    />
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="building_type_err"
                    >
                        Building type is required
                    </small>
                </div> -->

                <!-- Completion Year -->
                <div class="form-group">
                    <label class="d-block" for="completion_year">Completion Year <span class="required">*</span></label>
                    <input type="text" id="completion_year" class="form-control" v-model="completion_year"
                        :class="completion_year_err ? 'is-invalid' : ''" />
                    <small class="invalid-feedback" role="alert" v-if="completion_year_err">
                        Completion year is required
                    </small>
                </div>

                <!-- Description -->
                <div class="form-group">
                    <label class="d-block">Description <span class="required">*</span></label>
                    <textarea class="form-control" rows="4" v-model="description"
                        :class="description_err ? 'is-invalid' : ''"></textarea>
                    <small class="invalid-feedback" role="alert" v-if="description_err">
                        Description is required
                    </small>
                </div>
            </div>

            <!-- Form Column 2 -->
            <div>
                <!-- Deposit Structure * -->
                <div class="form-group">
                    <label class="d-block">Deposit Structure
                        <span class="required">*</span>
                        <p class="fs-6 font-weight-light">After the 10th line, press the Enter key twice to insert a blank
                            line.</p>
                    </label>
                    <textarea rows="4" class="form-control" v-model="detailed_description"
                        :class="deposit_structure_err ? 'is-invalid' : ''" />
                    <small class="invalid-feedback" role="alert" v-if="deposit_structure_err">
                        Deposit structure is required
                    </small>
                </div>

                <!-- Amenities -->
                <div class="form-group">
                    <label class="d-block" for="city">Amenities <span class="required">*</span></label>
                    <multiselect v-model="amnities" :options="amenitiesOptions" :multiple="true"
                        :class="deposit_structure_err ? 'is-invalid' : ''"></multiselect>
                    <small class="invalid-feedback" role="alert" v-if="amnities_err">
                        Amenities is required
                    </small>
                </div>

                <!-- Price per SqFt -->
                <!-- <div class="form-group">
                    <label class="d-block" for="city"
                        >Price per SqFt <span class="required">*</span></label
                    >
                    <input
                        class="form-control"
                        type="text"
                        v-model="price_per_sqft"
                        :class="price_per_sqft_err ? 'is-invalid' : ''"
                        placeholder="Price/sqft"
                    />
                    <small
                        class="invalid-feedback"
                        role="alert"
                        v-if="price_per_sqft_err"
                    >
                        This is required
                    </small>
                </div> -->

                <!-- <div class="form-group">
                    <div class="input-group-col-2">
                        <div class="form-group">
                            <label class="d-block"
                                >Bedroom <span class="required">*</span></label
                            >
                            <select
                                v-model="bedroom"
                                id="bedroom"
                                class="form-control"
                                :class="bedroom_err ? 'is-invalid' : ''"
                            >
                                <option value="">Select</option>
                                <option
                                    :value="item"
                                    v-for="item in numOptions"
                                >
                                    {{ item }}
                                </option>
                            </select>
                            <small
                                class="invalid-feedback"
                                role="alert"
                                v-if="bedroom_err"
                            >
                                Bedroom is required
                            </small>
                        </div>

                        <div class="form-group">
                            <label class="d-block"
                                >Bathroom <span class="required">*</span></label
                            >
                            <select
                                v-model="bathroom"
                                id="bathroom"
                                class="form-control"
                                :class="bathroom_err ? 'is-invalid' : ''"
                            >
                                <option value="">Select</option>
                                <option
                                    :value="item"
                                    v-for="item in numOptions"
                                >
                                    {{ item }}
                                </option>
                            </select>
                            <small
                                class="invalid-feedback"
                                role="alert"
                                v-if="bathroom_err"
                            >
                                Bathroom is required
                            </small>
                        </div>
                    </div>
                </div> -->

                <div class="form-group">
                    <div class="input-group-col-2">
                        <div class="form-group">
                            <label class="d-block">Parking space available
                                <span class="required">*</span></label>
                            <select v-model="have_parking" id="parking_space" class="form-control"
                                :class="have_parking_err ? 'is-invalid' : ''">
                                <option value="">Select</option>
                                <option :value="item.value" v-for="item in booleanOptions">
                                    {{ item.label }}
                                </option>
                            </select>
                            <small class="invalid-feedback" role="alert" v-if="have_parking_err">
                                Parking space is required
                            </small>
                        </div>

                        <div class="form-group">
                            <label class="d-block">Garage Space available
                                <span class="required">*</span></label>
                            <select v-model="garage" id="garage" class="form-control"
                                :class="garage_err ? 'is-invalid' : ''">
                                <option value="">Select</option>
                                <option :value="item.value" v-for="item in booleanOptions">
                                    {{ item.label }}
                                </option>
                            </select>
                            <small class="invalid-feedback" role="alert" v-if="garage_err">
                                Garage size is required
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-button-group">
            <button class="save-btn" @click="handleSave('save')" :disabled="isSaving">
                <span v-if="isSaving && currentActionBtn === 'save'" class="spinner-grow spinner-grow-sm" role="status"
                    aria-hidden="true"></span>
                Save
            </button>
            <button class="reset-btn" @click="handleReset()" :disabled="isSaving">
                Reset
            </button>
            <button class="next-btn" @click="handleSave('next')" :disabled="isSaving">
                <span v-if="isSaving && currentActionBtn === 'next'" class="spinner-grow spinner-grow-sm" role="status"
                    aria-hidden="true"></span>
                Next
            </button>
        </div>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import pick from "lodash/pick";

const initialFormFields = {
    construction_status: "",
    min_price: "",
    // max_price: "",
    building_type: "",
    completion_year: "",
    description: "",
    detailed_description: "",
    amnities: "",
    price_per_sqft: "",
    bedroom: "",
    bathroom: "",
    have_parking: "",
    garage: "",
    sqft_area: "",
};
export default {
    name: "Step2",
    props: {
        propertyDetails: {},
        isSaving: false,
    },
    data() {
        return {
            ...initialFormFields,
            typeOfSaleOptions: [
                {
                    label: "Pre-construction",
                    value: "Pre-construction",
                },
                {
                    label: "Inventory",
                    value: "Inventory",
                },
                {
                    label: "Assignment Sale",
                    value: "Assignment Sale",
                },
            ],
            amenitiesOptions: [
                "Rooftop Terrace",
                "Lounge with Wet Bar",
                "Dog Wash Station",
                "24/7 Concierge",
                "Bike Storage",
                "Catering Kitchen",
                "Fitness Centre",
                "Guest Suite",
                "Outdoor Reading Rooms",
                "Entertainment Lounge",
                "Conference Room",
                "Garden Suite",
                "Lobby Lounge",
                "Sun bathing areas",
                "Private Elevators",
                "Party Room",
                "Fitness Room",
                "Garden Terrace",
                "Lobby Bar",
                "Valet Parking",
                "Infinity Pool",
                "Outdoor Pool",
                "Sun Deck",
                "Parcel and Mail Room",
                "Outdoor Terrace with Fire pit",
                "Cabanas",
                "Co-working Lounge",
                "Sauna",
                "Outdoor BBQ",
                "Concierge",
                "Entertainment Space",
                "Al Fresco Dining and Grilling Areas",
                "Green Roof",
                "Private Garden Patio",
                "Porte Cochere",
                "Rooftop Lounge",
                "Stretch Room",
                "Landscaped Courtyard",
                "Patio Lounge",
                "Outdoor Dining",
                "Package Room",
                "BBQ Station",
                "Catering Kitchen and Dining",
                "Fitness Studio",
                "Lobby",
                "24 Hour Virtual Concierge",
                "Parcel Room",
                "Outdoor Terrace",
                "Storage Room",
                "Ping Pong Table",
                "Pilates Studio",
                "Chef’s Table and Wine Tasting Room",
                "BBQ Terrace",
                "Foosball Table",
                "Zen Space",
                "WiFi Lounge",
                "Theatre Room",
                "Karaoke Area",
                "Outdoor Garden",
                "Lounge",
                "Pool",
                "Discovery Den",
                "Mail Room",
                "Kids Zone",
                "Lounge Area",
                "Pet Wash",
                "Potting Shed",
                "Playground",
                "Private Dining Room",
                "Social Lounge",
                "Dining Bar",
                "Social Lounge",
                "Pet Spa",
                "Outdoor Movie Theatre",
                "Fire Pit",
                "Yoga and Stretching Space",
                "Media Lounge",
                "Indoor Bike Storage",
                "EV Charging",
                "Locker Rooms",
                "Smart Home Technology",
                "Smart Home Technology",
                "Concierge Attended Lobby",
                "Grand Lobby Lounge",
                "Fire Pit",
                "Party Rooms",
                "Yoga Studio",
                "outdoor terrace with BBQ",
                "Bike Parking",
                "Pet Grooming Room",
                "Video Games Room",
                "Relaxation Lounge",
                "Virtual Bike Room",
                "Yoga Room",
                "Billiards Room",
                "Co-Work Lounge",
                "Meeting Room",
                "heated stone lounger",
                "Meeting Room",
                "Outdoor Relaxation Area",
                "Swimming Pool",
                "Library",
                "Bike Gear Room",
                "Bowling Alley",
                "Bike Elevator",
                "Hot Tub",
                "Bike Lockers",
                "Steam Room",
                "Bike Wash Station",
                "Games Room",
                "spin class studio",
                "Conference Rooms",
                "KTV room",
                "parcel locker rooms",
                "Music Room",
                "Social Room",
                "Guest Suites",
                "Virtual Golf Simulator",
                "Outdoor Lounge",
                "Garden Cafe",
                "Yoga Studio",
                "Fireplace Terrace",
                "Party Lounge",
                "Gaming Hub",
                "Sound Studio",
                "Gym",
                "Spin Room",
                "gym lounge",
                "Weight Room",
                "Whirlpool",
                "Security System",
                "Underground Private Parking Garage",
                "Decks",
                "Patio Spaces",
                "In Suite Elevator",
                "Heated Laneway",
                "Boardroom",
                "Private Terrace",
                "Terrace",
                "Dining Room",
                "Indoor Pool",
                "Bar",
                "Private BBQ/Dining Area",
                "Outdoor Yoga and Zen Garden",
                "Art Studio",
                "Library Lounge",
                "Outdoor Theatre",
                "Demo Kitchen",
                "Juice & Coffee Bar",
                "Outdoor firepit lounge",
                "Shared Work Space",
                "Steam & Sauna Room",
                "24 Hour Concierge Service",
                "Spa Lounge",
                "Social Club",
                "Outdoor Games Lounge",
                "BBQ Area",
                "Parcel Storage",
                "Personal Training Studio",
                "Private Terraces and Balconies",
                "Parking Stackers",
                "Bike Racks",
                "Fitness Equipment",
                "BBQ Area",
                "Chet's Kitchen",
                "Outdoor Patio",
                "Rooftop Patio",
                "Party Room with Kitchen and Dining Room",
                "Bicycle Storage",
                "24 Hour Concierge",
                "Outdoor Terrace with BBQ and Pergola",
                "Lounge with seating and wetbar",
                "Parcel Storage Room",
                "Secure Key Fob",
                "Guest Parking",
                "Balconies and/or Terraces",
                "Guest Parking",
                "Balconies and/or Terraces",
                "outdoor bbq and seating",
                "reception parlour",
                "Covered Terrace",
                "Valet Service",
                "Private Function Room",
                "24 Hour Concierge Service",
                "Fireplace",
                "Courtyard",
                "Pet Grooming Station",
                "Seating Area",
                "Kitchen",
                "Car Share",
                "Electric Charging Station Available",
                "Games Deck",
                "The Speakeasy Room",
                "Yoga Area",
                "Recharge Retreat",
                "Parking",
                "Plush Lounge",
                "Entertainment Room",
                "Green Space",
                "Coworking Space",
                "Courtyard",
                "Lockers Available",
                "Common Room",
                "Walkways",
                "Rooftop Terraces",
                "Garden Patio",
                "Parking Available",
                "EV Charging",
                "Rooftop Terrace",
            ],
            numOptions: [1, 2, 3, 4, 5],
            booleanOptions: [
                {
                    label: "Yes",
                    value: 1,
                },
                {
                    label: "No",
                    value: 0,
                },
            ],
            currentActionBtn: null,
        };
    },
    created() {
        this.setExistingFormData();
    },
    computed: {
        construction_status_err() {
            return !!this.$v.construction_status.$error;
        },
        price_err() {
            return !!this.$v.min_price.$error;
        },
        // max_price_err() {
        //     return !!this.$v.max_price.$error;
        // },
        sqft_err() {
            return !!this.$v.sqft_area.$error;
        },
        // max_sqft_err() {
        //     return !!this.$v.max_sqft.$error;
        // },
        completion_year_err() {
            return !!this.$v.completion_year.$error;
        },
        description_err() {
            return !!this.$v.detailed_description.$error;
        },
        building_type_err() {
            return !!this.$v.building_type.$error;
        },
        deposit_structure_err() {
            return !!this.$v.detailed_description.$error;
        },
        amnities_err() {
            return !!this.$v.amnities.$error;
        },
        price_per_sqft_err() {
            return !!this.$v.price_per_sqft.$error;
        },
        bedroom_err() {
            return !!this.$v.bedroom.$error;
        },
        bathroom_err() {
            return !!this.$v.bathroom.$error;
        },
        have_parking_err() {
            return !!this.$v.have_parking.$error;
        },
        garage_err() {
            return !!this.$v.garage.$error;
        },
    },
    validations: {
        construction_status: {
            required,
        },
        // min_price: {
        //     required,
        // },
        // max_price: {
        //     required,
        // },
        // sqft_area: {
        //     required,
        // },
        // max_sqft: {
        //     required,
        // },
        // building_type: {
        //     required,
        // },
        completion_year: {
            required,
        },
        description: {
            required,
        },
        detailed_description: {
            required,
        },
        amnities: {
            required,
        },
        // price_per_sqft: {
        //     required,
        // },
        // bedroom: {
        //     required,
        // },
        // bathroom: {
        //     required,
        // },
        have_parking: {
            required,
        },
        garage: {
            required,
        },
    },
    methods: {
        handleSave(actionType) {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.currentActionBtn = actionType;
                this.$v.$reset();
                const formData = pick(this, Object.keys(initialFormFields));
                this.$emit("save", formData);
            }
        },
        handleReset() {
            for (let key in initialFormFields) {
                this[key] = initialFormFields[key];
            }
        },
        setExistingFormData() {
            for (let key in initialFormFields) {
                // if (key === "sqft_area" && this.propertyDetails[key]) {
                //     const [min_sqft, max_sqft] =
                //         this.propertyDetails[key]?.split?.("-");
                //     this.min_sqft = min_sqft;
                //     this.max_sqft = max_sqft;
                //     continue;
                // }
                this[key] = this.propertyDetails[key] || initialFormFields[key];
            }
        },
        // updateSqftArea() {
        //     this.sqft_area = `${this.min_sqft}-${this.max_sqft}`;
        // },
    },
    // watch: {
    //     min_sqft() {
    //         this.updateSqftArea();
    //     },
    //     max_sqft() {
    //         this.updateSqftArea();
    //     },
    // },
};
</script>

<style></style>
