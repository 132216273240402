<template>
    <div>
        <section class="filter-top-sec">
            <filter-list-header ref="filterComponent" :listType="'new_construction'" :cities="cityArray"
                @onFilterChange="handleFilterChange" :showBuildingStatus="true" :hideExclusiveBtn="true"
                :hideMapSearchBtn="true" :hideBuySellOptions="true"></filter-list-header>
        </section>

        <section class="thumbnail">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 px-2 order-2 order-xl-1 order-lg-1 order-md-1">
                        <div class="col-md-12 p-0">
                            <div class="thumbnail_title">
                                <!-- <h1>Real Estate, Homes for Sale, Apartments & Houses for Rent</h1> -->
                                <h1>
                                    New Homes For Sale <template v-if="searchstring">in {{ searchstring }}</template>
                                </h1>
                                <p>
                                    {{ total_properties }} homes available on
                                    TeamAlliance
                                </p>
                            </div>
                        </div>
                        <div class="thumbnail_slider_arrea">
                            <div class="col-md-12">
                                <div>
                                    <div class="search-result-grid" v-if="isLoading">
                                        <skeleton-loader v-for="(item, index) of skeletonList"
                                            :key="index"></skeleton-loader>
                                    </div>
                                    <template v-else>
                                        <template v-if="total_properties == 0">
                                            <div class="col-6 col-md-3 text-center mx-auto">
                                                <img src="/assets/images/no_result.gif"
                                                    class="img-fluid img-responsive w-100" alt="No result found" />
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div v-if="propertyData.data" class="common-property-list-grid">
                                                <!-- Property Card -->
                                                <common-property-card v-for="(
                                                        item, index
                                                    ) in filteredData" :key="'property-item-' + index
                                                        " :property_details="item" type="NEW_CONSTRUCTION"
                                                    :user_id="user_id" :userFavProperties="userFavProperties"
                                                    @onFavoriteSuccess="handleFavoriteSuccess"
                                                    :canShare="!displayShareButton"
                                                    :isCheckedForShare="isCheckedForShare(item)"
                                                    @share="handleShare(item)"></common-property-card>
                                                <!-- ./Property Card -->
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <pagination v-if="!isLoading" class="mt-4" :data="propertyData" :align="'center'"
                            :show-disabled="true" :limit="12" @pagination-change-page="getHomes">
                        </pagination>
                    </div>
                </div>
            </div>
        </section>
        <share-property-modal v-if="loggedInUserEmail" ref="share-property-modal" :properties="selectedShareProperties"
            :senderEmail="loggedInUserEmail" @onSuccess="handleShareSuccess"></share-property-modal>
        <auth-modal ref="authModal" :initialView="initialAuthView" @loginSuccess="loginSuccess"></auth-modal>
    </div>
</template>

<script>
import SearchConstructionFilter from "./SearchConstructionFilter.vue";
import SearchConstructionPropertyCard from "./SearchConstructionPropertyCard.vue";
import SkeletonLoader from "../../../pages/SkeletonLoader/SkeletonLoader.vue";
import CommonPropertyCard from "../../../pages/PropertyCard/CommonPropertyCard/CommonPropertyCard.vue";
import { getUserFavPropertiesIds } from "../../../utils/app";
import SharePropertyModal from "../../SharePropertyModal.vue";
import AuthModal, { VIEWS } from "../../AuthModal/AuthModal.vue";
import FilterListHeader from "../../FilterListHeader/FilterListHeader.vue";

export default {
    name: "SearchNewConstructionContainer",
    props: {
        searchstring: {
            default: "",
        },
        user_id: "",
        sender_email: ""
    },
    data() {
        return {
            cities: [],
            sub_city: [],
            propertyData: {},
            isLoading: true,
            currentPage: 1,
            bedroom: "All",
            buildingType: "All",
            city: "All",
            homeType: "All",
            subCity: "All",
            skeletonList: new Array(8),
            filterData: {
                home_type: "All",
                property_type: "All",
                bedroom: "All",
                bathroom: "All",
                min_price: "All",
                max_price: "All",
                sortBy: "created_at",
                sortDir: "desc",
                // building_type: "All", // Not Required For Now
                search: "",
            },
            userFavProperties: [],
            selectedShareProperties: [],
            selectedSharePropertiesIds: [],
            displayFavButton: true,
            displayShareEmailCard: false,
            displayShareButton: true,
            initialAuthView: VIEWS.LOGIN_FORM,
            loggedInUserEmail: ""
        };
    },
    components: {
        "search-construction-filter": SearchConstructionFilter,
        "search-construction-property-card": SearchConstructionPropertyCard,
        "skeleton-loader": SkeletonLoader,
        'share-property-modal': SharePropertyModal,
        'common-property-card': CommonPropertyCard,
        'auth-modal': AuthModal,
        'filter-list-header': FilterListHeader
    },
    mounted() {
        this.loggedInUserEmail = this.sender_email;
        this.filterData.search = this.searchstring;
        this.getHomes();
        this.fetchUserFavProperties()
    },
    methods: {
        filterPropertyData() {
            return this.propertyData.data.filter((item) => !item.is_parent)
        },

        fetchUserFavProperties() {
            getUserFavPropertiesIds(this.user_id, 'new-construction')
                .then(res => {
                    this.userFavProperties = res;
                });
        },
        handleFavoriteSuccess(res) {
            if (this.userFavProperties.includes(res.id)) {
                this.userFavProperties = this.userFavProperties.filter(propertyId => propertyId !== res.id)
            }
            else {
                this.userFavProperties = [...this.userFavProperties, res.id]
            }
        },
        getHomes(page = 1) {
            this.currentPage = page;
            this.propertyData = {};
            this.isLoading = true;
            const filterData = this.$refs['filterComponent'];
            const formData = new FormData();

            formData.append("home_type", filterData?.property_type || "All");
            formData.append("property_type", filterData?.building_status || "All");
            formData.append("bedroom", filterData?.bedrooms || "All");
            formData.append("bathroom", filterData?.bathrooms || "All");
            formData.append("min_price", filterData?.min_price || "All");
            formData.append("max_price", filterData?.max_price || "All");
            formData.append("sortBy", filterData?.sortBy);
            formData.append("sortDir", filterData?.sortDir);
            formData.append("search", filterData?.city || "");

            axios
                .get("https://www.redbuyers.com/api/new-construction?page=" + page, formData)
                .then((response) => {
                    this.cities = response?.data?.data?.cities;
                    this.sub_city = response?.data?.data?.sub_city;
                    this.propertyData = response?.data?.data?.property;
                })
                .catch((error) => {
                    console.error(error.response.data);
                })
                .finally((res) => {
                    this.isLoading = false;
                });
        },
        getProperties(payload) {
            this.bedroom = payload?.bedroom ? payload.bedroom : "All";
            this.buildingType = payload?.buildingType
                ? payload.buildingType
                : "All";
            this.city = payload?.city ? payload.city : "All";
            this.homeType = payload?.homeType ? payload.homeType : "All";
            this.subCity = payload?.subCity ? payload.subCity : "All";
            this.getHomes();
        },
        handleFilterChange(data) {
            // console.log("filter data", data);
            // this.filterData = { ...this.filterData, ...data };
            this.getHomes();
        },
        openShareModal() {
            if (!this.loggedInUserEmail) {
                // window.location.href="/login";
                this.openAuthModal()
                return;
            }
            this.displayFavButton = false;
            this.displayShareEmailCard = true;
            this.displayShareButton = false;
        },
        sendPropertyEmail() {
            if (this.selectedSharePropertiesIds?.length) {
                this.$refs['share-property-modal']?.open()
            }
        },
        handleShare(property) {
            if (this.isCheckedForShare(property)) {
                this.selectedShareProperties = this.selectedShareProperties.filter(item => (item?.["unique_id"] || item?.['id']) !== (property?.["unique_id"] || property?.['id']));
                this.selectedSharePropertiesIds = this.selectedShareProperties.map(item => (item?.["unique_id"] || item?.['id']))
            } else {
                let formattedProperty = {
                    id: property?.["id"],
                    unique_id: property?.["unique_id"],
                    link: property["plan_details_url"],
                    address: this.property_details?.street_address || "No address available",
                    beedroom: property.bedroom || "0",
                    bathroom: Number(property?.bathrooms) || "0",
                    garage: property?.garage_spaces || "0.0",
                    price: property?.list_price || "0",
                }
                this.selectedShareProperties.push(formattedProperty);
                this.selectedSharePropertiesIds.push(formattedProperty?.["unique_id"] || formattedProperty?.['id'])
            }

        },
        isCheckedForShare(property) {
            return this.selectedSharePropertiesIds.includes(property?.['unique_id'] || property?.['id'])
        },
        openAuthModal() {
            this.$refs.authModal.open()
        },
        loginSuccess(user) {
            console.log({ loginResponse: user })
            this.loggedInUserEmail = user.email;
            if (this.loggedInUserEmail) {
                this.openShareModal()
            }
        },
        handleShareSuccess() {
            setTimeout(() => {
                this.$refs['share-property-modal']?.close();
                this.displayFavButton = true;
                this.displayShareEmailCard = false;
                this.displayShareButton = true;
                this.selectedShareProperties = []
                this.selectedSharePropertiesIds = []
            }, 1000);
        }
    },
    computed: {
        filteredData() {
            return this.filterPropertyData();
        },
        first_page() {
            if (this.propertyData?.prev_page_url) {
                return false;
            } else {
                return true;
            }
        },
        last_page() {
            if (this.propertyData?.next_page_url) {
                return false;
            } else {
                return true;
            }
        },
        total_properties() {
            if (this.propertyData?.total) {
                return this.propertyData.total;
            } else {
                return 0;
            }
        },
        cityArray() {
            let cities = this?.cities;
            if (typeof this?.cities === 'string') {
                cities = JSON.parse(this.cities)
            }
            return cities?.map(item => item?.city)
        }
    },
};
</script>

<style scoped>
.search-result-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}
</style>
