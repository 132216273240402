<template>
    <div>
        <p class="step-label">EDIT PROPERTY - Step 4</p>
        <div class="form-split">
            <!-- Form Column 1 -->
            <div>
                <!-- Closest Intersection -->
                <div class="form-group">
                    <label class="d-block" for="intersection"
                        >Closest Intersection</label
                    >
                    <input
                        type="text"
                        id="intersection"
                        v-model="intersection"
                        class="form-control"
                    />
                </div>

                <!-- Neighbourhood Name -->
                <div class="form-group">
                    <label class="d-block" for="neighbourhood"
                        >Neighbourhood Name</label
                    >
                    <input
                        type="text"
                        id="neighbourhood"
                        v-model="neighbourhood"
                        class="form-control"
                    />
                </div>

                <!-- unit (if grand child property) -->
                <div class="form-group">
                    <label class="d-block" for="unit"
                        >unit (if grand child property)</label
                    >
                    <input
                        type="text"
                        id="unit"
                        v-model="unit"
                        class="form-control"
                    />
                </div>

                <!-- Marketing Company -->
                <div class="form-group">
                    <label class="d-block" for="marketing_company"
                        >Marketing Company</label
                    >
                    <input
                        type="text"
                        id="marketing_company"
                        v-model="marketing_company"
                        class="form-control"
                    />
                </div>

                <!-- Interior Designer -->
                <div class="form-group">
                    <label class="d-block" for="interior_designer"
                        >Interior Designer</label
                    >
                    <input
                        type="text"
                        id="interior_designer"
                        v-model="interior_designer"
                        class="form-control"
                    />
                </div>

                <!-- Sale Address -->
                <div class="form-group">
                    <label class="d-block" for="sale_address"
                        >Sale Address</label
                    >
                    <input type="hidden" v-model="sale_address" />
                    <vue-google-autocomplete
                        id="sale_address"
                        ref="mapElement"
                        class="form-control"
                        placeholder="Sale address"
                        :country="['ca']"
                        @placechanged="getAddressData"
                        :value="sale_address"
                    ></vue-google-autocomplete>
                </div>
            </div>

            <!-- Form Column 2 -->
            <div>
                <!-- Relationship with other property -->
                <div class="form-group">
                    <label class="d-block" for="relation"
                        >Relationship with other property</label
                    >
                    <input
                        type="text"
                        id="relation"
                        v-model="relation"
                        class="form-control"
                    />
                </div>

                <!-- Actual City -->
                <div class="form-group">
                    <label class="d-block" for="actual_city">Actual City</label>
                    <input
                        type="text"
                        id="actual_city"
                        v-model="actual_city"
                        class="form-control"
                    />
                </div>

                <!-- Builder Type -->
                <div class="form-group radio-group">
                    <label class="d-block">Builder Type</label>
                    <div
                        v-for="option in builderTypeOptions"
                        class="form-check"
                        :key="option.value"
                    >
                        <input
                            class="form-check-input"
                            type="radio"
                            name="builder_type"
                            :id="option.label"
                            :value="option.value"
                            v-model="builder_type"
                        />
                        <label class="form-check-label" :for="option.label">{{
                            option.label
                        }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-button-group">
            <button
                class="save-btn save"
                @click="handleSave()"
                :disabled="isSaving"
            >
                <span
                    v-if="isSaving"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
                Save
            </button>
            <button
                class="reset-btn"
                @click="handleReset()"
                :disabled="isSaving"
            >
                Reset
            </button>
            <button
                class="next-btn next"
                @click="$emit('skip')"
                :disabled="isSaving"
            >
                Skip
            </button>
        </div>
    </div>
</template>

<script>
import pick from "lodash/pick";

const initialFormFields = {
    intersection: "",
    neighbourhood: "",
    unit: "",
    marketing_company: "",
    interior_designer: "",
    sale_address: "",
    relation: "",
    actual_city: "",
    builder_type: "",
};
export default {
    name: "Step4",
    props: {
        propertyDetails: {},
        isSaving: false,
    },
    data() {
        return {
            ...initialFormFields,
            builderTypeOptions: [
                {
                    label: "Sales",
                    value: "Sales",
                },
                {
                    label: "Broker",
                    value: "Broker",
                },
            ],
        };
    },
    mounted() {
        setTimeout(() => {
            this.setExistingFormData();
        }, 1000);
    },
    methods: {
        getAddressData(addressData, placeResultData, id) {
            if (placeResultData?.formatted_address) {
                this.sale_address = placeResultData.formatted_address;
            }
        },
        handleSave() {
            const FormData = pick(this, Object.keys(initialFormFields));
            this.isSaving = true;
            axios
                .post(
                    "/property-management/update-property-details/" +
                        this.propertyDetails?.id,
                    FormData
                )
                .then((response) => {
                    this.isSaving = false;
                })
                .catch((error) => {
                    console.error(error?.response?.data);
                })
                .finally((res) => {
                    // window.location.reload();
                    this.updateData();
                    this.$emit("skip");
                });
        },
        handleReset() {
            for (let key in initialFormFields) {
                this[key] = initialFormFields[key];
            }
        },
        setExistingFormData() {
            for (let key in initialFormFields) {
                this[key] = this.propertyDetails[key] || initialFormFields[key];
            }
        },
        updateData() {
            for (let key in initialFormFields) {
                this.propertyDetails[key] = this[key];
            }
        },
    },
};
</script>

<style lang="scss">
.form-split {
    .form-group {
        .form-control {
            display: block;
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
        }
    }
}
.form-button-group {
    .save-btn.save {
        background-color: #0061df !important;
        color: #fff !important;
    }
    .reset-btn {
        border: 0.5px solid #e8e8e8 !important;
        color: #5f6165 !important;
    }
    .next-btn.next {
        color: var(--clr-primary) !important;
        border: 0.5px solid var(--clr-primary) !important;
        color: #0061df !important;
        border: 0.5px solid #0061df !important;
    }
}
</style>
