<template>
    <div>
        <!-- Search Home Filter -->
        <search-home-filter
            :displayShareButton="displayShareButton"
            @onFilterChange="handleFilterChange"
            @openShareModal="openShareModal"
            @sendPropertyEmail="sendPropertyEmail"
        ></search-home-filter>
        <!-- ./Search Home Filter -->
        <section class="thumbnail">
            <div class="container">
                <div class="row">
                    <div
                        class="col-md-12 px-2 order-2 order-xl-1 order-lg-1 order-md-1"
                    >
                        <div class="col-md-12 p-0">
                            <div class="thumbnail_title">
                                <!-- <h1>Real Estate, Homes for Sale, Apartments & Houses for Rent</h1> -->
                                <h1>
                                    New Homes For Sale <template
                                        v-if="searchstring"
                                        >in {{ searchstring }}</template
                                    >
                                </h1>
                                <p>
                                    {{ total_properties }} homes available on
                                    TeamAlliance
                                </p>
                            </div>
                        </div>
                        <div class="thumbnail_slider_arrea">
                            <div class="col-md-12">
                                <div>
                                    <div
                                        class="search-result-grid"
                                        v-if="isLoading"
                                    >
                                        <skeleton-loader v-for="(item,index) of skeletonList" :key="index"></skeleton-loader>
                                    </div>
                                    <template v-else>
                                        <template v-if="total_properties == 0">
                                            <div
                                                class="col-6 col-md-3 text-center mx-auto"
                                            >
                                                <img
                                                    src="/assets/images/no_result.gif"
                                                    class="img-fluid img-responsive w-100"
                                                    alt="No result found"
                                                />
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div
                                                v-if="propertyData.data"
                                                class="common-property-list-grid"
                                            >
                                                <!-- Property Card -->
                                                <common-property-card
                                                    v-for="(
                                                        item, index
                                                    ) in propertyData.data"
                                                    :key="
                                                        'property-item-' + index
                                                    "
                                                    :property_details="item"
                                                    type="NEW_CONSTRUCTION"
                                                    :user_id="user_id"
                                                    :userFavProperties="userFavProperties"
                                                    @onFavoriteSuccess="handleFavoriteSuccess"
                                                    :canShare="!displayShareButton"
                                                    :isCheckedForShare="isCheckedForShare(value)" 
                                                    @share="handleShare(item)"
                                                ></common-property-card>
                                                <!-- ./Property Card -->
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <pagination
                            v-if="!isLoading"
                            class="mt-4"
                            :data="propertyData"
                            :align="'center'"
                            :show-disabled="true"
                            :limit="12"
                            @pagination-change-page="getHomes"
                        >
                        </pagination>
                    </div>
                </div>
            </div>
        </section>
        <share-property-modal v-if="loggedInUserEmail" ref="share-property-modal" :properties="selectedShareProperties" :senderEmail="loggedInUserEmail" @onSuccess="handleShareSuccess"></share-property-modal>
        <auth-modal ref="authModal" :initialView="initialAuthView" @loginSuccess="loginSuccess"></auth-modal>
    </div>
</template>

<script>
import SearchHomeFilter from "./SearchHomeFilter.vue";
import SearchHomePropertyCard from "./SearchHomePropertyCard.vue";
import SkeletonLoader from "../../pages/SkeletonLoader/SkeletonLoader.vue";
import CommonPropertyCard from "../../pages/PropertyCard/CommonPropertyCard/CommonPropertyCard.vue";
import { getUserFavPropertiesIds } from "../../utils/app";
import SharePropertyModal from "../SharePropertyModal.vue";
import AuthModal, { VIEWS } from "../AuthModal/AuthModal.vue";

export default {
    name: "SearchHomeContainer",
    props: {
        searchstring: {
            default: "",
        },
        user_id: "",
        sender_email: ""
    },
    data() {
        return {
            cities: [],
            sub_city: [],
            propertyData: {},
            isLoading: true,
            currentPage: 1,
            bedroom: "All",
            buildingType: "All",
            city: "All",
            homeType: "All",
            subCity: "All",
            skeletonList:new Array(8),
            filterData: {
                bedroom: "All",
                bathroom: "All",
                min_price: "All",
                max_price: "All",
                property_type: "All",
                home_type: "All",
                building_type: "All",
                search: "",
                sortBy: "",
                sortDir: "",
            },
            userFavProperties: [],
            selectedShareProperties: [],
            selectedSharePropertiesIds: [],
            displayFavButton: true,
            displayShareEmailCard: false,
            displayShareButton: true,
            initialAuthView: VIEWS.LOGIN_FORM,
            loggedInUserEmail: "" 
        };
    },
    components: {
        "search-home-filter": SearchHomeFilter,
        "search-home-property-card": SearchHomePropertyCard,
        "skeleton-loader": SkeletonLoader,
        'share-property-modal': SharePropertyModal,
        'common-property-card': CommonPropertyCard,
        'auth-modal': AuthModal
    },
    mounted() {
        this.loggedInUserEmail = this.sender_email;
        this.filterData.search = this.searchstring;
        this.getHomes();
        this.fetchUserFavProperties()
    },
    methods: {
        fetchUserFavProperties(){
            getUserFavPropertiesIds(this.user_id, 'new-construction')
            .then(res=>{
                this.userFavProperties = res;
            });
        },
        handleFavoriteSuccess(res){
            if(this.userFavProperties.includes(res.id)){
                this.userFavProperties = this.userFavProperties.filter(propertyId=> propertyId !== res.id)
            }
            else {
                this.userFavProperties = [...this.userFavProperties, res.id]
            }
        },
        getHomes(page = 1) {
            this.currentPage = page;
            this.propertyData = {};
            this.isLoading = true;
            const formData = new FormData();
            for (let [key, value] of Object.entries(this.filterData)) {
                formData.append(key, value);
            }

            axios
                .post("/fetch-homes?page=" + page, formData)
                .then((response) => {
                    this.cities = response?.data?.data?.cities;
                    this.sub_city = response?.data?.data?.sub_city;
                    this.propertyData = response?.data?.data?.property;
                })
                .catch((error) => {
                    console.error(error.response.data);
                })
                .finally((res) => {
                    this.isLoading = false;
                });
        },
        getProperties(payload) {
            this.bedroom = payload?.bedroom ? payload.bedroom : "All";
            this.buildingType = payload?.buildingType
                ? payload.buildingType
                : "All";
            this.city = payload?.city ? payload.city : "All";
            this.homeType = payload?.homeType ? payload.homeType : "All";
            this.subCity = payload?.subCity ? payload.subCity : "All";
            this.getHomes();
        },
        handleFilterChange(data) {
            console.log("filter data", data);
            this.filterData = { ...this.filterData, ...data };
            this.getHomes();
        },
        openShareModal(){
            if(!this.loggedInUserEmail){
                // window.location.href="/login";
                this.openAuthModal()
                return;
            }
            this.displayFavButton = false;
            this.displayShareEmailCard = true;
            this.displayShareButton = false;
        },
        sendPropertyEmail(){
            if(this.selectedSharePropertiesIds?.length){
                this.$refs['share-property-modal']?.open()
            }
        },
        handleShare(property){
            if(this.isCheckedForShare(property)){
                this.selectedShareProperties = this.selectedShareProperties.filter(item=> (item?.["unique_id"] || item?.['id']) !== (property?.["unique_id"] || property?.['id']));
                this.selectedSharePropertiesIds = this.selectedShareProperties.map(item=> (item?.["unique_id"] || item?.['id']))
            }else {
                let formattedProperty = {
                id: property?.["id"],
                unique_id: property?.["unique_id"],
                link: property["plan_details_url"],
                address: this.property_details?.street_address || "No address available",
                beedroom: property.bedroom || "0",
                bathroom: Number(property?.bathrooms) || "0",
                garage: property?.garage_spaces || "0.0",
                price: property?.list_price || "0",
                }
                this.selectedShareProperties.push(formattedProperty);
                this.selectedSharePropertiesIds.push(formattedProperty?.["unique_id"] || formattedProperty?.['id'])
            }
            
        },
        isCheckedForShare(property){
            return this.selectedSharePropertiesIds.includes(property?.['unique_id'] || property?.['id'])
        },
        openAuthModal(){
            this.$refs.authModal.open()
        },
        loginSuccess(user){
            console.log({loginResponse: user})
            this.loggedInUserEmail = user.email;
            if(this.loggedInUserEmail){
                this.openShareModal()
            }
        },
        handleShareSuccess(){
            setTimeout(() => {
                this.$refs['share-property-modal']?.close();
                this.displayFavButton = true;
                this.displayShareEmailCard = false;
                this.displayShareButton = true;
                this.selectedShareProperties = []
                this.selectedSharePropertiesIds = []
            }, 1000);
        }
    },
    computed: {
        first_page() {
            if (this.propertyData?.prev_page_url) {
                return false;
            } else {
                return true;
            }
        },
        last_page() {
            if (this.propertyData?.next_page_url) {
                return false;
            } else {
                return true;
            }
        },
        total_properties() {
            if (this.propertyData?.total) {
                return this.propertyData.total;
            } else {
                return 0;
            }
        },
    },
};
</script>

<style scoped>
.search-result-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}
</style>
