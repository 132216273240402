<template>
    <div>
        <section class="pt-0 pb-4 mb-2">
            <div class="container">
                <template v-if="hideHeading">
                    <h2 class="h1 text-center">
                        Buyers: Affordable Buyer/Investor Solutions.
                    </h2>
                    <p class="text-center h5 fw-normal">
                        Make Everything (Almost) Easier with Our Help
                    </p>
                </template>
                <div class="solution-grid features-grid">
                    <a
                        href="#"
                        class="feature-item solution-item"
                        :class="{ active: currentTab === 1 }"
                        @click.prevent="handleSelectTab(1)"
                    >
                        <img
                            class="img-fluid"
                            src="/assets/new-home/images/MLS-homes.png"
                            alt="MLS Homes"
                        />
                        <h4>MLS Homes</h4>
                        <p class="mb-0 text-grey">
                            Explore all available homes.
                        </p>
                    </a>
                    <a
                        href="#"
                        class="feature-item solution-item"
                        :class="{ active: currentTab === 2 }"
                        @click.prevent="handleSelectTab(2)"
                    >
                        <img
                            class="img-fluid"
                            src="/assets/new-home/images/off-market.png"
                            alt="Off-Market"
                        />
                        <h4>Off-Market</h4>
                        <p class="mb-0 text-grey mx-lg-3">
                            Homes you can’t find anywhere else.
                        </p>
                    </a>
                    <a
                        href="/co-investment"
                        class="feature-item solution-item"
                        :class="{ active: currentTab === 3 }"
                    >
                        <img
                            class="img-fluid"
                            src="/assets/new-home/images/co-buying.png"
                            alt="Co-Buying"
                        />
                        <h4>Co-Buying</h4>
                        <p class="mb-0 text-grey mx-lg-3">
                            2-4 people can buy a home together.
                        </p>
                    </a>
                    <a
                        href="/co-investment"
                        class="feature-item solution-item"
                        :class="{ active: currentTab === 4 }"
                    >
                        <img
                            class="img-fluid"
                            src="/assets/new-home/images/co-investment.png"
                            alt="Co-Investment"
                        />
                        <h4>Co-Investment</h4>
                        <p class="mb-0 text-grey">
                            Co-invest with 25+ individuals.
                        </p>
                    </a>
                </div>
            </div>
        </section>
        <section class="pt-0">
            <div class="container solution-tab-container">
                <div
                    key="property-loader"
                    class="common-property-list-grid"
                    v-if="isLoading && (currentTab === 1 || currentTab === 2)"
                >
                    <skeleton-loader
                        v-for="item in [1, 2, 3, 4]"
                        :key="'property-' + item"
                    />
                </div>
                <div
                    :key="'carousel-' + currentTab"
                    class="owl-carousel owl-theme"
                    v-if="!isLoading && propertyList.length !== 0"
                >
                    <common-property-card
                        v-for="(property, index) in propertyList"
                        :key="'idx-property-' + index"
                        :property_details="property"
                        :type="propertyType"
                        :displayTimeLabel="true"
                    />
                </div>
                <div
                    key="not-found"
                    class="text-center"
                    v-if="!isLoading && propertyList.length === 0"
                >
                    <img
                        src="/images/no_result.gif"
                        alt="No Result"
                        class="img-responsive img-fluid mx-auto d-block"
                    />
                </div>
                <div
                    class="view-all-btn-container"
                    v-if="propertyList.length && propertyList.length > 4"
                >
                    <a :href="getViewAllLink" class="view-all-btn mt-0"
                        >View All Properties</a
                    >
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from "axios";
import CommonPropertyCard from "../../pages/PropertyCard/CommonPropertyCard/CommonPropertyCard.vue";
import SkeletonLoader from "../../pages/SkeletonLoader/SkeletonLoader.vue";

export default {
    name: "HomePropertiesTabs",
    props: {
        hideHeading: {
            default: false,
        },
    },
    data() {
        return {
            currentTab: 1,
            isLoading: true,
            propertyList: [],
            propertyType: "BROWSE_HOME",
        };
    },
    components: {
        "skeleton-loader": SkeletonLoader,
        "common-property-card": CommonPropertyCard,
    },
    mounted() {
        this.getMLSListing();
    },
    methods: {
        handleSelectTab(tab) {
            this.currentTab = tab;
            if (tab === 1) {
                this.getMLSListing();
            } else if (tab === 2) {
                this.getExclusiveListing();
            } else {
                this.propertyList = [];
            }
        },
        getMLSListing() {
            this.propertyType = "BROWSE_HOME";
            this.isLoading = true;
            this.propertyList = [];
            axios
                .get(
                    "https://valuation.santhoshmathew.com/api/v1/properties/residential?page=1&perPage=9&sortBy=timestamp_idx&sortDir=desc&sale_or_lease=Sale"
                )
                .then((response) => {
                    if (this.currentTab !== 1) return;
                    this.propertyList = response?.data?.data;
                    if (this.propertyList.length > 0) {
                        this.loadCarousel();
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally((result) => {
                    this.isLoading = false;
                });
        },
        getExclusiveListing() {
            this.propertyType = "EXCLUSIVE";
            this.isLoading = true;
            this.propertyList = [];
            axios
                .get(
                    window.location.origin +
                        "/exclusive-property-filter?page=1&perPage=8"
                )
                .then((response) => {
                    if (this.currentTab !== 2) return;
                    this.propertyList = response?.data?.data;
                    if (this.propertyList.length > 0) {
                        this.loadCarousel();
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally((result) => {
                    this.isLoading = false;
                });
        },
        loadCarousel() {
            setTimeout(() => {
                this.initOwlCarousel();
            }, 200);
        },
        initOwlCarousel() {
            const $owl = $(".owl-carousel");
            $owl.children().each(function (index) {
                $(this).attr("data-position", index);
            });

            $owl.owlCarousel({
                autoplay: false,
                items: 4,
                // stagePadding: 100,
                center: false,
                nav: true,
                margin: 10,
                dots: false,
                loop: false,
                autoHeight: true,
                responsive: {
                    0: {
                        items: 1,
                    },
                    480: {
                        items: 1,
                    },
                    575: {
                        items: 1,
                    },
                    768: {
                        items: 2,
                    },
                    991: {
                        items: 3,
                    },
                    1226:{
                        items:3,
                    },
                    1300: {
                        items: 4,
                    },
                },
            });

            let makeid = +new Date();
            $(".owl-prev").attr("aria-label", "Owl Prev_" + makeid);
            $(".owl-next").attr("aria-label", "Owl Next_" + makeid);
            $(".owl-dot").attr("aria-label", "Owl Dot_" + makeid);
        },
    },
    computed: {
        getViewAllLink() {
            if (this.currentTab === 1) return "/idx-listings/search-result";
            if (this.currentTab === 2)
                return "/exclusive-property/search-result";
        },
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1037px) {
    .solution-tab-container {
        margin-top: 20px;
    }

    .owl-theme .owl-nav .owl-prev,
    .owl-theme .owl-nav .owl-next {
        width: 50px;
        height: 50px;
    }

    .view-all-btn-container {
        bottom: 10px;
        left: 12px;
        transform: translateX(0);
    }

    .view-all-btn-container .view-all-btn {
        font-size: 14px;
        padding: 15px 20px;
    }

    .solution-grid.features-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .feature-item {
        padding: 30px 15px;
        border-radius: 20px;

        img {
            height: 50px;
        }

        h4 {
            margin-top: 15px;
            font-size: 16px;
        }

        p {
            font-size: 12px;
        }
    }

    .solution-tab-container {
        .common-property-list-grid {
            & > * {
                display: none;
            }

            & > :first-child {
                display: block;
            }

            .skeleton-card {
                width: 100%;
            }
        }
    }
}
</style>
